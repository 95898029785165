import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { ChartOptions, TooltipItem } from 'chart.js';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { WidgetColors } from '../../enums/widget-colors.enum';

/**
 * This widget displays the 14 different voltages of a battery
 */
@Component({
	selector: 'agilox-analytics-widget-distance',
	templateUrl: './widget-distance.component.html',
	styleUrls: ['./widget-distance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetDistanceComponent extends BaseWidgetDirective {
	config: Array<EntryConfig>;
	colors = [WidgetColors[0], WidgetColors[1]];
	unit = 'km';

	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	ownOptions: ChartOptions = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				min: 0,
				max: 0.1,
				suggestedMin: 0,
				suggestedMax: 0.1,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 3,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
						const result = value - Math.floor(value) !== 0 ? value.toFixed(2) : value;
						return (
							this.translate.instant(
								'widget.distance.' + this.config[tooltipItem.datasetIndex].name
							) +
							': ' +
							result +
							this.unit
						);
					},
					labelColor: (tooltipItem: TooltipItem<any>) => {
						return {
							borderColor: this.colors[tooltipItem['datasetIndex']],
							backgroundColor: this.colors[tooltipItem['datasetIndex']],
						};
					},
				},
			},
		},
	};

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(
		chartService: ChartDataService,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getData,
			this.transformData,
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.config = new Array<EntryConfig>();
		this.config.push(
			new EntryConfig('distance', { borderColor: this.colors[0], backgroundColor: this.colors[0] })
		);
		this.config.push(
			new EntryConfig('distanceOccupied', {
				borderColor: this.colors[1],
				backgroundColor: this.colors[1],
			})
		);
	}

	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		if (data[0]?.entries.length) {
			return data.map((machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry) => {
						const timelineEntries = new Array<Entry>();
						timelineEntries.push(new Entry('distance', entry['distance']));
						timelineEntries.push(new Entry('distanceOccupied', entry['distanceOccupied']));
						return new Timestamp(entry.start, timelineEntries);
					}),
				};
			});
		} else {
			return new Array<ReducedMachine>();
		}
	}
}
