import { MenuPoint } from '@agilox/ui-common';
import { analyticsMenuPoints } from './analytics-menupoints';
/** desktop menu points*/
export const desktopMenuPoints: MenuPoint[] = [
	analyticsMenuPoints.dashboard,
	analyticsMenuPoints.productivity,
	analyticsMenuPoints.processes,
	analyticsMenuPoints.failures,
	analyticsMenuPoints.battery,
	analyticsMenuPoints.help,
	analyticsMenuPoints.logout,
];
