import { Directive, Input } from '@angular/core';

@Directive()
export class BaseWidgetDirective {
	@Input() widgetName: string;

	@Input() disableOnComb: boolean;

	@Input() disableOnSum: boolean;

	@Input() disabledOnLoadNoData: boolean;

	@Input() disableOnLoad = true;
}
