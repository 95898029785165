/**
 * represents a stat
 */
export class Entry {
	/**
	 * constructs an entry
	 * @param start start
	 * @param end end
	 * @param speed speed
	 * @param speedTarget speedTarget
	 * @param battLvl battLvl
	 * @param battSoh battSoh
	 * @param battCur battCur
	 * @param z z
	 * @param status status
	 * @param charge charge
	 * @param battVol0 battVol0
	 * @param battVol1 battVol1
	 * @param battVol2 battVol2
	 * @param battVol3 battVol3
	 * @param battVol4 battVol4
	 * @param battVol5 battVol5
	 * @param battVol6 battVol6
	 * @param battVol7 battVol7
	 * @param battVol8 battVol8
	 * @param battVol9 battVol9
	 * @param battVol10 battVol10
	 * @param battVol11 battVol11
	 * @param battVol12 battVol12
	 * @param battVol13 battVol13
	 * @param battMin battMin
	 * @param battMax battMax
	 * @param battDiff battDiff
	 * @param tempBatt1
	 * @param tempBatt2
	 * @param tempBatt3
	 * @param tempBattMin
	 * @param tempBattMax
	 * @param ordersActive ordersActive
	 * @param ordersQueued ordersQueued
	 * @param safetyfield safetyfield
	 * @param weight weight
	 * @param issuePPct issuePPct
	 * @param issueTPct issueTPct
	 * @param chargePct chargePct
	 * @param busyPct busyPct
	 * @param idlePct idlePct
	 * @param advObstructionPct advObstructionPct
	 * @param advLockwaitPct advLockwaitPct
	 * @param advProtectivePct advProtectivePct
	 * @param manualPct manualPct
	 * @param offlinePct offlinePct
	 * @param failure failure
	 * @param version version
	 * @param offlineSec offlineSec
	 * @param onlineSec onlineSec
	 * @param distanceAbs distanceAbs
	 * @param weightAbs weightAbs
	 * @param distance
	 * @param distanceOccupied
	 * @param distanceOccupiedAbs
	 * @param parkCount
	 * @param pickupCount
	 * @param chargeCount
	 * @param dropCount
	 * @param durFLAbs
	 * @param durFL
	 * @param durFRAbs
	 * @param durFR
	 * @param durRLAbs
	 * @param durRL
	 * @param durRRAbs
	 * @param durRR
	 * @param acknowledgeButton acknowledgeButton
	 * @param battLvl_2
	 * @param battSoh_2
	 * @param battCur_2
	 * @param battVol0_2
	 * @param battVol1_2
	 * @param battVol2_2
	 * @param battVol3_2
	 * @param battVol4_2
	 * @param battVol5_2
	 * @param battVol6_2
	 * @param battVol7_2
	 * @param battVol8_2
	 * @param battVol9_2
	 * @param battVol10_2
	 * @param battVol11_2
	 * @param battVol12_2
	 * @param battVol13_2
	 * @param tempBatt_2_min
	 * @param tempBatt_2_max
	 * @param batt_2_Min
	 * @param batt_2_Max
	 * @param batt_2_Diff
	 */
	constructor(
		public start: number, // timestamp_start
		public end: number, // timestamp_end
		public speed: number,
		public speedTarget: number, // speed_target
		public battLvl: number, // batt_lvl
		public battSoh: number, // batt_soh
		public battCur: number, // batt_cur
		public z: number,
		public status: number,
		public charge: number,
		public battVol0: number, // batt_vol_0
		public battVol1: number, // batt_vol_1
		public battVol2: number, // batt_vol_2
		public battVol3: number, // batt_vol_3
		public battVol4: number, // batt_vol_4
		public battVol5: number, // batt_vol_5
		public battVol6: number, // batt_vol_6
		public battVol7: number, // batt_vol_7
		public battVol8: number, // batt_vol_8
		public battVol9: number, // batt_vol_9
		public battVol10: number, // batt_vol_10
		public battVol11: number, // batt_vol_11
		public battVol12: number, // batt_vol_12
		public battVol13: number, // batt_vol_13
		public battMin: number, // batt_min
		public battMax: number, // batt_max
		public battDiff: number, // batt_vdiff
		public tempBatt1: number, // temp_battery_1
		public tempBatt2: number, // temp_battery_2
		public tempBatt3: number, // temp_battery_3
		public tempBattMin: number, // temp_battery_min
		public tempBattMax: number, // temp_battery_max
		public ordersActive: number, // orders_active
		public ordersQueued: number, // orders_queued
		public safetyfield: number,
		public weight: number,
		public issuePPct: number, // issue_p_pct
		public issueTPct: number, // issue_t_pct
		public chargePct: number, // charge_pct
		public busyPct: number, // busy_pct
		public idlePct: number, // idle_pct
		public advObstructionPct: number, // adv_obstruction_pct
		public advLockwaitPct: number, // adv_lockwait_pct
		public advProtectivePct: number, // adv_protectiv_pct
		public manualPct: number, // manual_pct
		public offlinePct: number, // offline_pct
		public failure: number,
		public version: number,
		public offlineSec: number, // offline_sec
		public onlineSec: number, // online_sec
		public distanceAbs: number, // distance_abs
		public weightAbs: number, // weight_abs
		public distance: number, // distance
		public distanceOccupied: number, // distance_occupied
		public distanceOccupiedAbs: number, // distance_abs_occupied
		public parkCount: number, // park_cnt
		public pickupCount: number, // pickup_cnt
		public chargeCount: number, //charge_cnt
		public dropCount: number, //drop_cnt
		public durFLAbs: number, //dur_0_abs FL
		public durFL: number, //dur_0 FL
		public durFRAbs: number, //dur_1_abs FR
		public durFR: number, //dur_1 FR
		public durRLAbs: number, //dur_2_abs RL
		public durRL: number, //dur_2 RL
		public durRRAbs: number, //dur_3_abs RR
		public durRR: number, //dur_3 RR
		public acknowledgeButton: number, //acknowledge_button
		public z_abs: number,
		public battLvl_2?: number,
		public battSoh_2?: number,
		public battCur_2?: number,
		public battVol0_2?: number,
		public battVol1_2?: number,
		public battVol2_2?: number,
		public battVol3_2?: number,
		public battVol4_2?: number,
		public battVol5_2?: number,
		public battVol6_2?: number,
		public battVol7_2?: number,
		public battVol8_2?: number,
		public battVol9_2?: number,
		public battVol10_2?: number,
		public battVol11_2?: number,
		public battVol12_2?: number,
		public battVol13_2?: number,
		public tempBatt_2_min?: number,
		public tempBatt_2_max?: number,
		public batt_2_min?: number,
		public batt_2_max?: number,
		public batt_2_diff?: number
	) {}

	/**
	 * constructs an entry from a server-request
	 * @param data data from server-request
	 */
	static generateFromJson(data: any): Entry {
		return new Entry(
			data.timestamp_start * 1000,
			data.timestamp_end * 1000,
			data.speed,
			data.speed_target,
			data.batt_lvl,
			data.batt_soh,
			data.batt_cur,
			data.z,
			data.status,
			data.charge,
			data.batt_vol_0,
			data.batt_vol_1,
			data.batt_vol_2,
			data.batt_vol_3,
			data.batt_vol_4,
			data.batt_vol_5,
			data.batt_vol_6,
			data.batt_vol_7,
			data.batt_vol_8,
			data.batt_vol_9,
			data.batt_vol_10,
			data.batt_vol_11,
			data.batt_vol_12,
			data.batt_vol_13,
			data.batt_min,
			data.batt_max,
			data.batt_diff,
			data.temp_battery_1,
			data.temp_battery_2,
			data.temp_battery_3,
			data.temp_battery_min,
			data.temp_battery_max,
			data.orders_active,
			data.orders_queued,
			data.safetyfield,
			data.weight,
			data.issue_p_pct,
			data.issue_t_pct,
			data.charge_pct,
			data.busy_pct,
			data.idle_pct,
			data.adv_obstruction_pct,
			data.adv_lockwait_pct,
			data.adv_protectiv_pct,
			data.manual_pct,
			data.offline_pct,
			data.failure,
			data.version,
			data.offline_sec,
			data.online_sec,
			data.distance_abs,
			data.weight_abs,
			data.distance,
			data.distance_occupied,
			data.distance_abs_occupied,
			data.park_cnt,
			data.pickup_cnt,
			data.charge_cnt,
			data.drop_cnt,
			data.dur_0_abs / 100 / 1000,
			data.dur_0 / 100 / 1000,
			data.dur_1_abs / 100 / 1000,
			data.dur_1 / 100 / 1000,
			data.dur_2_abs / 100 / 1000,
			data.dur_2 / 100 / 1000,
			data.dur_3_abs / 100 / 1000,
			data.dur_3 / 100 / 1000,
			data.acknowledge_button,
			data.z_abs,
			data.batt_2_lvl,
			data.batt_2_soh,
			data.batt_2_cur,
			data.batt_2_vol_0,
			data.batt_2_vol_1,
			data.batt_2_vol_2,
			data.batt_2_vol_3,
			data.batt_2_vol_4,
			data.batt_2_vol_5,
			data.batt_2_vol_6,
			data.batt_2_vol_7,
			data.batt_2_vol_8,
			data.batt_2_vol_9,
			data.batt_2_vol_10,
			data.batt_2_vol_11,
			data.batt_2_vol_12,
			data.batt_2_vol_13,
			data.temp_battery_2_min,
			data.temp_battery_2_max,
			data.batt_2_min,
			data.batt_2_max,
			data.batt_2_diff
		);
	}
}
