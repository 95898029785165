import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

/**
 * Config for some of the gestures
 */
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
	/**
	 * overrides some settings
	 */
	override overrides = {
		pan: { threshold: 0, velocity: 0 },
	};

	//Fix vertical scrolling for swipeLeft and swipeRight
	override buildHammer(element: HTMLElement) {
		const mc = new Hammer(element, {
			touchAction: 'pan-y',
		});
		return mc;
	}
}
