import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { ChartOptions, TooltipItem } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { FailuresEntry } from 'apps/analytics/src/app/menupoints/failures/failures-history/failures-entry';
import { FailureCategoryColor } from 'apps/analytics/src/app/general/shared/services/failure-order-workflow/failure-category-color.enum';
import { FailureCategoryKey } from 'apps/analytics/src/app/general/shared/services/failure-order-workflow/failure-category.enum';
/**
 * This widget displays the 14 different voltages of a battery
 */
@Component({
	selector: 'agilox-analytics-widget-failures-type-history',
	templateUrl: './widget-failures-type-history.component.html',
	styleUrls: ['./widget-failures-type-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFailuresTypeHistoryComponent extends BaseWidgetDirective {
	config: Array<EntryConfig>;
	colors = [
		FailureCategoryColor[FailureCategoryKey.ERROR],
		FailureCategoryColor[FailureCategoryKey.WARNING],
		FailureCategoryColor[FailureCategoryKey.PROBLEM],
	];
	unit = '';

	loadingHandler: LoadingHandler<FailuresEntry, ReducedMachine>;

	barChart = true;

	ownOptions: ChartOptions = {
		layout: {
			padding: {
				bottom: 25,
				left: 25,
				right: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				min: 0,
				max: 0.1,
				suggestedMin: 0,
				suggestedMax: 0.1,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 3,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		plugins: {
			legend: {
				display: true,
			},
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (d: TooltipItem<any>) => {
						return (
							this.translate.instant(this.config[d.datasetIndex].name) + ': ' + d.formattedValue
						);
					},
				},
				itemSort: (a, b) => {
					return b.datasetIndex - a.datasetIndex;
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
	};

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(
		chartService: ChartDataService,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getFailuresHistory,
			this.transformData,
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.config = new Array<EntryConfig>();
		this.config.push(
			new EntryConfig('failures.' + FailureCategoryKey.ERROR.toLowerCase() + 's', {
				borderColor: this.colors[0],
				backgroundColor: this.colors[0],
			})
		);
		this.config.push(
			new EntryConfig('failures.' + FailureCategoryKey.PROBLEM.toLowerCase() + 's', {
				borderColor: this.colors[2],
				backgroundColor: this.colors[2],
			})
		);
		this.config.push(
			new EntryConfig('failures.' + FailureCategoryKey.WARNING.toLowerCase() + 's', {
				borderColor: this.colors[1],
				backgroundColor: this.colors[1],
			})
		);
	}

	private transformData(data: Array<FailuresEntry>): Array<ReducedMachine> {
		if (data.length > 0) {
			return [
				{
					serial: '000000',
					data: data.map((entry) => {
						const timeEntries = new Array<Entry>();
						Object.keys(FailureCategoryKey).map((key) => {
							key = key.toLocaleLowerCase();
							timeEntries.push(new Entry('failures.' + key + 's', entry[key + 's']));
						});
						return new Timestamp(entry.start, timeEntries);
					}),
				},
			];
		} else {
			return new Array<ReducedMachine>();
		}
	}
}
