import { Subscription } from 'rxjs';

export class LoadingHandler<T extends any, V extends any> {
	/**
	 * It is really important, that you keep an eye on the value which get passend into the different functions
	 * and which one gets returned, because the loading-handler is tightly bounded to the loading-directive.
	 *
	 * @param fetchContext context under which the fetchFunction gets executed
	 * @param fetchFunction function which fetches the data, the data gets parsed by the parseFunction
	 * @param parseFunction parses the function, always returns an array
	 * @param onLoadedFunction gets executed after every is loaded
	 * @param isThereDataFunction function for customized checking if there is data. Needs to return true or false if data is empty and null if not loaded
	 */
	constructor(
		fetchContext: any,
		public fetchFunction: (
			observer: (data: Array<T>) => void,
			renderInShowAll?: any
		) => Subscription,
		public parseFunction?: (data: Array<T>, machineNames?: Array<[string, string]>) => Array<V>,
		public onLoadedFunction?: (data: Array<V>) => void,
		public isThereDataFunction?: (data: Array<V>) => boolean | null
	) {
		if (fetchContext) {
			this.fetchFunction = this.fetchFunction.bind(fetchContext);
		}
	}
}
