import { CheckSubscriptionExpireSoonPipe, Meta, Vehicle } from '@agilox/common';
import { IconModule, SelectComponent, SelectModule, TooltipModule } from '@agilox/ui';
import { AsyncPipe } from '@angular/common';
import {
	Component,
	forwardRef,
	inject,
	Input,
	signal,
	ViewChild,
	WritableSignal,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	tap,
} from 'rxjs';
import { VehicleSelectPipe } from './pipes/vehicle-select.pipe';
import { VehiclesSelectService } from './vehicles-select.service';

@Component({
	selector: 'ui-vehicles-select',
	templateUrl: './vehicles-select.component.html',
	standalone: true,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VehiclesSelectComponent),
			multi: true,
		},
		VehiclesSelectService,
	],
	imports: [
		SelectModule,
		TranslateModule,
		AsyncPipe,
		VehicleSelectPipe,
		ReactiveFormsModule,
		IconModule,
		TooltipModule,
		CheckSubscriptionExpireSoonPipe,
	],
})
export class VehiclesSelectComponent implements ControlValueAccessor {
	private service: VehiclesSelectService = inject(VehiclesSelectService);
	private search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private searchObservable$: Observable<string> = this.search$.asObservable().pipe(
		debounceTime(300),
		distinctUntilChanged(),
		// only set the loading when searched is triggered, not when scrolling
		tap(() => this.loading.set(true))
	);
	private page: Meta = { number: 0, size: 50 };
	private currentPage: number = 1;

	private paginationSubject: Subject<Meta> = new Subject<Meta>();
	private pagination$: Observable<Meta> = this.paginationSubject.asObservable().pipe(
		startWith({
			number: 0,
			size: 50,
		})
	);

	/**
	 * Because we internally disable/enable the form control
	 * based on the loading state, we need to keep track of the
	 * disabled state from the parent
	 * @private
	 */
	private _disabledFromParent: boolean = false;

	private doSortSubject: Subject<void> = new Subject<void>();
	private doSort$: Observable<void> = this.doSortSubject.asObservable().pipe(startWith(undefined));

	public loading: WritableSignal<boolean> = signal(false);

	initialLoad: boolean = true;

	public vehicleResponse: Observable<Vehicle[]> = combineLatest([
		this.searchObservable$,
		this.pagination$,
	]).pipe(
		tap(() => {
			this.formControl.disable({ emitEvent: false });
		}),
		switchMap(([search, page]) => this.service.fetchVehicles(search, page, this.selectedSerials)),
		tap((data) => {
			this.page = data.meta;
			if (!this._disabledFromParent) {
				this.formControl.enable({ emitEvent: false });
			}
			this.loading.set(false);
			if (this.initialLoad) {
				this.initialLoad = false;
				this.setVehiclesToSelectedVehiclesFromSerialStrings(this.selectedSerials, data.data);
			}
		}),
		map((data) => data.data)
	);

	public sortedVehicles$: Observable<Vehicle[]> = combineLatest([
		this.vehicleResponse,
		this.doSort$,
	]).pipe(
		map(([vehicles]) => {
			return this.sortOptions(vehicles);
		})
	);

	@ViewChild(SelectComponent, { static: true }) selectComponent: SelectComponent | undefined;

	@Input() multiple: boolean = false;
	@Input() selectedSerials: string[] = [];
	@Input() maxSelections: number | undefined;
	@Input() maxSelectionsText: string = 'select.maxSelectionsText';

	@Input() fullDropdownWidth: boolean = true;

	/**
	 * Need to cache the selectedVehicles on the initial write
	 * otherwise not all vehicles will be selected.
	 */
	selectedVehicles: Vehicle[] = [];

	public formControl: FormControl = new FormControl({ value: [], disabled: true });
	onChange: any = () => {};
	onTouched: any = () => {};

	writeValue(obj: Vehicle[]): void {
		this.selectedVehicles = obj;
		this.formControl.setValue(obj, { emitEvent: false });
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });

		this._disabledFromParent = isDisabled;
	}

	onSearch(query: string): void {
		this.search$.next(query);
	}

	onScroll(): void {
		this.currentPage++;
		this.page.size = 50 * this.currentPage;
		if (this.page.size < (this.page?.total || 0)) {
			this.paginationSubject.next(this.page);
		}
	}

	/**
	 * Needed in cases where the selected vehicles are passed as serials
	 * @example
	 * We get 123481723,1230129384
	 * When we receive the vehicles from the backend, we need to set the vehicles that have the serials 123481723 and 1230129384
	 * to the form control
	 *
	 * @param serials
	 * @param vehicles
	 * @private
	 */
	private setVehiclesToSelectedVehiclesFromSerialStrings(
		serials: string[],
		vehicles: Vehicle[]
	): void {
		if (serials?.length && vehicles?.length) {
			const selectedVehicles = vehicles.filter((vehicle) => serials.includes(vehicle.serial));
			this.formControl.setValue(selectedVehicles, { emitEvent: false });
		}
	}

	public onOpened(): void {
		if (this.page.number !== 0 || this.page.size !== 50) {
			this.paginationSubject.next({ number: 0, size: 50 });
		}
		this.search$.next('');
		this.doSortSubject.next();
	}

	onSave() {
		const selectedSerials = this.formControl.value;
		this.onChange(selectedSerials);
	}

	public toggleDropdown() {
		this.selectComponent?.toggleDropdown();
	}

	/**
	 * Selected Vehicles are always on top
	 * @param vehicles
	 * @private
	 */
	private sortOptions(vehicles: Vehicle[]): Vehicle[] {
		const selectedSerials = this.selectedSerials;
		const selectedVehicles = vehicles.filter((vehicle: Vehicle) =>
			selectedSerials.includes(vehicle.serial)
		);
		const unselectedVehicles = vehicles.filter(
			(vehicle: Vehicle) => !selectedSerials.includes(vehicle.serial)
		);
		return [...selectedVehicles, ...unselectedVehicles];
	}
}
