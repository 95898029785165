@let expireSoonVehicles = expireSoonVehicles$ | async;
@if (!appsettingsService.isInternalUser() && expireSoonVehicles?.length) {
	<ui-notify
		data-cy="subscription-expire-soon-notify"
		[text]="
			expireSoonVehicles.length
				| pluralTranslate
					: 'general.subscription_for_vehicle_expire_soon'
					: 'general.subscription_for_vehicles_expire_soon'
					: { machines: expireSoonVehicles | subscriptionVehicleDisplay }
		"
		type="info"
	></ui-notify>
}
