import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays a timeline-chart for weight-absolute
 */
@Component({
	selector: 'agilox-analytics-widget-weight-absolute',
	templateUrl: './widget-weight-absolute.component.html',
	styleUrls: ['./widget-weight-absolute.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWeightAbsoluteComponent extends BaseWidgetDirective {}
