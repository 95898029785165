import { FileUtil } from './../utils/file-util';
import { Mime } from '../mime';
import { Exporter } from './exporter';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
export abstract class FileExporter<T> implements Exporter<T> {
	constructor() {}

	public export(rows: Array<any>, options?: T) {
		if (!rows) {
			throw new Error('Empty json array is provided, rows parameter is mandatory!');
		}
		const mimeType = this.getMimeType();
		this.createContent(rows, options).then((content) => {
			FileUtil.save(content, mimeType, options);
		});
	}

	public abstract createContent(rows: Array<any>, options?: T): Promise<any>;
	public abstract getMimeType(): Mime;
}
