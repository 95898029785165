<div class="dashboard-empty">
	<ui-empty-state
		text="widget.general.dashboard_empty"
		[buttonText]="establishDefault.observers.length ? 'widget.general.establish_default' : ''"
		(buttonClicked)="onEstablishDefault()"
		image="assets/images/onboarding/dashboard.svg"
		buttonIcon="pin"
	>
	</ui-empty-state>
</div>
