<div class="widget-weight-analysis">
	<agilox-analytics-widget-header
		[widgetName]="widgetName"
		[disabledOnLoadNoData]="disabledOnLoadNoData"
		[disableOnLoad]="disableOnLoad"
		[disableOnComb]="disableOnComb"
		[disableOnSum]="disableOnSum"
	>
	</agilox-analytics-widget-header>
	<div
		*agiloxAnalyticsLoad="
			let elem of loadingHandler;
			disableOnComb: disableOnComb;
			disableOnSum: disableOnSum
		"
	>
		<agilox-analytics-block-info-chart
			[colorScheme]="BlockInfoChartColorSchemeEnum.BLUE"
			[blocks]="blockInfoChartBoxes"
			[values]="[elem.weightMax + ' kg', elem.weightMin + ' kg', elem.weightAvg + ' kg']"
		>
		</agilox-analytics-block-info-chart>
	</div>
</div>
