import { Entry } from './entry';
import { Order } from './order';

/**
 * represents a machine with all its entries
 */
export class Machine {
	/**
	 * constructs a machine
	 * @param serial serial of the machine
	 * @param model model of the machine
	 * @param entries all entries of the machine
	 */
	constructor(
		public serial: string,
		public model: string,
		public entries: Array<Entry | Order>
	) {}

	/**
	 * generates a machine
	 * @param serial serial number
	 * @param data array<any>
	 */
	static generateFromJson(serial: string, model: string, data: Array<any>): Machine {
		const entries = data
			.filter((item) => item)
			.map((item) => {
				// checking for a random attribute of entry
				if (item.batt_cur === undefined) {
					return Order.generateFromJson(item);
				}

				return Entry.generateFromJson(item);
			});
		return new Machine(serial, model, entries);
	}
}
