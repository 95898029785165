/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
export class Mime {
	extension: string;
	contentTypeHeader: string;

	constructor(extension: string, contentTypeHeader: string) {
		this.extension = extension;
		this.contentTypeHeader = contentTypeHeader;
	}
}
