import { NgModule } from '@angular/core';
import { VehicleUnionSelectComponent } from './vehicle-union-select.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { NotifyComponent } from '../notify/notify.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { IconModule } from '../icon/icon.module';
import { InputModule } from '../input/input.module';
import { PillComponent } from '../pill/pill.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UnionSelectOptionComponent } from './components/union-select-option/union-select-option.component';
import { VehicleSelectUnionCheckboxPipe } from './pipes/vehicle-select-union-checkbox.pipe';
import { VehicleSelectOptionPipe } from './pipes/vehicle-select-option.pipe';
import { VehicleSelectOptionComponent } from './components/vehicle-select-option/vehicle-select-option.component';
import { VehicleUnionSelectService } from './services/vehicle-union-select.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@NgModule({
	declarations: [
		VehicleUnionSelectComponent,
		UnionSelectOptionComponent,
		VehicleSelectUnionCheckboxPipe,
		VehicleSelectOptionPipe,
		VehicleSelectOptionComponent,
	],
	imports: [
		AsyncPipe,
		DropdownModule,
		IconModule,
		InputModule,
		NotifyComponent,
		PillComponent,
		TranslateModule,
		NgClass,
		ReactiveFormsModule,
		CheckboxComponent,
		TooltipModule,
		SpinnerComponent,
	],
	exports: [VehicleUnionSelectComponent],
	providers: [VehicleUnionSelectService],
})
export class VehicleUnionSelectModule {}
