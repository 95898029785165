import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ChartDataService } from '../../shared/services/chart-data/chart-data.service';
import { Summary } from '../../shared/services/chart-data/summary';
import { InterpolatedLabels } from '../components-for-widget-construction/interpolated-labels';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { WidgetColors } from '../../enums/widget-colors.enum';

// technical-, process-counter
type DoughnutData = [number, number];

@Component({
	selector: 'agilox-analytics-widget-productivity-distance-pie',
	templateUrl: './widget-productivity-distance-pie.component.html',
	styleUrls: ['./widget-productivity-distance-pie.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProductivityDistancePieComponent extends BaseWidgetDirective {
	colors: any = [WidgetColors[0], WidgetColors[1]];
	distance: number;
	distanceOccupied: number;

	loadingHandler: LoadingHandler<Summary, DoughnutData>;

	constructor(private chartService: ChartDataService) {
		super();
		this.loadingHandler = new LoadingHandler(
			null,
			this.chartService.getProductivitySummaryData.bind(this.chartService),
			this.parseFunction,
			null,
			(data: Array<DoughnutData>) => {
				this.disableOnLoad = data === null;
				const res =
					this.disableOnLoad || data === undefined ? null : data[0]?.some((elem) => elem > 0);
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	parseFunction(data: Array<Summary>): Array<DoughnutData> {
		const summary = data[0];
		if (summary) {
			const distance = (summary.distance - summary.distanceOccupied) / (10 * 100 * 1000);
			this.distance = Math.round(distance * 100) / 100; // round to 2 decimals
			const distanceOccupied = summary.distanceOccupied / (10 * 100 * 1000);
			this.distanceOccupied = Math.round(distanceOccupied * 100) / 100; // round to 2 decimals
		}
		return summary ? [[this.distance, this.distanceOccupied]] : [];
	}

	interpolateLabels(data: Array<number>): InterpolatedLabels {
		return {
			distance: data[0],
			distanceOccupied: data[1],
		};
	}
}
