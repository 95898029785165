import { ExportType } from '../export-type';
import * as FileSaver from 'file-saver-es';
import { MAT_TABLE_EXPORTER, DOT } from '../constants';
import { Mime } from '../mime';
import { Options } from '../options';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
export class FileUtil {
	public static save(content: string, mime: Mime, options?: Options) {
		const blob = new Blob([content], { type: mime.contentTypeHeader });
		let fileName = MAT_TABLE_EXPORTER;
		if (options && options.fileName) {
			fileName = options.fileName;
		}
		FileSaver.saveAs(blob, fileName + mime.extension);
	}

	public static removeExtension(options?: Options) {
		options.fileName = options.fileName.split(DOT)[0];
	}
}
