<div class="timeline-chart">
	<div [hidden]="spinnerEntries?.length === 0" class="loading-handler">
		<canvas
			baseChart
			[datasets]="datasets"
			[labels]="labels"
			[legend]="legend"
			[options]="options"
			[type]="type"
		></canvas>
	</div>
</div>
