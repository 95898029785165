import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/**
 * displays an info-message when no widgets are on the dashboard.
 * optionally if you add a subscriber on establishDefault, a button which
 * bubbles up the event appears.
 */
@Component({
	selector: 'agilox-analytics-dashboard-empty',
	templateUrl: './dashboard-empty.component.html',
	styleUrls: ['./dashboard-empty.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardEmptyComponent {
	@Output() establishDefault: EventEmitter<void> = new EventEmitter<void>();

	onEstablishDefault() {
		this.establishDefault.emit();
	}
}
