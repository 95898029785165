import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessesSubMenuUrl } from '../../shared/enums/processes-submenu.enum';
import { ActionStation } from '../../shared/services/failure-order-workflow/action-station';
import { FailureOrderWorkflowService } from '../../shared/services/failure-order-workflow/failure-order-workflow.service';
import { StationCount } from '../../shared/services/failure-order-workflow/station-count';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { SwitchChart } from '../components-for-widget-construction/switch-chart.interface';
import { Col } from '../components-for-widget-construction/table-chart/col.type';
import { HeaderIconsDisabled } from '../components-for-widget-construction/table-chart/header-icons-disabled.type';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays the lifted weight at a timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-processes-pickups',
	templateUrl: './widget-processes-pickups.component.html',
	styleUrls: ['./widget-processes-pickups.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProcessesPickupsComponent extends BaseWidgetDirective {
	config: Array<Col> = [
		['widget.processes_pickups.station', 'station'],
		['widget.processes_pickups.count', 'count'],
	];

	/** holds the config for the chart switch */
	switchChart: SwitchChart = {
		rightChartConfig: { name: 'view_list' },
		leftChartConfig: { name: 'chart_doughnut' },
		currentMode: false,
	};

	/** table chart is visible */
	tableChartVisible = false;

	/** loading handler for the doughnut chart */
	loadingHandler: LoadingHandler<ActionStation, StationCount>;

	/**
	 * constructs the component
	 */
	constructor(
		private service: FailureOrderWorkflowService,
		private router: Router
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			null,
			this.service.getActionStations.bind(this.service),
			this.parseOrdersDoughnutChart,
			null,
			(data: Array<StationCount>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	/**
	 * fetch function for the table chart
	 */
	getFetchFunction() {
		return this.service.getActionStations.bind(this.service);
	}

	/**
	 * parse function for the table chart
	 * @param data array of actions
	 */
	parseOrders(data: Array<ActionStation>): Array<StationCount> {
		return StationCount.parseOrders(data, 'pickup');
	}

	/**
	 * gets invoked when clicking on station in the table widget
	 * @param data
	 */
	clickedInTableChart(data: { station: string; others: boolean }) {
		this.router.navigateByUrl(ProcessesSubMenuUrl.actions, {
			state: { station: data.station, action: 'pickup', others: data.others },
		});
	}

	/**
	 * gets invoked when clicking on a station in doughnut chart
	 * @param data
	 */
	clickedInDoughnutChart(data: { data: string; others: boolean }) {
		this.router.navigateByUrl(ProcessesSubMenuUrl.actions, {
			state: { station: data.data, action: 'pickup', others: data.others },
		});
	}

	/**
	 * parse function for the doughnut chart
	 * @param data array of orders
	 */
	parseOrdersDoughnutChart(data: Array<ActionStation>): Array<StationCount> {
		const result = StationCount.parseOrders(data, 'pickup');
		return result.length > 0 ? [result] : [];
	}

	/**
	 * interpolates the fetched values into the placeholders of the stations
	 * @param data
	 */
	interpolateLabels(data: Array<StationCount>) {
		const arr = data.map((item) => ({ [item.station]: item.count }));
		return Object.assign({}, ...arr);
	}

	/**
	 * gets invoked when header icons disabled changed
	 * @param event header icon disabled type
	 */
	headerDisabledChanged(event: HeaderIconsDisabled) {
		this.disabledOnLoadNoData = event[0];
		this.disableOnLoad = event[1];
	}
}
