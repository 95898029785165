import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProcessesSubMenuUrl } from '../../shared/enums';
import { FailureOrderWorkflowService } from '../../shared/services/failure-order-workflow/failure-order-workflow.service';
import { Order } from '../../shared/services/failure-order-workflow/order';
import { InterpolatedLabels } from '../components-for-widget-construction/interpolated-labels';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { WidgetColors } from '../../enums/widget-colors.enum';

// technical-, process-counter
type DoughnutData = [number, number];

@Component({
	selector: 'agilox-analytics-widget-processes-workflows',
	templateUrl: './widget-processes-workflows.component.html',
	styleUrls: ['./widget-processes-workflows.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProcessesWorkflowsComponent extends BaseWidgetDirective {
	colors: any = [WidgetColors[0], WidgetColors[1]];

	loadingHandler: LoadingHandler<Order, DoughnutData>;

	constructor(
		private orderService: FailureOrderWorkflowService,
		private router: Router,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			null,
			this.orderService.getOrdersForWidgets.bind(this.orderService),
			this.parseFunction,
			null,
			(data: Array<DoughnutData>) => {
				this.disableOnLoad = data === null;
				const res =
					this.disableOnLoad || data === undefined ? null : data[0]?.some((element) => element > 0);
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	parseFunction(data: Array<Order>): Array<DoughnutData> {
		const res: Array<DoughnutData> = [[0, 0]];
		data.forEach((order) => res[0][order.canceled ? 1 : 0]++);
		return res;
	}

	/**
	 * interpolates the fetched values into the placeholders of the translations
	 * @param data
	 */
	interpolateLabels(data: Array<number>): InterpolatedLabels {
		return {
			canceled: data[1],
			processed: data[0],
		};
	}

	/**
	 * clicked on an status in the doughnut chart
	 * @param status status of the workflow
	 */
	clickedOnStatus(status: string) {
		if (status) {
			const splitStatus = status.split('.');
			this.router.navigateByUrl(ProcessesSubMenuUrl.workflows, {
				state: {
					diagnose: this.translate.instant('processes.' + splitStatus[splitStatus.length - 1]),
				},
			});
		}
	}
}
