/**
 * Represents a workflow
 */
export class WorkflowData {
	/**
	 * generates a workflow
	 * @param serial serial of the machine
	 * @param agiloxName name of the vehicle
	 * @param union union
	 * @param name name
	 * @param id id
	 * @param start start string
	 * @param end end string
	 * @param processed processed
	 * @param canceled canceled workflows
	 * @param pickups pickups
	 * @param distance distance traveled
	 * @param duration duration of the workflows
	 * @param fastest fastest workflow
	 * @param slowest slowest workflow
	 * @param average average workflow
	 */
	constructor(
		public serial: string,
		public agiloxName: string,
		public union: string,
		public name: string, // workflow_name
		public id: number, // workflow_id
		public start: number, // start_date number,
		public end: number, // end_date number
		public processed: number,
		public canceled: number,
		public pickups: number,
		public distance: number, // distance number
		public duration: number, // ot number
		public fastest: number, // ot_fastest
		public slowest: number, // ot_slowest
		public average: number // ot_avg
	) {}

	/**
	 * generates Workflow from a received json object
	 * @param data data of the workflow request
	 * @param serial serial of the machine
	 */
	static generateFromJson(data: any): WorkflowData {
		return new WorkflowData(
			data.serial,
			data.agilox_name,
			data.union,
			data.workflow_name,
			data.workflow_id,
			data.start_date * 1000,
			data.end_date * 1000,
			data.success,
			data.canceled,
			data.pickups,
			data.distance / 1000 / 1000,
			data.ot,
			data.ot_fastest,
			data.ot_slowest,
			data.ot_avg
		);
	}
}
