import { ActionStation } from './action-station';

/** holds the station and the count to the station */
export class StationCount {
	constructor(
		public station: string,
		public count: number
	) {}

	static parseOrders(data: Array<ActionStation>, searchString: string): any {
		const maxStations = 9;
		let actions = data;

		// Filter all pickup actions with station
		actions = actions.filter(
			(action) =>
				action.station !== '' && action.station !== undefined && action.actionName === searchString
		);

		// count actions with same station
		const result = [
			...actions
				.reduce((wholeMap, action) => {
					const k = action.station;
					// contains the map already the description
					if (!wholeMap.has(k)) {
						wholeMap.set(k, { ...action, count: 1 });
					} else {
						wholeMap.get(k).count++;
					}
					return wholeMap;
				}, new Map() /* initial value */)
				.values(),
		];

		const arr = result
			.map((action) => {
				return { station: action.station, count: action.count };
			})
			.sort((a, b) => b.count - a.count);
		const topStations = arr.slice(0, maxStations);
		const othersCount = arr
			.filter((element) => !topStations.includes(element))
			.map((element) => element.count)
			.reduce((previous, current) => previous + current, 0);
		if (othersCount > 0) {
			topStations.push({ station: 'processes.others', count: othersCount });
		}
		// Return top 10 pickup stations + other
		return topStations;
	}
}
