import { inject, Inject, Injectable } from '@angular/core';
import {
	Environment,
	ENVIRONMENT,
	HttpParamsBuilder,
	Meta,
	Response,
	Vehicle,
} from '@agilox/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VehicleUnionSelectService {
	@Inject(ENVIRONMENT) public environment: Environment = inject(ENVIRONMENT);
	private http: HttpClient = inject(HttpClient);

	public fetchVehicles(
		search: string,
		meta: Meta,
		union?: string,
		serials?: string[]
	): Observable<Response<Vehicle>> {
		let params: HttpParams = HttpParamsBuilder.buildParameters(meta, undefined, search, undefined);
		if (!search) {
			/**
			 * If the search string is not empty
			 * we remove the prioritize parameter
			 */
			if (union) {
				params = params.append('prioritize[unionUuid]', union);
			} else if (serials?.length) {
				params = params.append('prioritize[serial]', serials.join(','));
			}
		}

		return this.http.get<Response<Vehicle>>(this.environment.server + '/v2/machineMgmt/vehicles', {
			params,
		});
	}
}
