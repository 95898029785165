/**
 * Represents an Azure object
 */
export class Azure {
	/**
	 * constructs a Azure object
	 * @param storageAccount storageAccount
	 * @param shared_access_key shared_access_key
	 * @param bbox bbox
	 * @param bboxWrite bboxWrite
	 * @param orderCi orderCi
	 * @param orderCiWrite orderCiWrite
	 * @param machineBackup machineBackup
	 * @param machineBackupWrite machineBackupWrite
	 * @param iotHub iotHub
	 * @param iotPassword iotPassword
	 * @param iotUsername iotUsername
	 * @param orderCiWriteArchive orderCiWriteArchive
	 * @param machineBackupWriteArchive machineBackupWriteArchive
	 * @param bboxWriteArchive bboxWriteArchive
	 */
	constructor(
		public storageAccount: string,
		public bbox: string,
		public bboxWrite: string,
		public orderCi: string,
		public orderCiWrite: string,
		public machineBackup: string,
		public machineBackupWrite: string,
		public iotHub: string,
		public iotPassword: string,
		public iotUsername: string,
		public orderCiWriteArchive: string,
		public machineBackupWriteArchive: string,
		public bboxWriteArchive: string
	) {}

	/**
	 * Generates a azure object from the json
	 * @param data Azure data JSON from the MachineMGMT/list endpoint
	 */
	static generateFromJson(data: any): Azure {
		return new Azure(
			data.storage_account,
			data.bbox_sas,
			data.bbox_sas_write,
			data.order_ci_sas,
			data.order_ci_sas_write,
			data.machine_backup_sas,
			data.machine_backup_sas_write,
			data.iot_hub,
			data.iothub_password,
			data.iothub_username,
			data.order_ci_archive_sas_write,
			data.machine_backup_archive_sas_write,
			data.bbox_archive_sas_write
		);
	}

	/**
	 * transforms an azure object to a valid json object for the server
	 */
	toJson(): any {
		return {
			storage_account: this.storageAccount,
			bbox_sas: this.bbox,
			bbox_sas_write: this.bboxWrite,
			order_ci_sas: this.orderCi,
			order_ci_sas_write: this.orderCiWrite,
			machine_backup_sas: this.machineBackup,
			machine_backup_sas_write: this.machineBackupWrite,
			iot_hub: this.iotHub,
			iothub_password: this.iotPassword,
			iothub_username: this.iotUsername,
			order_ci_archive_sas_write: this.orderCiWriteArchive,
			machine_backup_archive_sas_write: this.machineBackupWriteArchive,
			bbox_archive_sas_write: this.bboxWriteArchive,
		};
	}
}
