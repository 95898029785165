import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { ChartOptions, TooltipItem } from 'chart.js';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { MachineModel } from '@agilox/common';
import { TranslateService } from '@ngx-translate/core';
import { WidgetColors } from '../../enums/widget-colors.enum';

/**
 * This widgets displays the min, max and median of the cell voltage
 */
@Component({
	selector: 'agilox-analytics-widget-battery-difference',
	templateUrl: './widget-battery-difference.component.html',
	styleUrls: ['./widget-battery-difference.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBatteryDifferenceComponent extends BaseWidgetDirective {
	contextForTemplate: any;
	config: Array<EntryConfig>;
	unit = 'V';

	textholder: Array<any>;
	loadingHandler: LoadingHandler<Machine, ReducedMachine>;
	data: Array<ReducedMachine>;

	ownOptions: ChartOptions = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				min: 0,
				max: 0.1,
				suggestedMin: 0,
				suggestedMax: 0.1,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 3,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (d: TooltipItem<any>) => {
						return (
							this.translate.instant('widget.battery_difference.' + d.dataset.label) +
							d.formattedValue +
							this.unit
						);
					},
					footer: (item) => {
						return (
							'Min: ' +
							this.textholder[item[0].datasetIndex]?.min +
							' | Max: ' +
							this.textholder[item[0].datasetIndex]?.max
						);
					},
					afterFooter: (tooltipItems: TooltipItem<any>[]) => {
						const textholder = this.textholder[tooltipItems[0].datasetIndex];

						if (this.machineModel === MachineModel.OPS && textholder?.min_2 && textholder?.max_2) {
							return (
								this.translate.instant('widget.battery_difference.batt_2_min') +
								textholder.min_2 +
								' | ' +
								this.translate.instant('widget.battery_difference.batt_2_max') +
								textholder.max_2
							);
						} else return '';
					},

					labelColor: () => {
						return {
							borderColor: '#FFFFFF',
							backgroundColor: WidgetColors[0],
						};
					},
				},
			},
		},
	};

	private machineModel: string;

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(
		public chartService: ChartDataService,
		private translate: TranslateService,
		private changeDetection: ChangeDetectorRef
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getData,
			this.transformData.bind(this),
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.config = new Array<EntryConfig>();
		this.config.push(new EntryConfig('battDiff', { borderColor: WidgetColors[0] }));
	}

	/**
	 * parses the data accordingly
	 */
	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		this.textholder = new Array<any>();
		if (data[0]?.entries.length > 0) {
			this.machineModel = data[0].model;
			// getting all voltage-values
			const transformedData = data.map((machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry) => {
						const timelineEntries = new Array<Entry>();
						const wanted = 'battDiff';
						timelineEntries.push(new Entry(wanted, entry[wanted]));
						this.textholder.push({
							min: entry['battMin'],
							max: entry['battMax'],
						});

						if (this.machineModel === MachineModel.OPS) {
							timelineEntries.push(new Entry('batt_2_diff', entry['batt_2_diff']));
							this.textholder[this.textholder.length - 1] = {
								...this.textholder[this.textholder.length - 1],
								min_2: entry['batt_2_min'],
								max_2: entry['batt_2_max'],
							};
						}

						return new Timestamp(entry.start, timelineEntries);
					}),
				};
			});

			if (this.machineModel === MachineModel.OPS) {
				this.config.push(new EntryConfig('batt_2_diff', { borderColor: WidgetColors[1] }));
			}

			return transformedData;
		} else {
			return new Array<ReducedMachine>();
		}
	}
}
