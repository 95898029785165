import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';

@Component({
	selector: 'ui-union-select-option',
	templateUrl: './union-select-option.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionSelectOptionComponent {
	@Input() union: string = '';

	private _options: SelectOption<Vehicle>[] | null = null;

	@Input() set options(options: SelectOption<Vehicle>[] | null) {
		this._options = options;
	}

	@Input() selectedVehicles: Vehicle[] = [];

	@Output() deselectAll: EventEmitter<void> = new EventEmitter<void>();

	/** Select all vehicles in this union */
	@Output() selectAll: EventEmitter<void> = new EventEmitter<void>();

	get options(): SelectOption<Vehicle>[] | null {
		return this._options;
	}

	public handleClick() {
		if (this.selectedVehicles.length === this.options?.length) {
			this.deselectAll.emit();
		} else {
			this.selectAll.emit();
		}
	}

	/**
	 * Ignore the checkbox functionality,
	 * let the component handle the click event
	 * @param event
	 */
	public handleCheckboxClick(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.handleClick();
	}
}
