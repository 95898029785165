import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Failure } from '../../shared/services/failure-order-workflow/failure';
import { FailureCategoryColor } from '../../shared/services/failure-order-workflow/failure-category-color.enum';
import { FailureCategoryKey } from '../../shared/services/failure-order-workflow/failure-category.enum';
import { FailureOrderWorkflowService } from '../../shared/services/failure-order-workflow/failure-order-workflow.service';
import { InterpolatedLabels } from '../components-for-widget-construction/interpolated-labels';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { FailuresSubMenuUrl } from '../../shared/enums/failures-submenu.enum';

// technical-, process-counter
type DoughnutData = [number, number, number];

@Component({
	selector: 'agilox-analytics-widget-failures-types',
	templateUrl: './widget-failures-types.component.html',
	styleUrls: ['./widget-failures-types.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFailuresTypesComponent extends BaseWidgetDirective {
	colors: any = [
		FailureCategoryColor[FailureCategoryKey.ERROR],
		FailureCategoryColor[FailureCategoryKey.PROBLEM],
		FailureCategoryColor[FailureCategoryKey.WARNING],
	];

	loadingHandler: LoadingHandler<Failure, DoughnutData>;

	constructor(
		private failureService: FailureOrderWorkflowService,
		private router: Router
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			null,
			this.failureService.getFailuresForWidgets.bind(this.failureService),
			this.parseFunction,
			null,
			(data: Array<DoughnutData>) => {
				this.disableOnLoad = data === null;
				const res =
					this.disableOnLoad || data === undefined ? null : data[0]?.some((num) => num > 0);
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	parseFunction(data: Array<Failure>): Array<DoughnutData> {
		const res: Array<DoughnutData> = [[0, 0, 0]];
		data.forEach((failure) => res[0][['error', 'problem', 'warning'].indexOf(failure.type)]++);
		return res;
	}

	interpolateLabels(data: Array<number>, labels: Array<string>): InterpolatedLabels {
		return {
			errors: data[0],
			problems: data[1],
			warnings: data[2],
		};
	}

	/**
	 * gets invoked when clicking on a type in doughnut chart
	 * @param type
	 */
	clickedInDoughnutChart(type: any) {
		this.router.navigateByUrl(FailuresSubMenuUrl.overview, {
			state: {
				type: type?.split('.')[2],
			},
		});
	}
}
