<div class="widget-battery-usage">
	<agilox-analytics-widget-header
		[widgetName]="widgetName"
		[disabledOnLoadNoData]="disabledOnLoadNoData"
		[disableOnLoad]="disableOnLoad"
		[disableOnComb]="disableOnComb"
		[disableOnSum]="disableOnSum"
	>
	</agilox-analytics-widget-header>
	<div class="widget-battery-usage__container">
		<div
			*agiloxAnalyticsLoad="
				let elem of loadingHandler;
				disableOnComb: disableOnComb;
				disableOnSum: disableOnSum;
				dataAsArray: true
			"
		>
			<div class="widget-battery-usage__content" *ngIf="elem">
				<div class="widget-battery-usage__value">
					{{ elem.usage }}
				</div>
				<div class="widget-battery-usage__unit">kWh</div>
			</div>
		</div>
	</div>
</div>
