import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { MaterialModule } from '../material/material.module';
import { SharedDesktopModule } from '../shared-desktop/shared-desktop.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardEmptyComponent } from './components-for-widget-construction/dashboard-empty/dashboard-empty.component';
import { DoughnutChartComponent } from './components-for-widget-construction/doughnut-chart/doughnut-chart.component';
import { LoadDirective } from './components-for-widget-construction/load-directive/load.directive';
import { NotForApplianceComponent } from './components-for-widget-construction/not-for-appliance/not-for-appliance.component';
import { SimpleLineChartComponent } from './components-for-widget-construction/simple-line-chart/simple-line-chart.component';
import { TableChartComponent } from './components-for-widget-construction/table-chart/table-chart.component';
import { TimelineChartComponent } from './components-for-widget-construction/timeline-chart/timeline-chart.component';
import { WidgetHeaderComponent } from './components-for-widget-construction/widget-header/widget-header.component';
import { AnchorDirective } from './components-for-widget-construction/widget/anchor.directive';
import { WidgetComponent } from './components-for-widget-construction/widget/widget.component';
import { WidgetBatteryCurrentComponent } from './widget-battery-current/widget-battery-current.component';
import { WidgetBatteryDifferenceComponent } from './widget-battery-difference/widget-battery-difference.component';
import { WidgetBatteryLevelComponent } from './widget-battery-level/widget-battery-level.component';
import { WidgetBatteryTemperatureComponent } from './widget-battery-temperature/widget-battery-temperature.component';
import { WidgetBatteryUsageComponent } from './widget-battery-usage/widget-battery-usage.component';
import { WidgetChargeComponent } from './widget-charge-count/widget-charge-count.component';
import { WidgetCurrentSpeedComponent } from './widget-current-speed/widget-current-speed.component';
import { WidgetDistanceAbsoluteComponent } from './widget-distance-absolute/widget-distance-absolute.component';
import { WidgetDistanceComponent } from './widget-distance/widget-distance.component';
import { WidgetFailuresProcessComponent } from './widget-failures-process/widget-failures-process.component';
import { WidgetFailuresQuitComponent } from './widget-failures-quit/widget-failures-quit.component';
import { WidgetFailuresTechnicalComponent } from './widget-failures-technical/widget-failures-technical.component';
import { WidgetFailuresTypeHistoryComponent } from './widget-failures-type-history/widget-failures-type-history.component';
import { WidgetFailuresTypesComponent } from './widget-failures-types/widget-failures-types.component';
import { WidgetParkCountComponent } from './widget-park-count/widget-park-count.component';
import { WidgetPerformanceSummaryComponent } from './widget-performance-summary/widget-performance-summary.component';
import { WidgetPickupCountComponent } from './widget-pickup-count/widget-pickup-count.component';
import { WidgetProcessesCanceledComponent } from './widget-processes-canceled/widget-processes-canceled.component';
import { WidgetProcessesDropsComponent } from './widget-processes-drops/widget-processes-drops.component';
import { WidgetProcessesPickupsComponent } from './widget-processes-pickups/widget-processes-pickups.component';
import { WidgetProcessesWorkflowsComponent } from './widget-processes-workflows/widget-processes-workflows.component';
import { WidgetProductivityAnalysisComponent } from './widget-productivity-analysis/widget-productivity-analysis.component';
import { WidgetProductivityDistancePieComponent } from './widget-productivity-distance-pie/widget-productivity-distance-pie.component';
import { BarChartsComponent } from './widget-productivity-history/bar-charts/bar-charts.component';
import { SelectBarChartValuesComponent } from './widget-productivity-history/select-bar-chart-values/select-bar-chart-values.component';
import { WidgetProductivityHistoryComponent } from './widget-productivity-history/widget-productivity-history.component';
import { WidgetProductivitySummaryComponent } from './widget-productivity-summary/widget-productivity-summary.component';
import { WidgetRotationAbsoluteComponent } from './widget-rotation-absolute/widget-rotation-absolute.component';
import { WidgetWeightAbsoluteComponent } from './widget-weight-absolute/widget-weight-absolute.component';
import { WidgetWeightAnalysisComponent } from './widget-weight-analysis/widget-weight-analysis.component';
import { WidgetWeightComponent } from './widget-weight/widget-weight.component';
import { BlockInfoChartComponent } from './components-for-widget-construction/block-info-chart/block-info-chart.component';
import { SelectModule } from '@agilox/ui';
import { WidgetLiftHeightComponent } from './widget-lift-height/widget-lift-height.component';
import { WidgetLiftHeightAbsoluteComponent } from './widget-lift-height-absolute/widget-lift-height-absolute.component';
import { WidgetRotationDrivingUnitsComponent } from './widget-rotation-driving-units/widget-rotation-driving-units.component';
import { WidgetBatterySohComponent } from './widget-battery-soh/widget-battery-soh.component';
import { WidgetBatteryCellVoltageComponent } from './widget-battery-cell-voltage/widget-battery-cell-voltage.component';

@NgModule({
	declarations: [
		DashboardEmptyComponent,
		SimpleLineChartComponent,
		TimelineChartComponent,
		WidgetComponent,
		WidgetBatteryCellVoltageComponent,
		WidgetBatteryCurrentComponent,
		WidgetBatteryDifferenceComponent,
		WidgetBatteryLevelComponent,
		WidgetBatteryUsageComponent,
		WidgetBatteryTemperatureComponent,
		WidgetProductivityHistoryComponent,
		WidgetDistanceComponent,
		WidgetDistanceAbsoluteComponent,
		WidgetParkCountComponent,
		WidgetPerformanceSummaryComponent,
		WidgetPickupCountComponent,
		WidgetCurrentSpeedComponent,
		WidgetProductivityAnalysisComponent,
		BarChartsComponent,
		SelectBarChartValuesComponent,
		WidgetProductivitySummaryComponent,
		WidgetWeightComponent,
		WidgetWeightAbsoluteComponent,
		WidgetRotationAbsoluteComponent,
		WidgetHeaderComponent,
		NotForApplianceComponent,
		TableChartComponent,
		WidgetProcessesCanceledComponent,
		DoughnutChartComponent,
		WidgetChargeComponent,
		WidgetProductivityDistancePieComponent,
		WidgetProcessesWorkflowsComponent,
		WidgetProcessesPickupsComponent,
		WidgetProcessesDropsComponent,
		WidgetFailuresQuitComponent,
		WidgetFailuresProcessComponent,
		WidgetFailuresTechnicalComponent,
		WidgetFailuresTypesComponent,
		WidgetWeightAnalysisComponent,
		WidgetFailuresTypeHistoryComponent,
		AnchorDirective,
		LoadDirective,
		BlockInfoChartComponent,
		WidgetLiftHeightComponent,
		WidgetLiftHeightAbsoluteComponent,
		WidgetRotationDrivingUnitsComponent,
		WidgetBatterySohComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		MaterialModule,
		NgChartsModule,
		SharedDesktopModule,
		SelectModule,
	],
	exports: [
		NgChartsModule,
		DashboardEmptyComponent,
		SimpleLineChartComponent,
		TimelineChartComponent,
		WidgetComponent,
		WidgetHeaderComponent,
		WidgetBatteryCellVoltageComponent,
		WidgetBatteryCurrentComponent,
		WidgetBatteryDifferenceComponent,
		WidgetBatteryLevelComponent,
		WidgetBatteryUsageComponent,
		WidgetBatteryTemperatureComponent,
		WidgetProductivityHistoryComponent,
		WidgetDistanceComponent,
		WidgetDistanceAbsoluteComponent,
		WidgetParkCountComponent,
		WidgetPerformanceSummaryComponent,
		WidgetPickupCountComponent,
		WidgetCurrentSpeedComponent,
		WidgetProductivityAnalysisComponent,
		BarChartsComponent,
		SelectBarChartValuesComponent,
		WidgetProductivitySummaryComponent,
		WidgetWeightComponent,
		WidgetWeightAbsoluteComponent,
		WidgetRotationAbsoluteComponent,
		NotForApplianceComponent,
		TableChartComponent,
		WidgetProcessesCanceledComponent,
		DoughnutChartComponent,
		WidgetChargeComponent,
		WidgetProductivityDistancePieComponent,
		WidgetProcessesWorkflowsComponent,
		WidgetProcessesPickupsComponent,
		WidgetProcessesDropsComponent,
		WidgetFailuresQuitComponent,
		WidgetFailuresProcessComponent,
		WidgetFailuresTechnicalComponent,
		WidgetFailuresTypesComponent,
		WidgetWeightAnalysisComponent,
		WidgetFailuresTypeHistoryComponent,
		AnchorDirective,
		LoadDirective,
		BlockInfoChartComponent,
		WidgetLiftHeightComponent,
		WidgetLiftHeightAbsoluteComponent,
		WidgetRotationDrivingUnitsComponent,
		WidgetBatterySohComponent,
	],
})
export class WidgetsModule {}
