export class ActionStation {
	constructor(
		public station: string,
		public actionName: string
	) {}

	static parseDataFromResponse(data: any) {
		return new ActionStation(data.station ?? '', data.action);
	}
}
