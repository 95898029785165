<div class="widget-header">
	<div class="widget-header__container">
		<div class="widget-header__title">
			<div class="widget-header__name">{{ title | translate }}</div>
			<div class="widget-header__beta">
				<ui-tag *ngIf="beta" text="BETA"></ui-tag>
			</div>
		</div>

		<div
			class="widget-header__iconsMobile hide-desktop"
			(click)="!isDisabled(true) ? toggleIcons($event, true) : false"
		>
			<ui-icon
				icon="menu_hamburger"
				size="l"
				type="dark"
				[action]="true"
				[disabled]="isDisabled(true)"
			></ui-icon>
		</div>
		<div class="widget-header__icons" [class.is-visible]="iconsOpenMobile">
			<ng-content></ng-content>

			<agilox-analytics-binary-toggler
				[disableIcon]="!disabledOnLoadNoData"
				[rightSide]="switchChart.rightChartConfig"
				[leftSide]="switchChart.leftChartConfig"
				[currentMode]="switchChart.currentMode"
				(currentModeChange)="barChartSwitchToggled($event)"
				*ngIf="chartSwitch"
				directiveGoogleAnalytics
				eventAction="click: widget-barchart"
				[eventLabel]="widgetConfig.name"
			>
			</agilox-analytics-binary-toggler>

			<ng-container *ngIf="widgetConfig && !!widgetConfig.onExport">
				<ui-icon
					class="widget-header__icon"
					icon="download_png"
					size="l"
					type="dark"
					[uiTooltip]="'widget.general.download_png' | translate"
					[action]="true"
					[disabled]="isDisabled(false)"
					(iconClicked)="widgetConfig.onExport()"
				>
				</ui-icon>
			</ng-container>

			<ui-icon
				[uiTooltip]="
					(isWidgetPinnedToDashboard
						? 'widget.general.unpin_dashboard'
						: 'widget.general.pin_dashboard'
					) | translate
				"
				class="widget-header__icon"
				icon="pin"
				size="l"
				[type]="isWidgetPinnedToDashboard ? 'primary' : 'dark'"
				[action]="true"
				(iconClicked)="toggleOnDashboard()"
				[disabled]="isDisabled(true)"
			>
			</ui-icon>
			<ui-icon
				[uiTooltip]="'widget.general.help' | translate"
				class="widget-header__icon"
				icon="o_quesmark"
				size="l"
				type="dark"
				[action]="true"
				(iconClicked)="toggleHelp()"
				[disabled]="isDisabled(true)"
				directiveGoogleAnalytics
				eventAction="click: widget-help"
				[eventLabel]="widgetConfig.name"
			>
			</ui-icon>
		</div>
	</div>
</div>
