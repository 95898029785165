<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
></agilox-analytics-widget-header>

<agilox-analytics-doughnut-chart
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[interpolateLabels]="interpolateLabels"
	[labels]="[
		'widget.failures_types.error',
		'widget.failures_types.problem',
		'widget.failures_types.warning',
	]"
	[loadingHandler]="loadingHandler"
	[colors]="colors"
	[interactive]="true"
	(clickedItem)="clickedInDoughnutChart($event)"
	data-cy="doughnut-chart-failures-types"
	directiveGoogleAnalytics
	eventAction="click: doughnut-chart-click"
	eventLabel="widget-failures-types"
>
</agilox-analytics-doughnut-chart>
