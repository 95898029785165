<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
>
</agilox-analytics-widget-header>

<div
	class="loading-handler"
	*agiloxAnalyticsLoad="
		let elem of loadingHandler;
		disableOnSum: disableOnSum;
		disableOnComb: disableOnComb
	"
>
	<agilox-analytics-timeline-chart
		[data]="elem.data"
		[unit]="unit"
		[entryConfigs]="config"
		[options]="ownOptions"
	>
	</agilox-analytics-timeline-chart>
</div>
