<div
	class="flex gap-2 h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
	[ngClass]="{
		'bg-primary-200': selected,
		'text-secondary-400': option.isDisabled,
		'cursor-not-allowed': option.isDisabled,
	}"
	(click)="toggleItemHandler(option)"
	data-cy="c-vehicle-union-select-select-option"
>
	<ui-checkbox
		[disabled]="option.isDisabled || false"
		[checked]="selected"
		(checkedChange)="toggleItemHandler(option)"
		class="mr-4 -mt-[1.625rem]"
	></ui-checkbox>
	<div class="flex items-center justify-between -ml-4 w-full gap-2">
		<div class="flex items-center gap-1">
			@if (option.isDisabled && hasSubscriptionExpired()) {
				<ui-icon
					[uiTooltip]="'subscription.expired' | translate"
					icon="failures"
					size="xs"
					type="problem"
				></ui-icon>
			}
			@if (!option.value?.active) {
				<ui-icon icon="decommissioned"></ui-icon>
			}

			<div class="line-clamp-1 max-w-full" [uiTooltip]="option.title">
				{{ option.title }}
			</div>
		</div>
		@if (displayPills) {
			<div class="flex gap-1">
				@if (option.value?.maas) {
					<ui-pill [value]="'general.maas' | translate" class="mr-2"></ui-pill>
				}

				<ui-pill
					[value]="option.pillText"
					[disabled]="option.isDisabled ?? false"
					type="secondaryOutline"
				></ui-pill>
			</div>
		}
	</div>
</div>
