import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { saveMultipleVehicles, setVehicles } from './vehicle-filter.actions';
import { setMode } from '../mode-filter/mode-filter.actions';

@Injectable()
export class VehicleFilterEffects {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveMultipleSelectedVehicles$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveMultipleVehicles),
				switchMap((action: { value: string[] }) =>
					this.appSettingsService
						.saveVehiclesAndMode(action.value)
						.pipe(
							mergeMap((filter: ApplicationSettings) => [
								setVehicles({ value: filter.selectedMachines }),
								setMode({ value: filter.dateSelector.mode }),
							])
						)
				)
			),
		{ dispatch: true }
	);
}
