<ui-spinner *ngIf="!data && isLoading" [small]="small" [light]="light"></ui-spinner>
<div *ngIf="isLoading && data && data.length === 0" class="spinner">
	<ui-empty-state
		[isAbsolute]="true"
		[text]="isMachineSelected ? 'general.no_data' : 'general.no_machines'"
		[buttonText]="
			!noLink ? (isMachineSelected ? 'general.change_date' : 'general.change_machines') : ''
		"
		(buttonClicked)="isMachineSelected ? openDateSelector($event) : openMachineSelector($event)"
		*ngIf="!small"
	>
	</ui-empty-state>
</div>
