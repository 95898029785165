import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { ChartOptions, TooltipItem } from 'chart.js';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { MachineModel } from '@agilox/common';
import { WidgetColors } from '../../enums/widget-colors.enum';

/**
 * This widget displays the 14 different voltages of a battery
 */
@Component({
	selector: 'agilox-analytics-widget-battery-cell-voltage',
	templateUrl: './widget-battery-cell-voltage.component.html',
	styleUrls: ['./widget-battery-cell-voltage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class WidgetBatteryCellVoltageComponent extends BaseWidgetDirective {
	config: Array<EntryConfig>;
	colors = WidgetColors;
	unit = 'V';

	ownOptions: ChartOptions = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				ticks: {
					autoSkip: true,
					maxTicksLimit: 5,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				titleFont: {
					size: 10,
				},
				bodyFont: {
					size: 10,
				},
				position: 'nearest',
				yAlign: 'bottom',
				displayColors: true,
				enabled: false,
				external: function (tooltipModel: any) {
					/**
					 * Need to implement a custom tooltip
					 * as there are too many values to display
					 * https://www.chartjs.org/docs/latest/samples/tooltip/html.html
					 */

					// Tooltip Element
					let tooltipEl = document.getElementById('chartjs-tooltip');
					tooltipModel = tooltipModel.tooltip;

					if (!tooltipModel.body) {
						return;
					}

					function getBody(bodyItem) {
						// remove duplicates
						return bodyItem.lines.filter(
							(value: string, index: number, self: any) => self.indexOf(value) === index
						);
					}

					let bodyLines = tooltipModel.body.map(getBody);

					// Create element on first render
					if (!tooltipEl) {
						tooltipEl = document.createElement('div');
						tooltipEl.id = 'chartjs-tooltip';
						tooltipEl.innerHTML = `<div class="flex flex-col text-xs p-2 gap-2 text-white" id="tooltip"></div>`;
						document.body.appendChild(tooltipEl);
					}

					// Hide if no tooltip
					if (tooltipModel.opacity === 0) {
						tooltipEl.style.opacity = '0';
						return;
					}

					// Set caret position
					tooltipEl.classList.remove('above', 'below', 'no-transform');
					if (tooltipModel.yAlign) {
						tooltipEl.classList.add(tooltipModel.yAlign);
					} else {
						tooltipEl.classList.add('no-transform');
					}
					let tableRoot = tooltipEl.querySelector('#tooltip');
					let body: string = `<div class="flex gap-2 ${bodyLines.length > 14 ? 'min-w-60' : ''}">`;
					// Set Text
					if (tooltipModel.body) {
						let left: string = '<div class="flex flex-col">';
						let right: string = '<div class="flex flex-col">';

						bodyLines.forEach(function (body: string, i: number) {
							let div: string = '<div class="flex gap-1 justify-start items-center">';
							const boxStyle = `background: ${tooltipModel.labelColors[i].backgroundColor}`;
							let box: string = `<span class="border-white h-3 w-3 border" style="${boxStyle}"></span>`;
							if (i < 14) {
								left += div + box + body + '</div>';
							} else {
								right += div + box + body + '</div>';
							}
						});

						body += left + '</div>' + right + '</div></div>';

						let title: string = '<span class="font-semibold">' + tooltipModel.title[0] + '</span>';

						tableRoot.innerHTML = title + body;
					}

					// `this` will be the overall tooltip
					let position = this.chart.canvas.getBoundingClientRect();

					// Display, position, and set styles for font
					tooltipEl.style.opacity = '1';
					tooltipEl.style.position = 'absolute';
					tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
					tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
					tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
					tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
					tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
					tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
					tooltipEl.style.pointerEvents = 'none';
				},

				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						return (
							this.translate.instant(
								'widget.cell_voltage.' + this.config[tooltipItem.datasetIndex].name
							) +
							': ' +
							tooltipItem.formattedValue +
							this.unit
						);
					},
					labelColor: (tooltipItem: TooltipItem<any>) => {
						return {
							backgroundColor: this.colors[tooltipItem['datasetIndex']],
							borderColor: this.colors[tooltipItem['datasetIndex']],
						};
					},
				},
			},
		},
	};

	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(
		chartService: ChartDataService,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getData,
			this.transformData.bind(this),
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
		this.config = new Array<EntryConfig>();
		for (let i = 0; i < 14; i++) {
			this.config.push(new EntryConfig('battVol' + i, { borderColor: this.colors[i] }));
		}
	}

	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		if (data[0]?.entries.length) {
			this.setConfig(data[0].model);
			return data.map((machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry) => {
						// getting the 14 different voltage values
						const timelineEntries = new Array<Entry>();
						for (let i = 0; i < 14; i++) {
							const wanted = 'battVol' + i;
							timelineEntries.push(new Entry(wanted, entry[wanted]));
						}
						if (data[0].model === MachineModel.OPS) {
							for (let i = 0; i < 14; i++) {
								const wanted = 'battVol' + i + '_2';
								timelineEntries.push(new Entry(wanted, entry[wanted] ?? 0));
							}
						}

						return new Timestamp(entry.start, timelineEntries);
					}),
				};
			});
		} else {
			return new Array<ReducedMachine>();
		}
	}

	private setConfig(machineModel: string) {
		if (machineModel === MachineModel.OPS) {
			for (let i = 0; i < 14; i++) {
				this.config.push(
					new EntryConfig('battVol' + i + '_2', { borderColor: this.colors[i + 14] })
				);
			}
		}
	}
}
