import { createFeature, createReducer, on } from '@ngrx/store';
import { Mode } from '../../general/shared/services/appsettings/mode.enum';
import { setCombinedMode, setMode } from './mode-filter.actions';

export interface ModeFilter {
	mode: Mode;
	combined: boolean;
}

const initialState: ModeFilter = {
	mode: Mode.each,
	combined: false,
};

const reducer = createReducer(
	initialState,
	on(setMode, (state, action) => ({ ...state, mode: action.value })),
	on(setCombinedMode, (state, action) => ({ ...state, combined: action.value }))
);
export const modeFilterFeature = createFeature({
	name: 'globalModeFilter',
	reducer,
});
