<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
>
</agilox-analytics-widget-header>
<agilox-analytics-doughnut-chart
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[interpolateLabels]="interpolateLabels"
	[attr.data-cy]="'doughnut-chart-processes-workflows'"
	[labels]="['widget.processes_workflows.processed', 'widget.processes_workflows.canceled']"
	[colors]="colors"
	[loadingHandler]="loadingHandler"
	[interactive]="true"
	(clickedItem)="clickedOnStatus($event)"
>
</agilox-analytics-doughnut-chart>
