import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { Vehicle } from '@agilox/common';

@Pipe({
	name: 'vehicleSelectUnionCheckbox',
})
export class VehicleSelectUnionCheckboxPipe implements PipeTransform {
	transform(
		union: string,
		options: SelectOption<Vehicle>[] | null,
		selectedVehicles: Vehicle[]
	): 'checkmark' | 'minus' {
		if (!options) {
			return 'minus';
		}

		let unionVehicles = options.filter(
			(option: SelectOption<Vehicle>) => option.value?.union === union && option.value
		);

		if (unionVehicles.length === 0) {
			return 'checkmark';
		}

		// remove all union vehicles which do not have a serial
		unionVehicles = unionVehicles.filter(
			(unionVehicle: SelectOption<Vehicle>) => unionVehicle.value?.serial
		);

		const allSelected: boolean = unionVehicles.every((unionVehicle: SelectOption<Vehicle>) =>
			selectedVehicles.find((vehicle: Vehicle) => vehicle.serial === unionVehicle.value!.serial)
		);

		return allSelected ? 'checkmark' : 'minus';
	}
}
