import { DataRowOutlet } from '@angular/cdk/table';
import { Directive, Input, Renderer2 } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ExportType } from './export-type';
import { ExcelOptions, Options, TxtOptions } from './options';
import { ExtractorService } from './extractor.service';
import { Exporter } from './exporters/exporter';
import { ServiceLocatorService } from './service-locator.service';

/**
 * Exporter class for CdkTable. Abstracts the varying behaviors among different CdkTable implementations.
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
@Directive()
export abstract class CdkTableExporter {
	private _exporterService: Exporter<Options>;

	@Input() columns: Array<string>;

	/** index of hidden columns */
	private _hiddenColumns: Array<number>;
	@Input() set hiddenColumns(value: Array<string | number>) {
		if (value) {
			if (typeof value[0] === 'string') {
				const result = [];
				this.columns.forEach((column, index) => {
					if (value.includes(column)) {
						result.push(index);
					}
				});
				this._hiddenColumns = result;
			} else {
				this._hiddenColumns = value as Array<number>;
			}
		}
	}
	get hiddenColumns(): Array<number> {
		return this._hiddenColumns;
	}
	@Input() exporter?: Exporter<Options>;
	exportCompleted = new Subject<void>();
	exportStarted = new Subject<void>();

	/**
	 * Data array which is extracted from nativeTable
	 */
	private _data: Array<any>;

	private _isIterating: boolean;

	public abstract _initialPageIndex: number;

	private _isExporting: boolean;

	private _subscription: Subscription;

	private _options?: Options;

	private _selectedRows?: Array<number>;

	constructor(
		protected renderer: Renderer2,
		private serviceLocator: ServiceLocatorService,
		private dataExtractor: ExtractorService,
		protected _cdkTable: any
	) {}

	public abstract getPageCount(): number;

	public abstract getPageSize(): number;

	public abstract getCurrentPageIndex(): number;

	public abstract getTotalItemsCount(): number;

	public abstract goToPage(index: number): void;

	public abstract getPageChangeObservable(): Observable<any>;

	exportTable(
		exportType?: ExportType | 'xlsx' | 'csv' | 'other',
		options?: Options | ExcelOptions | TxtOptions
	) {
		this.loadExporter(exportType);
		this._options = options;
		this.exportStarted.next();
		this._isIterating = true;
		this._isExporting = true;
		this._data = new Array<any>();
		this.extractTableHeader();
		try {
			this.exportWithPagination();
		} catch (notPaginated) {
			this.exportSinglePage();
		}
	}

	toggleRow(index: number): void {
		const paginatedRowIndex: number = this.getPaginatedRowIndex(index);
		if (this.isToggleOn(paginatedRowIndex)) {
			this.toggleOff(paginatedRowIndex);
		} else {
			this.toggleOn(paginatedRowIndex);
		}
	}

	resetToggleRows() {
		this._selectedRows = [];
	}

	private toggleOn(index: number) {
		this._selectedRows = [...(this._selectedRows || []), index];
	}

	private toggleOff(index: number) {
		this._selectedRows = this._selectedRows.filter((x) => x !== index);
	}

	private isToggleOn(index: number): boolean {
		return this._selectedRows?.includes(index);
	}

	private loadExporter(exportType: any) {
		if (exportType === ExportType.OTHER.valueOf()) {
			this._exporterService = this.exporter;
		} else {
			this._exporterService = this.serviceLocator.getService(exportType);
		}
	}

	private exportWithPagination() {
		this._initialPageIndex = this.getCurrentPageIndex();
		this.initPageHandler();
	}

	private exportSinglePage() {
		this.extractDataOnCurrentPage();
		this.extractTableFooter();
		this.exportExtractedData();
	}

	private extractDataOnCurrentPage() {
		const rows = this.dataExtractor.extractRows(this._cdkTable, this.hiddenColumns);
		this._data = this._data.concat(this.getSelectedRows(rows));
	}

	private getSelectedRows(rows: Array<any>) {
		if (this.isSelectiveExport()) {
			return rows.filter((_, i) => this._selectedRows.includes(this.getPaginatedRowIndex(i)));
		} else {
			return rows;
		}
	}

	private isSelectiveExport(): boolean {
		return this._selectedRows && !this.isMasterToggleOff() && !this.isMasterToggleOn();
	}

	private isMasterToggleOn(): boolean {
		return this.compareSelectedRowCount(this.getTotalItemsCount());
	}

	private isMasterToggleOff(): boolean {
		return this.compareSelectedRowCount(0);
	}

	private compareSelectedRowCount(rowCount: number): boolean {
		return !!(this._selectedRows?.length === rowCount);
	}

	private initPageHandler(): void {
		if (!this._subscription) {
			this.handlePage();
			this._subscription = this.getPageChangeObservable().subscribe(() => {
				this.handlePage();
			});
		}
	}

	private handlePage() {
		setTimeout(() => {
			if (this._isIterating) {
				this.extractDataOnCurrentPage();
				if (this.hasNextPage()) {
					this.nextPage();
				} else {
					this._isIterating = false;
					this.handlePage();
				}
			} else if (this._isExporting) {
				this._isExporting = false;
				this.extractTableFooter();
				this.exportExtractedData();
			}
		});
	}

	private exportExtractedData() {
		this._exporterService.export(this._data, this._options);
		this._data = new Array<any>();
		this.exportCompleted.next();
	}

	private extractSpecialRows(outlet: DataRowOutlet) {
		this._data.push(...this.dataExtractor.extractRows(this._cdkTable, this.hiddenColumns, outlet));
	}

	private extractTableHeader() {
		this.extractSpecialRows(this._cdkTable._headerRowOutlet);
	}

	private extractTableFooter() {
		this.extractSpecialRows(this._cdkTable._footerRowOutlet);
	}

	private hasNextPage(): boolean {
		if (this.getCurrentPageIndex() < this.getPageCount() - 1) {
			return true;
		} else {
			return false;
		}
	}

	private nextPage(): void {
		this.goToPage(this.getCurrentPageIndex() + 1);
	}

	private getPaginatedRowIndex(index: number): number {
		return index + this.getPageSize() * this.getCurrentPageIndex();
	}
}
