<div>
	<label class="form-label" for="ui-vehicles-select">{{ 'general.vehicles' | translate }}</label>

	<ui-select
		[options]="sortedVehicles$ | async | vehicleSelect: selectedVehicles"
		[formControl]="formControl"
		(saved)="onSave()"
		fieldToCompare="serial"
		[searchEnabled]="true"
		(search)="onSearch($event)"
		(scrollEnd)="onScroll()"
		[multiple]="true"
		inputId="ui-vehicles-select"
		(opened)="onOpened()"
		[maxSelections]="maxSelections"
		[maxSelectionsText]="maxSelectionsText"
		[selectAllEnabled]="false"
		[fullDropdownWidth]="fullDropdownWidth"
		[loading]="loading()"
		[parentSearch]="true"
	>
		<ng-container *uiSelectCustomOption="let elem">
			@if (elem.value | checkSubscriptionExpireSoon) {
				<ui-icon
					class="mr-2 -ml-2"
					icon="failures"
					[uiTooltip]="
						'general.subscription_for_vehicle_expire_soon'
							| translate
								: {
										machines: elem.value.name,
								  }
					"
				></ui-icon>
			}
			<span class="whitespace-normal break-all grow line-clamp-1 -ml-2">
				{{ elem.title }}
			</span>
		</ng-container>
	</ui-select>
</div>
