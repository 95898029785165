<agilox-analytics-simple-line-chart
	[widgetName]="widgetName"
	[attribute]="'z'"
	[fetchFunction]="'getData'"
	[disableOnSum]="disableOnSum"
	[disableOnComb]="disableOnComb"
	[integerYAxis]="true"
	[barChart]="false"
	[chartSwitch]="true"
	[unit]="'mm'"
	[resetBarChartOptions]="true"
	[dataAsArray]="true"
	[noLowerBound]="true"
></agilox-analytics-simple-line-chart>
