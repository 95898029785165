<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[(barChart)]="areBarsVisible"
	[chartSwitch]="true"
>
	<agilox-analytics-select-bar-chart-values
		*ngIf="areBarsVisible"
		(selectedValues)="hideBarChartValues($event)"
		class="hide-mobile"
	></agilox-analytics-select-bar-chart-values>

	<div
		[uiTooltip]="'widget.general.download_csv' | translate"
		class="productivity-history__headerIcon"
	>
		<ui-icon
			class="productivity-history__icon"
			icon="download_csv"
			size="l"
			type="dark"
			[action]="true"
			(iconClicked)="exportCsv()"
		></ui-icon>
	</div>
</agilox-analytics-widget-header>

<div
	class="loading-handler"
	*agiloxAnalyticsLoad="
		let elem of loadingHandler;
		disableOnComb: disableOnComb;
		disableOnSum: disableOnSum
	"
>
	<agilox-analytics-bar-charts
		*ngIf="areBarsVisible && appsettings.dateSelector.mode !== 'comb'"
		[doNotShow]="excludedBarChartValues"
		[customOptions]="customBodyOptions"
	>
	</agilox-analytics-bar-charts>

	<agilox-analytics-timeline-chart
		[data]="elem.data"
		[unit]="'%'"
		[entryConfigs]="config"
		[options]="ownOptions"
		[fill]="true"
		*ngIf="!areBarsVisible"
	>
	</agilox-analytics-timeline-chart>
</div>
