/**
 * represents the summary of the last week and this week
 * gets returned from the weekOrderTrend
 */
export class WeekSummary {
	/**
	 * constrcuts the object
	 * @param serial serial
	 * @param pickups pickups of the current week
	 * @param pickupsPct pickupsPct of the current week
	 * @param pickupsSkip pickupsSkip of the current week
	 * @param pickupsLastWeek pickupsLastWeek
	 * @param pickupsSkipLastWeekPct pickupsSkipLastWeekPct
	 * @param pickupsSkipLastWeek pickupsSkipLastWeek
	 */
	constructor(
		public serial: string,
		public pickups: number,
		public pickupsPct: number,
		public pickupsSkip: number,
		public pickupsLastWeek: number,
		public pickupsLastWeekPct: number,
		public pickupsSkipLastWeek: number
	) {}

	/**
	 * constructs the WeekSummary from json
	 * @param serial serial
	 * @param data data from the request
	 */
	static generateFromJson(serial: string, data: any): WeekSummary {
		return new WeekSummary(
			serial,
			data.pickup_this_week,
			data.pickup_this_week_pct.toFixed(2),
			data.pickup_skip_this_week,
			data.pickup_last_week,
			data.pickup_last_week_pct.toFixed(2),
			data.pickup_skip_last_week
		);
	}
}
