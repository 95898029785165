<ui-button
	type="secondary"
	[text]="'general.export' | translate"
	[size]="size"
	[disabled]="!data?.length || showSpinner || disabled"
	(buttonClicked)="data?.length && !showSpinner && !disabled ? exportTable(exporter) : false"
	[showSpinner]="showSpinner"
	directiveGoogleAnalytics
	eventAction="click: export-button"
	[eventLabel]="fileName"
	[fullWidth]="fullWidth"
	icon="media_csv"
></ui-button>
