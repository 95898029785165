import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { WidgetConfig } from 'apps/analytics/src/app/general/shared/services/appsettings/widget-config';
import { WidgetConfigResolverService } from '../../widget-config-resolver.service';
import { AnchorDirective } from './anchor.directive';

/**
 * Is the basecomponent for all widgets. As an Attribute/Input pass the
 * config for the widget. The component injects automatically the correct
 * component for the charts.
 *
 * When adding a new component:
 * 1. add the case in the switch in widget.service getWidget-method
 * 2. add the case in the switch in widget.config isValidWidget-method
 * 3. go for it
 */
@Component({
	selector: 'agilox-analytics-widget',
	templateUrl: './widget.component.html',
	styleUrls: ['./widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetComponent implements AfterViewInit, OnDestroy {
	/**
	 * config of the widget
	 */
	@Input() widgetConfig: WidgetConfig;

	/**
	 * the widget gets injected there
	 */
	@ViewChild(AnchorDirective) anchor: AnchorDirective;

	/**
	 * the componentRef is needed for invoking manualy all
	 * lifecycle hooks, because we create the component outside the lifecycle
	 */
	private componentRef: ComponentRef<any> = null;

	/**
	 * base component for every widget
	 * @param widgetConfigResolverService widget config resolver service
	 */
	constructor(private widgetConfigResolverService: WidgetConfigResolverService) {}

	/**
	 * Injects a widget a place of the agiloxAnalyticsAnchor directive
	 * https://angular.io/guide/dynamic-component-loader
	 */
	ngAfterViewInit() {
		this.injectComponent();
	}

	/**
	 * detaches the dynamical loaded component
	 */
	ngOnDestroy() {
		// if an invalid component name was given componentRef is null
		// and this would lead to an exception
		this.componentRef?.changeDetectorRef.detach();
	}

	/**
	 * injects the component into the container
	 */
	injectComponent() {
		this.componentRef = this.anchor.viewContainerRef.createComponent(
			this.widgetConfigResolverService.getWidget(this.widgetConfig)
		);
		this.componentRef.instance.widgetName = this.widgetConfig.name;
		this.componentRef.instance.disableOnComb = this.widgetConfig.disableOnComb;
		this.componentRef.instance.disableOnSum = this.widgetConfig.disableOnSum;
		this.componentRef.changeDetectorRef.detectChanges();
	}
}
