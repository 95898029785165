<div class="bar-charts">
	<agilox-analytics-spinner [data]="noData ? [] : chartDataToShowHolder"></agilox-analytics-spinner>
	<div
		[hidden]="!chartDataToShowHolder || noData"
		class="bar-charts__chartContainer"
		*ngFor="let datasets of chartDataToShowHolder; let idx = index"
	>
		<canvas
			class="bar-charts__canvas"
			baseChart
			[labels]="chartLabelsHolder[idx]"
			[type]="'bar'"
			[options]="chartOptions"
			[datasets]="datasets.datasets"
		></canvas>
	</div>
</div>
