import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { FailuresEntry } from 'apps/analytics/src/app/menupoints/failures/failures-history/failures-entry';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Action } from './action';
import { ActionGroup } from './action-group';
import { ActionStation } from './action-station';
import { Failure } from './failure';
import { FailureCategory } from './failure-category';
import { FailureCategoryKey } from './failure-category.enum';
import { Order } from './order';
import { WorkflowData } from './workflow-data';
import { WorkflowName } from './workflow-name';
import { environment } from '@analytics/env/environment';

/**
 * is responsible for fetching orders, failures and workflows
 */
@Injectable({
	providedIn: 'root',
})
export class FailureOrderWorkflowService {
	constructor(
		private http: HttpClient,
		private appsettings: AppsettingsService
	) {}

	/**
	 * fetches all orders from the server
	 * @param observer gets notified
	 */
	getOrders(observer: (data: Array<Order> | string) => void, serials: Array<string>): Subscription {
		const reqObj = this.appsettings.generateReqObject(true);

		/**
		 * Shift is not needed for the orders
		 */
		if (reqObj.shift) {
			reqObj.shift.on = false;
		}

		if (reqObj.serial.length !== 0 && serials.length) {
			if (serials) {
				reqObj.serial = serials;
			}
			return this.http
				.post(environment.server + '/v2/Stats/order', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr: Array<Order> = new Array<Order>();
							for (const item of data.data) {
								arr.push(Order.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches all orders from the server
	 * @param observer gets notified
	 * @param serials all serials
	 */
	getActionStations(
		observer: (data: Array<ActionStation> | string) => void,
		serials?: Array<string>
	): Subscription {
		const reqObj = this.appsettings.generateReqObject();
		if (reqObj.serial.length !== 0) {
			if (serials?.length) {
				reqObj.serial = serials;
			}
			return this.http
				.post(environment.server + '/v2/Stats/actions', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const result = new Array<ActionStation>();
							for (const item of data.data as Array<any>) {
								result.push(ActionStation.parseDataFromResponse(item));
							}
							return result;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the same data as getFailures, but for the widgets-fetchfunction we need other parameters
	 * @param observer observer
	 * @param renderedInShowAll if all machines should get rendered
	 * @returns Subscription
	 */
	getOrdersForWidgets(
		observer: (data: Array<Order>) => void,
		renderedInShowAll = false
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(renderedInShowAll);
		if (reqObj.serial.length !== 0) {
			return this.http
				.post(environment.server + '/v2/Stats/order', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Order>();
							for (const item of data.data) {
								arr.push(Order.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the failures from the server and returns the subscription for manual unsubscribing
	 * @param observer gets notified
	 */
	getFailures(
		observer: (data: Array<Failure> | string) => void,
		serials?: Array<string>
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(true);
		if (reqObj.serials?.length !== 0) {
			if (serials?.length) {
				reqObj.serial = serials;
			}

			return this.http
				.post(environment.server + '/v2/Stats/failureLog', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Failure>();
							for (const item of data.data) {
								arr.push(Failure.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the same data as getFailures, but for the widgets-fetchfunction we need other parameters
	 * @param observer observer
	 * @param renderedInShowAll if all machines should get rendered
	 * @returns Subscription
	 */
	getFailuresForWidgets(
		observer: (data: Array<Failure>) => void,
		renderedInShowAll = false
	): Subscription {
		const reqObj = this.appsettings.generateReqObject(renderedInShowAll);
		if (reqObj.serial.length !== 0) {
			return this.http
				.post(environment.server + '/v2/Stats/failureLog', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							const arr = new Array<Failure>();
							for (const item of data.data) {
								arr.push(Failure.generateFromJson(item));
							}
							return arr;
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}

	/**
	 * fetches the workflows from the server and returns the subscriptions for manual unsubscribing
	 * @param observer gets notified
	 * @param zeroShift should it include 0 shifts
	 * @param shiftStart start of the shift
	 * @param shiftLength length of the shift
	 * @param workflows selected workflows
	 */
	getWorkflowData(
		observer: (data: Array<WorkflowData>) => void,
		shiftStart: number,
		shiftLength: number,
		workflows: Array<WorkflowName>,
		numberOfShifts: number,
		selectedVehicles: Array<string>
	): Subscription {
		const reqObject = this.appsettings.generateReqObject(true);

		// set start time
		const from = new Date(reqObject.from * 1000);
		const time = shiftStart / 1000 / 60 / 60;
		const hours = Math.floor(time);
		const minutes = Math.round((time - Math.floor(time)) * 60);

		from.setUTCHours(hours);
		from.setUTCMinutes(minutes);

		reqObject.from = from.getTime() / 1000;

		const to = new Date(reqObject.to * 1000);
		to.setUTCHours(23);
		to.setUTCMinutes(59);

		reqObject.to = to.getTime() / 1000;

		// uncomment: req object with shift length in seconds
		reqObject.length = shiftLength / 1000;

		reqObject.workflows = workflows;
		reqObject.numberOfShifts = numberOfShifts;

		reqObject.serial = selectedVehicles;

		return this.http
			.post(environment.server + '/v2/Stats/workflowStats', reqObject)
			.pipe(
				map((data: any) => {
					if (data.data) {
						const arr = new Array<WorkflowData>();
						for (const serial in data.data) {
							if (
								data.data[serial].workflows !== undefined &&
								data.data[serial].workflows !== null
							) {
								for (const workflow of data.data[serial].workflows) {
									arr.push(WorkflowData.generateFromJson(workflow));
								}
							}
						}
						return arr.sort((a, b) => this.getUTCDate(a.start) - this.getUTCDate(b.start));
					}
					return data;
				})
			)
			.subscribe(observer);
	}

	/**
	 * return the date (YYYY.MM.DD) in utc
	 * @param ms time in ms
	 */
	getUTCDate(ms: number) {
		const date = new Date(ms);
		return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
	}

	/**
	 * fetches the workflownames from the server and returns the subscriptions for manual unsubscribing
	 * @param observer gets notified
	 */
	getWorkflowNames(observer: (data: Array<WorkflowName>) => void): Subscription {
		const reqObj = this.appsettings.generateReqObject(true);
		if (reqObj.serial.length !== 0) {
			return this.http
				.post(environment.server + '/v2/Stats/workflowNames', reqObj)
				.pipe(
					map((data: any) => {
						if (data.data) {
							return (data.data as Array<any>).map((item) => WorkflowName.generateFromJson(item));
						}
						return data;
					})
				)
				.subscribe(observer);
		} else {
			return of([] as any).subscribe(observer);
		}
	}
}
