export enum PerformancePercentageName {
	busyPct = 'busy',
	chargePct = 'charge',
	advLockwaitPct = 'wait',
	issueTPct = 'issue_t',
	issuePPct = 'issue_p',
	advProtectivePct = 'adv_protective',
	advObstructionPct = 'adv_obstruction',
	manualPct = 'manual',
	idlePct = 'idle',
	offlinePct = 'offline',
}
