/**
 * represents an order from the server
 */
export class Order {
	/**
	 * constructs the order
	 * @param start start
	 * @param end end
	 * @param pickups pickups
	 * @param skippedPickups skippedPickups
	 * @param drive drive
	 * @param drop drop
	 * @param charge charge
	 * @param park park
	 */
	constructor(
		public start: number, // timestamp_start
		public end: number, // timestamp_end
		public pickups: number, // pickup
		public skippedPickups: number, // skipped_pickup
		public drive: number,
		public drop: number,
		public charge: number,
		public park: number
	) {}

	/**
	 * transforms an entity from the server to an order
	 * @param data entity from the request
	 */
	static generateFromJson(data: any): Order {
		return new Order(
			data.timestamp_start * 1000,
			data.timestamp_end * 1000,
			data.pickup,
			data.skipped_pickup,
			data.drive,
			data.drop,
			data.charge,
			data.park
		);
	}
}
