<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
></agilox-analytics-widget-header>
<agilox-analytics-table-chart
	(headerDisabled)="headerDisabledChanged($event)"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[config]="config"
	[fetchFunction]="getFetchFunction()"
	[parseFunction]="parseFailures"
	[hideTableHead]="true"
	[interactive]="true"
	(clickedItem)="clickedOnFailure($event)"
	[attr.data-cy]="'widget-failures-process'"
	directiveGoogleAnalytics
	eventAction="click: table-widget-click"
	eventLabel="widget-failures-process"
></agilox-analytics-table-chart>
