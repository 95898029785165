import { Role } from '@agilox/common';
import { MenuPointUrl } from '../../enums';
import { analyticsMobileMenuPoints } from './analytics-menupoints';
import { MenuPoint } from '@agilox/ui-common';

/** mobile menu points*/
export const mobileMenuPoints: MenuPoint[] = [
	{
		name: 'general.menu.dashboard',
		url: MenuPointUrl.dashboard,
		icon: 'dashboard',
		roles: [],
	},
	{
		name: 'general.menu.menu',
		url: '',
		icon: 'menu_hamburger',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
		submenus: [
			analyticsMobileMenuPoints.productivity,
			analyticsMobileMenuPoints.processes,
			analyticsMobileMenuPoints.failures,
			analyticsMobileMenuPoints.battery,
			analyticsMobileMenuPoints.help,
			analyticsMobileMenuPoints.logout,
		],
	},
];
