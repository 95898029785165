/**
 * Calculate the remaining days of a subscription
 */
export function getSubscriptionRemaining(subscriptionEndDate: any) {
	if (!subscriptionEndDate) {
		return 0;
	}

	if (subscriptionEndDate > 0) {
		const today = new Date();
		const subscriptionEnd = new Date(subscriptionEndDate * 1000);
		const timeDifference = subscriptionEnd.getTime() - today.getTime();
		const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
		return Math.max(remainingDays, 0);
	} else {
		return 0;
	}
}
