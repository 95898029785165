import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

@Component({
	selector: 'agilox-analytics-widget-lift-height-absolute',
	templateUrl: './widget-lift-height-absolute.component.html',
	styleUrls: ['./widget-lift-height-absolute.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetLiftHeightAbsoluteComponent extends BaseWidgetDirective {
	/**
	 * Parses the meter value to kilometer
	 * @param value
	 */
	public parseValue(value: number): number {
		return value / 100;
	}
}
