import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChange,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Granularity } from 'apps/analytics/src/app/general/shared/services/appsettings/granularity.enum';
import { PerformanceColor } from '@agilox/common';
import { ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Mode } from '../../../shared/services/appsettings/mode.enum';
import { ChartOptionGenerator } from './chart-configs';
import { EntryConfig } from './entry-config';
import { Timestamp } from './timestamp';
import { WidgetColors } from '../../../enums/widget-colors.enum';

/**
 * represents the standard timeline-chart
 * TODO: fix tooltip-text (from-to)
 */
@Component({
	selector: 'agilox-analytics-timeline-chart',
	templateUrl: './timeline-chart.component.html',
	styleUrls: ['./timeline-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineChartComponent implements OnInit, OnChanges {
	@Input() data: Array<Timestamp>;

	@Input() entryConfigs: Array<EntryConfig>;

	@Input() unit: string;

	private _options: ChartOptions;
	@Input() set options(value: ChartOptions) {
		this._options = value;
		this.legend = this._options.plugins.legend?.display ? true : false;
	}
	get options(): ChartOptions {
		return this._options;
	}

	@Input() resetLineChartOptions = false;

	@Input() resetBarChartOptions = false;

	@Input() fill = false;

	@Input() disableChartSwitch = false;

	@Input() barChart = false;

	@Input() stackedBarChart = true;

	@Input() noLowerBound: boolean;

	/**
	 * if false sets the min-value for the y-axis to zero, otherwise it dependes on the data
	 */
	@Input() integerYAxis = false;

	/**
	 * the canvas for the chart
	 */
	@ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	spinnerEntries: Array<Timestamp>;

	private _datasets: Array<ChartDataset>;
	/**
	 * applies the datasets inside a timeout
	 */
	set datasets(value: Array<ChartDataset>) {
		const timeoutId = setTimeout(() => {
			this._datasets = value;
			if (this.unit !== '%' && this.options && this._datasets) {
				// Is set so that the y axis is reset
				if (this.barChart && this.resetBarChartOptions) {
					this.options = ChartOptionGenerator.getDefaultBarchartOptions(
						this,
						this.noLowerBound,
						this.integerYAxis,
						this.legend
					);
				} else if (!this.barChart && this.resetLineChartOptions) {
					this.options = ChartOptionGenerator.getDefaultLinechartOptions(
						this,
						this.noLowerBound,
						this.integerYAxis,
						this.legend
					);
				}
				this.setYAxis();
			}
			this.resetTooltipPosition();
			if (this.data?.length) {
				this.createLabels();
			}
			clearTimeout(timeoutId);
		}, 0);
	}
	get datasets(): Array<ChartDataset> {
		return this._datasets;
	}

	private _labels: Array<any>;
	/**
	 * sets the labels inside a timeout
	 */
	set labels(value: Array<any>) {
		const timeoutId = setTimeout(() => {
			this._labels = value;
			clearTimeout(timeoutId);
		}, 0);
	}
	get labels(): Array<any> {
		return this._labels;
	}

	private _colorConfigs: Array<any>;
	/**
	 * sets the colorConfigs inside a timeout
	 */
	set colorConfigs(value: Array<any>) {
		const timeoutId = setTimeout(() => {
			this._colorConfigs = value;
			clearTimeout(timeoutId);
		}, 0);
	}
	get colorConfigs(): Array<any> {
		return this._colorConfigs;
	}

	private _defaultColorConfig = {
		borderColor: WidgetColors[0],
	};

	legend: boolean;
	type = 'line';

	constructor(
		private appsettings: AppsettingsService,
		private translate: TranslateService,
		private changeDetection: ChangeDetectorRef,
		private datePipe: DatePipe
	) {}

	/**
	 * initialising all values
	 */
	ngOnInit() {
		this.spinnerEntries = this.data;
		this.data = new Array<Timestamp>();
		this.entryConfigs = new Array<EntryConfig>();
		this._datasets = new Array<ChartDataset>();
		this._datasets.push({
			label: 'loading',
			data: [0],
			borderWidth: 1,
		});
		this._labels = new Array<any>();
		this._colorConfigs = new Array<any>();
		if (!this.options) {
			if (this.barChart) {
				this.options = ChartOptionGenerator.getDefaultBarchartOptions(
					this,
					this.noLowerBound,
					this.integerYAxis,
					this.legend
				);
			} else {
				this.options = ChartOptionGenerator.getDefaultLinechartOptions(
					this,
					this.noLowerBound,
					this.integerYAxis,
					this.legend
				);
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		let transformDataAfterwards = false;
		let updateChartAfterwards = false;

		if (changes['disableChartSwitch']) {
			this.disableChartSwitch = (changes['disableChartSwitch'] as SimpleChange).currentValue;
		}

		for (const changedProperty of Object.keys(changes)) {
			this[changedProperty] = (changes[changedProperty] as SimpleChange).currentValue;
			if (
				changedProperty === 'data' ||
				changedProperty === 'entryConfigs' ||
				changedProperty === 'fill'
			) {
				if (this.data?.length > 0) {
					transformDataAfterwards = true;
				}
			} else if (changedProperty === 'barChart' && !this.disableChartSwitch) {
				if (this.barChart) {
					this.type = 'bar';
					if (!this.options || this.resetBarChartOptions) {
						this.options = ChartOptionGenerator.getDefaultBarchartOptions(
							this,
							this.noLowerBound,
							this.integerYAxis,
							this.legend
						);
					}

					/** bars stacked correctly after switch from line to bar chart */
					this.datasets?.map((dataset) => {
						dataset.stack = this.stackedBarChart ? 'a' : undefined;
						dataset.borderWidth = 0;
						return dataset;
					});

					this.options.scales['y']['stacked'] = this.stackedBarChart;
					this.setYAxis();
				} else {
					this.type = 'line';
					if (!this.options || this.resetLineChartOptions) {
						this.options = ChartOptionGenerator.getDefaultLinechartOptions(
							this,
							this.noLowerBound,
							this.integerYAxis,
							this.legend
						);
					}

					this.datasets?.map((dataset) => {
						dataset.stack = undefined;
						dataset.borderWidth = 2;
						return dataset;
					});

					this.options.scales['y']['stacked'] = false;
					this.setYAxis();
				}
				updateChartAfterwards = true;
			}
		}

		if (transformDataAfterwards) {
			this.transformData();
		}

		if (transformDataAfterwards || updateChartAfterwards) {
			this.updateChart();
		}
	}

	/**
	 * builds the datasets, labels and colorconfig
	 */
	private transformData() {
		const datasets = new Array<ChartDataset>();
		const colorConfig = new Array<any>();

		// we check the entry configs, if no given then i assume there is only one entry per timestamp
		if (this.entryConfigs && this.entryConfigs.length > 0) {
			let idx = 0;

			// build the dataset and colorconfig for every entry
			for (const entryConfig of this.entryConfigs) {
				const label = this.translate.instant(entryConfig.name ?? ' ');
				const dataset: ChartDataset = {
					label: label,
					data: [],
					fill: this.fill,
					borderWidth: 2,
					borderCapStyle: 'butt',
					order: idx,
				};

				if (this.barChart) {
					dataset.stack = this.stackedBarChart ? 'a' : undefined;
					dataset.borderWidth = 0;
				}

				if (this.fill) {
					entryConfig.colorConfig.borderColor = PerformanceColor[entryConfig.name.toLowerCase()];
				}
				dataset.backgroundColor = entryConfig.colorConfig.borderColor;
				dataset.borderColor = entryConfig.colorConfig.borderColor;

				// add the values of the entries
				for (const timestamp of this.data) {
					dataset.data.push(timestamp.get(entryConfig.name));
				}

				datasets.push(dataset);
				idx++;
				colorConfig.push(entryConfig.colorConfig);
			}
		} else {
			const dataset: ChartDataset = {
				label: 'data',
				data: [],
				fill: this.fill,
			};

			if (this.barChart) {
				dataset.stack = this.stackedBarChart ? 'a' : undefined;
				dataset.borderWidth = 0;
			}

			// add the values of the entries
			for (const timestamp of this.data) {
				if (timestamp.data[0]?.value) {
					dataset.data.push(timestamp.data[0].value);
				}
			}

			datasets.push(dataset);
			colorConfig.push(this._defaultColorConfig);
		}
		this.datasets = datasets;
		this.colorConfigs = colorConfig;

		this.setYAxis();
		// building the labels
		this.createLabels();
	}

	/**
	 * creates the labels
	 */
	private createLabels() {
		const labels = [];
		let dateFormat = 'shortTime';
		let label = '';
		switch (this.appsettings.dateSelector.granularity) {
			case Granularity.mm:
			case Granularity.hh:
				dateFormat = 'short';
				this.setXTicksRotation(45);
				break;
			case Granularity.dd:
				dateFormat = 'shortDate';
				this.setXTicksRotation(0);
				break;
			case Granularity.ww:
				dateFormat = 'ww/yyyy';
				this.setXTicksRotation(0);
				label = 'KW';
				break;
			case Granularity.MM:
				dateFormat = 'MM.yyyy';
				this.setXTicksRotation(0);
				break;
		}
		for (const timestamp of this.data) {
			const date = this.datePipe.transform(timestamp.unix, dateFormat, 'UTC');
			labels.push(label + date);
		}
		this.labels = labels;
	}

	/**
	 * sets the x ticks (labels) rotation
	 * @param rotation rotation in degree
	 */
	setXTicksRotation(rotation: number) {
		this.options.scales['x'].ticks['minRotation'] = rotation;
		this.options.scales['x'].ticks['maxRotation'] = rotation;
	}

	/**
	 * updates the chart
	 * (setTimeout and markForCheck are both need, otherwise the chart wont be updated)
	 */
	private updateChart() {
		const timeoutId = setTimeout(() => {
			this.chart.update();
			this.changeDetection.detectChanges();
			clearTimeout(timeoutId);
		}, 0);
	}

	/**
	 * sets the y axis and calculates the minimum and maximum of the y axis
	 * using the dataset of the chart
	 */
	private setYAxis() {
		// set default option values if the chart has the unit "%"
		if (this.unit === '%') {
			this.options.scales['y'].ticks['stepSize'] = 10;
			this.options.scales['y'].ticks.maxTicksLimit = 12;
			this.options.scales['y'].max = 100;
			this.options.scales['y'].min = 0;
		}

		let max = this.options.scales['y'].max as number;
		let difference =
			(this.options.scales['y'].max as number) - (this.options.scales['y'].min as number);

		if (
			this._datasets &&
			(this.appsettings.webAppSettings.dateSelector.mode === Mode.comb || this.noLowerBound)
		) {
			// if the chart receives multiple datasets in a bar chart, (for example: in the combined mode)
			// then these values should be summed correctly so that the y-axis is set correctly
			const sum = this._datasets?.length > 1 && this.barChart;
			let array = [];

			if (sum) {
				// on the one hand all positive values are added
				// and on the other hand all negative values,
				// because there are also charts that have a y-axis that can be negative
				array.push(...this.sumDatasetValues(this._datasets, true));
				array.push(...this.sumDatasetValues(this._datasets, false));
			} else {
				array = this._datasets.concat.apply(
					[],
					this._datasets.map((element) => element.data as Array<number>)
				);
			}

			const mathMax = Math.max(...array);
			const mathMin = Math.min(...array);
			const log10Diff = mathMax !== mathMin ? Math.floor(Math.abs(mathMax - mathMin)) : 0;
			difference = mathMax - mathMin;

			const min = this.getRoundedValue('floor', mathMin, log10Diff);
			max = this.getRoundedValue('ceil', mathMax, log10Diff);

			if (min !== max) {
				// there is one difference when setting the maximum:
				// if i have a chart with the unit % and the values are not to be added up, then the maximum is still 100
				this.options.scales['y'].max = this.unit === '%' && !sum ? 100 : max;
			}
			this.options.scales['y'].min = this.noLowerBound ? min : 0;
			difference = (max as number) - min;
		}

		// only have to set a stepsize between 1 and 100 as it is automatically set correctly with a larger range
		if (max > 1 && max < 1000 && difference > 1) {
			this.options.scales['y'].ticks['stepSize'] = difference > 10 && difference < 1000 ? 10 : 1;
		}
	}

	/**
	 * @param func Math func
	 * @param value value to round
	 * @param log10Diff difference between the min/max values
	 */
	getRoundedValue(func: string, value: number, log10Diff: number) {
		// Calculates the e^n of the given value, so we can round into the same dimension again
		// if the value is 0, we return 0 again
		// else we take abs-value, with the floor of the log, we get lowest power of 10
		// 150 => -e^2
		// 0.15 => e^0
		// e.g. this functions scales every number to its unit-position by multiplying with e^n and then divides by e^n
		// the log10Diff is used to get correct min/max values when the values are arbritralily close to each other
		const roundingHelper =
			Math.pow(10, -Math.floor(Math.log10(Math.abs(value !== 0 ? value : 1)))) - log10Diff;
		return roundingHelper <= 0
			? Math[func](value)
			: Math[func](value * roundingHelper) / roundingHelper;
	}

	/**
	 * sums up the dataset values
	 * @param datasets datasets
	 * @param positiveValues if filter for positive values
	 */
	private sumDatasetValues(datasets: Array<ChartDataset>, positiveValues: boolean) {
		let result = [];
		for (let i = 0; i < datasets[0].data.length; i++) {
			const element = datasets
				.map((dataset) => dataset.data[i])
				.filter((dataset: number) => (positiveValues ? dataset > 0 : dataset < 0))
				.reduce(
					(previous: number, current: number) =>
						positiveValues ? previous + current : previous - current,
					0
				);
			result.push(element);
		}
		// if they are negative numbers, they are not pushed correctly as negative numbers
		if (!positiveValues) {
			result = result.map((elem) => elem * -1);
		}
		return result;
	}

	/**
	 * resets the tooltip position if only one entry in the dataset
	 */
	private resetTooltipPosition() {
		if (this._datasets?.length === 1) {
			this.chart.options.plugins.tooltip.position = 'average';
			this.chart.options.plugins.tooltip.yAlign = 'center';
			this.chart.update();
		}
	}
}
