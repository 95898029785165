<div class="h-10 w-10" data-cy="ui-download-icon">
	@if (status === DownloadStatus.PENDING) {
		<div [uiTooltip]="'download.pending' | translate" class="h-full w-full">
			<ui-spinner [inline]="true" [small]="true"></ui-spinner>
		</div>
	} @else if (status === DownloadStatus.SUCCESS) {
		<ui-button icon="x_check" type="link" [uiTooltip]="'download.success' | translate"></ui-button>
	} @else if (status === DownloadStatus.ERROR) {
		<ui-button icon="failures" type="link" [uiTooltip]="'download.error' | translate"></ui-button>
	} @else {
		<ui-button
			icon="download"
			type="link"
			[uiTooltip]="iconTooltip ? (iconTooltip | translate) : ''"
			(buttonClicked)="iconClicked.emit($event)"
		></ui-button>
	}
</div>
