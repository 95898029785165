/**
 * Represents a battery usage entity from the batterySummary request
 */
export class BatteryUsage {
	/**
	 * constructs the battery usage
	 * @param serial serial
	 * @param usage total battery usage
	 */
	constructor(
		public serial: string,
		public usage: string
	) {}

	/**
	 * generates a BatteryUsage object from the data
	 * @param serial serial of the machine
	 * @param data entity
	 */
	static generateFromJson(serial: string, data: any): BatteryUsage {
		return new BatteryUsage(serial, data.usage ? data.usage.toFixed(2) : undefined);
	}
}
