import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerformanceColor } from '@agilox/common';
import { PerformancePercentageName } from '../../../enums/performance-percentage-name.enum';
import { SelectOption } from '@agilox/ui-common';

@Component({
	selector: 'agilox-analytics-select-bar-chart-values',
	templateUrl: './select-bar-chart-values.component.html',
	styleUrls: ['./select-bar-chart-values.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBarChartValuesComponent implements OnInit {
	/**
	 * returns the names of the selected values
	 */
	@Output() selectedValues: EventEmitter<Array<string>>;

	/**
	 * pass options which are already selected
	 */
	selected: Array<string>;

	allValues: Array<string> = [];

	valuesSelectOptions: Array<SelectOption<string>> = [];

	colors: Array<{ label: string; color: string }> = [];

	constructor(private translate: TranslateService) {
		this.selectedValues = new EventEmitter<Array<string>>();
		this.selected = new Array<string>();
	}

	/**
	 * checks if some values should already be selected
	 */
	ngOnInit() {
		Object.values(PerformancePercentageName).forEach((name) => {
			const label = this.translate.instant('performance.' + name);
			this.valuesSelectOptions.push({ title: label, value: label });
			this.colors.push({ label: label, color: PerformanceColor[name] });
		});
		this.allValues = this.valuesSelectOptions.map((elem) => elem.value);
		this.selected = this.valuesSelectOptions.map((elem) => elem.value);

		this.valuesSelectOptions.reverse();
	}

	getColor(label: string): string {
		return this.colors.find((elem) => elem.label === label)?.color || '';
	}

	/**
	 * gets invoked if you select a value to not to display
	 * @param value selected value
	 */
	toggleValue(value: any) {
		// find all values which are not selected by comparing the selected and the allValues
		const notSelected = this.allValues.filter((val) => !this.selected.includes(val));
		this.selectedValues.emit(notSelected);
	}
}
