import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays the park-actions at a certain timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-charge-count',
	templateUrl: './widget-charge-count.component.html',
	styleUrls: ['./widget-charge-count.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetChargeComponent extends BaseWidgetDirective {}
