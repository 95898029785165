export class FailuresEntry {
	/**
	 * @param start start
	 * @param end end
	 * @param errors error
	 * @param problems problem
	 * @param warnings warning
	 * @param sum sum
	 * @param failures failures
	 */
	constructor(
		public start: number,
		public end: number,
		public errors: number,
		public problems: number,
		public warnings: number,
		public sum: number,
		public failures: Array<FailureCount>
	) {}

	/**
	 * generates the failures entry object from json
	 * @param data data
	 * @param failures failure indices array
	 */
	static generateFromJson(data: any, failures?: Array<string>): FailuresEntry {
		let failureEntries = [];
		if (failures) {
			failureEntries = failures.map((failureKey) => {
				return { idx: failureKey, count: data[failureKey] };
			});
		}
		return new FailuresEntry(
			data.timestamp_start * 1000,
			data.timestamp_end * 1000,
			data.error,
			data.problem,
			data.warning,
			data.sum,
			failureEntries
		);
	}
}
interface FailureCount {
	idx: string;
	count: number;
}
