<div
	class="fixed inset-0 bg-secondary-800 flex items-center justify-center z-overlay pr-4 pl-4"
	[ngClass]="background ? 'bg-opacity-50' : 'bg-opacity-0 '"
	data-cy="ui-overlay"
	(click)="onBackdropClick()"
>
	<div
		class="w-[33rem] max-h-[80vh] rounded-xl shadow-around flex flex-col bg-white text-secondary p-16 {{
			styleClass
		}}"
		[ngClass]="dark ? 'bg-secondary text-white' : 'bg-white text-secondary'"
		(click)="onInnerClick($event)"
		@popupAnimation
	>
		@if (showCloseButton) {
			<div class="flex justify-end bg-transparent -mt-8 -mr-8">
				<ui-icon
					icon="x_cross"
					size="m"
					(click)="onClose()"
					[action]="true"
					data-cy="c-overlay-close-button"
				></ui-icon>
			</div>
		}
		<div class="flex-1 overflow-hidden flex flex-col" [ngClass]="showCloseButton ? 'mt-3' : ''">
			@if (header) {
				<ui-headline type="h2">
					{{ header }}
				</ui-headline>
			}
			<div class="flex-1 overflow-y-auto">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
