import { Pipe, PipeTransform } from '@angular/core';

/**
 * transforms a weight from m into tons and kilotons
 */
@Pipe({
	name: 'weight',
})
export class WeightPipe implements PipeTransform {
	/**
	 * transforms a weight from kg into tons and kilotons
	 * @param value kg
	 * @param args no argument should be given
	 */
	transform(value: any, ...args: any[]): any {
		value = Math.abs(value);
		if (value === undefined) {
			return '';
		}

		if (value >= 10 * 100 * 1000) {
			return Math.round(value / (10 * 100 * 1000)) + 'kt';
		} else if (value > 10 * 100) {
			return Math.round(value / (10 * 100)) + 't';
		} else {
			return Math.round(value) + 'kg';
		}
	}
}
