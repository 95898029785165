import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GlobalFilter } from '../enums/global-filter.enum';

@Injectable({
	providedIn: 'root',
})
// TODO: Refactor once the load directive is refactored or the widgets are refactored
export class OpenGlobalFilterService {
	private openDateFilterSubject = new Subject<void>();
	openDateFilter$ = this.openDateFilterSubject.asObservable();

	private openVehicleSelectSubject = new Subject<void>();
	openVehicleSelect$ = this.openVehicleSelectSubject.asObservable();

	openSelector(name: GlobalFilter) {
		if (name === GlobalFilter.VEHICLE) {
			this.openVehicleSelectSubject.next();
		} else if (name === GlobalFilter.DATE) {
			this.openDateFilterSubject.next();
		}
	}
}
