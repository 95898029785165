import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/** Pass an index or range and this directive renders the content n-times. As a context you can access the index of the current element
 * Usage: <p *agiloxAnalyticsRange="let idx of range; let index = index">rendered Nr. {{idx}} and Idx. {{index}}</p>
 */
@Directive({
	selector: '[agiloxAnalyticsRange]',
})
export class RangeDirective {
	@Input() set agiloxAnalyticsRangeOf(val: number) {
		this.viewContainerRef.clear();
		if (val) {
			let start = 0;
			let end = val;
			if (val[0] && val[1] && val[0] <= val[1]) {
				start = val[0];
				end = val[1];
			} else if (val[0] > val[1]) {
				console.error(
					'Error in appNgRange: the first range input needs to be lower than the second one.\nGiven range: ',
					val
				);
			} else if (val[0] < 0 || val[1] < 0) {
				console.error(
					'Error in appNgRange: at least one of the given inputs is a negative number.\nGiven range: ',
					val
				);
			}
			for (let i = start; i < end; i++) {
				this.createView(i, i - start);
			}
		} else {
			console.error(
				'Error in appNgRange: no valid range given. (Needs to be a positive number) \nGiven range: ',
				val
			);
		}
	}

	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainerRef: ViewContainerRef
	) {}

	/** injects the view and creates the context with an additional index */
	private createView(ctx: number, idx: number) {
		this.viewContainerRef.createEmbeddedView(this.templateRef, {
			$implicit: ctx,
			index: idx,
		});
	}
}
