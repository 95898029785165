<div class="table-chart">
	@if (loadingHandler) {
		<div
			*agiloxAnalyticsLoad="
				let data of loadingHandler;
				disableOnSum: disableOnSum;
				disableOnComb: disableOnComb;
				dataAsArray: true
			"
			class="table-chart__tableWrapper"
		>
			<table class="table-chart__table">
				@if (!hideTableHead) {
					<tr class="table-chart__tableRow">
						<th *ngFor="let row of config" class="table-chart__tableHead">
							{{ row[0] | translate }}
						</th>
					</tr>
				}
				@for (element of data; let idx = $index; track element) {
					<tr
						class="table-chart__tableRow"
						[ngClass]="{ 'table-chart__interactiveTableRow': interactive }"
						(click)="clickedOnItem(element, data)"
					>
						@for (row of config; let rowIdx = $index; track row) {
							<td class="table-chart__tableCell">
								<div class="table-chart__rowContainer">
									@if (element.icon && rowIdx === 0) {
										<ui-icon
											[uiTooltip]="element.tooltip | translate"
											class="table-chart__icon"
											[icon]="element.icon"
											size="l"
											[type]="element.type"
										></ui-icon>
									}
									{{ element[row[1]].toString() | translate }}
								</div>
							</td>
						}
					</tr>
				}
			</table>

      <!--
			@if (data.length - 1 > 5) {
				<div
					class="table-chart__more"
					[attr.data-cy]="'table-chart-show-' + (expanded ? 'less' : 'more')"
				>
					<ui-button
						[text]="(expanded ? 'general.show_less' : 'general.show_more') | translate"
						(buttonClicked)="showAll()"
						type="link"
						class="table-chart__moreButton"
					></ui-button>
				</div>
			}
      -->
		</div>
	}
</div>
