import { Vehicle } from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'vehicleSelect',
	standalone: true,
})
export class VehicleSelectPipe implements PipeTransform {
	transform(
		vehicles: Vehicle[] | undefined | null,
		selectedVehicles: Vehicle[]
	): SelectOption<Vehicle>[] {
		if (!vehicles) {
			return [];
		}
		vehicles = vehicles.concat(this.findVehiclesNotInOptions(vehicles, selectedVehicles));
		return vehicles?.map((vehicle: Vehicle) => ({
			value: vehicle,
			title:
				vehicle.name +
				' (' +
				vehicle.serial +
				')' +
				' | ' +
				vehicle.union +
				' | ' +
				vehicle.customer,
			icon: vehicle.active ? '' : 'decommissioned',
		}));
	}

	/**
	 * The options are paginated so it is possible that the selected vehicles are not in the current options.
	 * This method finds the vehicles that are not in the current options and adds them to the options.
	 * @param vehicles
	 * @param selectedVehicles
	 * @private
	 */
	private findVehiclesNotInOptions(vehicles: Vehicle[], selectedVehicles: Vehicle[]): Vehicle[] {
		if (!selectedVehicles?.length) {
			return [];
		}
		return selectedVehicles.filter(
			(selectedVehicle: Vehicle) =>
				!vehicles.find((vehicle: Vehicle) => vehicle.serial === selectedVehicle.serial)
		);
	}
}
