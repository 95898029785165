import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { AuthenticationService } from 'apps/analytics/src/app/general/shared/services/authentication/authentication.service';
import { Observable } from 'rxjs';

/**
 * a basic-security tool, gets invoked on every request on mobile and desktop
 * checks if the user is logged in, if not it redirects the user to the world login-page
 */
export const authGuard: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(AuthenticationService).isLoggedIn;
};
