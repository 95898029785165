import { DateSelector } from './date-selector.interface';
import { Granularity } from './granularity.enum';
import { Mode } from './mode.enum';
import { WidgetConfig } from './widget-config';

export class AppSettings {
	/**
	 * constructs an Appsettings class
	 * @param dateSelector date selector
	 * @param dashboardWidgetConfig dashboard widget config
	 * @param selectedMachines selected machines
	 * @param machineToShow machine to show
	 * @param onboarding onboarding
	 */
	constructor(
		public dateSelector: DateSelector,
		public dashboardWidgetConfig: Array<WidgetConfig>,
		public selectedMachines: Array<string>,
		public machineToShow: string,
		public onboarding: {
			myWatched: boolean;
			analyticsWatched: boolean;
		}
	) {}

	/**
	 * generates appsettings from json
	 * @param data data
	 */
	static generateFromJson(data: any): AppSettings {
		if (this.isValidUserObject(data)) {
			return new AppSettings(
				data.dateSelector,
				data.dashboardWidgetConfig,
				data.selectedMachines,
				data.machineToShow,
				data.onboarding
			);
		} else {
			return new AppSettings(
				this.defaultDateSelector(),
				new Array<WidgetConfig>(),
				new Array<string>(),
				'',
				{ myWatched: false, analyticsWatched: false }
			);
		}
	}

	/**
	 * checks if the passed obj has all the properties of an appsettings-object
	 * @param obj
	 * @returns
	 */
	static isValidUserObject(obj: any): boolean {
		const dummy = new AppSettings(null, [], [], '', null);
		return Object.keys(dummy).every((key) => obj[key] !== undefined && obj[key] !== null);
	}

	/**
	 * returns default dateselector
	 */
	private static defaultDateSelector(): DateSelector {
		return {
			// Date.now() - 1 Day in ms
			startDate: new Date(Date.now() - 86400000).setUTCHours(0, 0, 0),
			endDate: new Date(Date.now()).setUTCHours(23, 59, 59),
			granularity: Granularity.hh,
			mode: Mode.each,
			shiftOn: false,
			shiftStart: 8 * 60 * 60 * 1000, // 08:00
			shiftEnd: 18 * 60 * 60 * 1000, // 18:00
			shiftDays: ['mon', 'tue', 'wed', 'thu', 'fri'],
		};
	}

	/**
	 * appsettings to json
	 */
	toJson() {
		return {
			dateSelector: this.dateSelector,
			dashboardWidgetConfig: this.dashboardWidgetConfig,
			selectedMachines: this.selectedMachines,
			machineToShow: this.machineToShow,
			onboarding: this.onboarding,
		};
	}
}
