<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
></agilox-analytics-widget-header>
<agilox-analytics-table-chart
	(headerDisabled)="headerDisabledChanged($event)"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[config]="config"
	[fetchFunction]="getFetchFunction()"
	[parseFunction]="parseOrders"
	[hideTableHead]="true"
	[interactive]="true"
	(clickedItem)="clickedOnProcess($event)"
	directiveGoogleAnalytics
	eventAction="click: table-widget-click"
	eventLabel="widget-processes-canceled"
>
</agilox-analytics-table-chart>
