/**
 * Represents and entity from the machineSummary request
 */
export class WeightData {
	/**
	 * constructs the WeightData
	 * @param weightMin
	 * @param weightMax
	 * @param weightAvg
	 */
	constructor(
		public weightMin: number,
		public weightMax: number,
		public weightAvg: number
	) {}

	/**
	 * generates a WeightData object from the data
	 * @param data entity
	 */
	static generateFromJson(data: any): WeightData {
		return new WeightData(data.weight_min, data.weight_max, data.weight_avg);
	}
}
