/**
 * all valid granularities
 */
export enum Granularity {
	mm = 'mm',
	hh = 'hh',
	dd = 'dd',
	ww = 'ww',
	MM = 'MM',
}
