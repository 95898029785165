import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProcessesSubMenuUrl } from '../../shared/enums/processes-submenu.enum';
import { FailureOrderWorkflowService } from '../../shared/services/failure-order-workflow/failure-order-workflow.service';
import { Order } from '../../shared/services/failure-order-workflow/order';
import { ParsedOrders } from '../../shared/services/failure-order-workflow/parsed-orders';
import { Col } from '../components-for-widget-construction/table-chart/col.type';
import { HeaderIconsDisabled } from '../components-for-widget-construction/table-chart/header-icons-disabled.type';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays the lifted weight at a timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-processes-canceled',
	templateUrl: './widget-processes-canceled.component.html',
	styleUrls: ['./widget-processes-canceled.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProcessesCanceledComponent extends BaseWidgetDirective {
	config: Array<Col> = [
		['widget.processes_canceled.diagnose', 'cancelReason'],
		['widget.processes_canceled.count', 'count'],
	];

	/**
	 * constructs the component
	 */
	constructor(
		private service: FailureOrderWorkflowService,
		private router: Router,
		private translate: TranslateService
	) {
		super();
	}

	getFetchFunction() {
		return this.service.getOrdersForWidgets.bind(this.service);
	}

	parseOrders(data: Array<Order>): Array<ParsedOrders> {
		// Filter all canceled orders with diagnose
		data = data.filter((order: Order) => order.cancelReason);

		// count canceled orders with same diagnose
		const result = [
			...data
				.reduce((wholeMap, order) => {
					const k = order.cancelReason;
					// contains the map already the description
					if (!wholeMap.has(k)) {
						wholeMap.set(k, { ...order, count: 1 });
					} else {
						wholeMap.get(k).count++;
					}
					return wholeMap;
				}, new Map() /* initial value */)
				.values(),
		];

		return result.sort((a, b) => b.count - a.count).slice(0, 5);
	}

	/**
	 * gets invoked when clicking on process in processes canceled widget
	 * @param process
	 */
	clickedOnProcess(process: Order) {
		this.router.navigateByUrl(ProcessesSubMenuUrl.workflows, {
			state: {
				diagnose: this.translate.instant(process.cancelReason),
			},
		});
	}

	/**
	 * gets invoked when header icons disabled changed
	 * @param event header icon disabled type
	 */
	headerDisabledChanged(event: HeaderIconsDisabled) {
		this.disabledOnLoadNoData = event[0];
		this.disableOnLoad = event[1];
	}
}
