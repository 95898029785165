/**
 * used to represent the values of a timestamp. A timestamp can hold different
 * variables/values and Entry should help in this case
 */
export class Entry {
	/**
	 * constructs the entry
	 * @param name name
	 * @param value value
	 */
	constructor(
		public name: string,
		public value: number
	) {}
}
