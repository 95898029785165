<div class="widget-productivity-summary">
	<agilox-analytics-widget-header
		[widgetName]="widgetName"
		[disabledOnLoadNoData]="disabledOnLoadNoData"
		[disableOnLoad]="disableOnLoad"
		[disableOnComb]="disableOnComb"
		[disableOnSum]="disableOnSum"
	>
	</agilox-analytics-widget-header>
	<div
		*agiloxAnalyticsLoad="
			let elem of loadingHandler;
			let machineNames = machineNames;
			disableOnComb: disableOnComb;
			disableOnSum: disableOnSum
		"
	>
		<agilox-analytics-block-info-chart
			[blocks]="blockInfoChartBoxes"
			[colorScheme]="BlockInfoChartColorSchemeEnum.YELLOW"
			[values]="[elem.weight | weight, elem.pickup + 'x', elem.distance | distance]"
		>
		</agilox-analytics-block-info-chart>
	</div>
</div>
