import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays the lifted weight at a timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-failures-quit',
	templateUrl: './widget-failures-quit.component.html',
	styleUrls: ['./widget-failures-quit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFailuresQuitComponent extends BaseWidgetDirective {}
