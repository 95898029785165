import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Mode } from '../../../../interfaces/mode.interface';

@Component({
	selector: 'agilox-analytics-binary-toggler',
	templateUrl: './binary-toggler.component.html',
	styleUrls: ['./binary-toggler.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BinaryTogglerComponent {
	/**
	 * gets invoked when the mode is switched
	 * false - left
	 * true - right
	 */
	@Output() currentModeChange = new EventEmitter<boolean>();

	@Input() currentMode = false;

	@Input() rightSide: Mode;

	@Input() leftSide: Mode;

	@Input() disableIcon: boolean;

	toggle(side: boolean) {
		if (side !== this.currentMode) {
			this.currentMode = !this.currentMode;
			this.currentModeChange.emit(this.currentMode);
		}
	}
}
