import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, TooltipItem } from 'chart.js';
import { ChartDataService } from '../../shared/services/chart-data/chart-data.service';
import { Machine } from '../../shared/services/chart-data/machine';
import { ReducedMachine } from '../../shared/services/chart-data/reduced-machine';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { MachineModel } from '@agilox/common';
import { WidgetColors } from '../../enums/widget-colors.enum';

/**
 * displays the battery-temperature at a certain timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-battery-temperature',
	templateUrl: './widget-battery-temperature.component.html',
	styleUrls: ['./widget-battery-temperature.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBatteryTemperatureComponent extends BaseWidgetDirective {
	config: Array<EntryConfig>;
	colors = [
		WidgetColors[0],
		WidgetColors[1],
		WidgetColors[2],
		WidgetColors[0],
		WidgetColors[1],
		WidgetColors[7],
		WidgetColors[8],
	];
	unit = '°';

	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	ownOptions: ChartOptions = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				ticks: {
					autoSkip: true,
					maxTicksLimit: 3,
					callback: (data: number) => (data % 1 !== 0 ? data.toFixed(2) : data) + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						return (
							this.translate.instant(
								'widget.battery_temperature.' + this.config[tooltipItem.datasetIndex].name
							) +
							': ' +
							tooltipItem.formattedValue +
							this.unit
						);
					},
					labelColor: (tooltipItem: TooltipItem<any>) => {
						return {
							backgroundColor: this.colors[tooltipItem['datasetIndex']],
							borderColor: this.colors[tooltipItem['datasetIndex']],
						};
					},
				},
			},
		},
	};

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param translate translateService
	 */
	constructor(
		chartService: ChartDataService,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getData,
			this.transformData,
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res =
					this.disableOnLoad || data === undefined || data === null
						? null
						: data.length > 0 &&
							data.filter(
								(elem) =>
									elem.data.filter((item: Timestamp) =>
										item.data.every((entry) => entry.value !== null)
									).length > 0
							).length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.config = new Array<EntryConfig>();
		this.config.push(
			new EntryConfig('tempBatt1', { borderColor: this.colors[0], backgroundColor: this.colors[0] })
		);
		this.config.push(
			new EntryConfig('tempBatt2', { borderColor: this.colors[1], backgroundColor: this.colors[1] })
		);
		this.config.push(
			new EntryConfig('tempBatt3', { borderColor: this.colors[2], backgroundColor: this.colors[2] })
		);
		this.config.push(
			new EntryConfig('tempBattMin', {
				borderColor: this.colors[3],
				backgroundColor: this.colors[3],
			})
		);
		this.config.push(
			new EntryConfig('tempBattMax', {
				borderColor: this.colors[4],
				backgroundColor: this.colors[4],
			})
		);
		this.config.push(
			new EntryConfig('tempBatt_2_min', {
				borderColor: this.colors[6],
				backgroundColor: this.colors[6],
			})
		);
		this.config.push(
			new EntryConfig('tempBatt_2_max', {
				borderColor: this.colors[7],
				backgroundColor: this.colors[7],
			})
		);
	}

	/**
	 * transforms data
	 * @param data data
	 */
	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		if (data[0]?.entries.length) {
			return data.map((machine: Machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry) => {
						const timelineEntries = new Array<Entry>();
						if (machine.model === MachineModel.OCF || machine.model === MachineModel.OCFV2) {
							timelineEntries.push(new Entry('tempBattMin', entry['tempBattMin']));
							timelineEntries.push(new Entry('tempBattMax', entry['tempBattMax']));
						} else if (machine.model === MachineModel.ODMV2 || machine.model === MachineModel.ODM) {
							timelineEntries.push(new Entry('tempBatt1', entry['tempBatt1']));
						} else if (machine.model === MachineModel.OPS) {
							timelineEntries.push(new Entry('tempBattMin', entry['tempBattMin']));
							timelineEntries.push(new Entry('tempBattMax', entry['tempBattMax']));
							timelineEntries.push(new Entry('tempBatt_2_min', entry['tempBatt_2_min']));
							timelineEntries.push(new Entry('tempBatt_2_max', entry['tempBatt_2_max']));
						} else {
							timelineEntries.push(new Entry('tempBatt1', entry['tempBatt1']));
							timelineEntries.push(new Entry('tempBatt2', entry['tempBatt2']));
							timelineEntries.push(new Entry('tempBatt3', entry['tempBatt3']));
						}
						return new Timestamp(entry.start, timelineEntries);
					}),
				};
			});
		} else {
			return new Array<ReducedMachine>();
		}
	}
}
