import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ChartDataService } from '../../shared/services/chart-data/chart-data.service';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { Performance } from '../../shared/services/chart-data/performance';
import { PerformanceColor } from '@agilox/common';
import { InterpolatedLabels } from '../components-for-widget-construction/interpolated-labels';
import { SwitchChart } from '../components-for-widget-construction/switch-chart.interface';
import { SecondsPipe } from 'libs/pipe/seconds/src/lib/pipe-seconds.pipe';
import { PerformancePropertyName } from '../../enums/performance-property-name.enum';

type DoughnutData = Array<number>;

/**
 * displays the raw data of the performance request
 */
@Component({
	selector: 'agilox-analytics-widget-productivity-analysis',
	templateUrl: './widget-productivity-analysis.component.html',
	styleUrls: ['./widget-productivity-analysis.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProductivityAnalysisComponent extends BaseWidgetDirective {
	colors: Array<string> = [];

	labels = new Array<string>();

	loadingHandler: LoadingHandler<Performance, DoughnutData>;

	allData: Array<Performance>;

	/** of timeMode is selected */
	timeMode = false;

	switchChart: SwitchChart = {
		rightChartConfig: { name: 'clock' },
		leftChartConfig: { name: 'percent' },
		currentMode: false,
	};

	constructor(private chartService: ChartDataService) {
		super();
		// need to get the correct order here...
		// here we would just need to get the correct order of the enum
		// what if we iterate based on the PerformancePropertyName enum?
		// and then we get the value from the PerformanceColor enum based on the index

		Object.entries(PerformanceColor).forEach((entry: [string, string]) => {
			this.colors.push(entry[1]);
		});

		this.loadingHandler = new LoadingHandler(
			this.chartService,
			this.chartService.getPerformanceData,
			(data: Array<Performance>) => {
				this.labels = [];
				const res: Array<DoughnutData> = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
				if (data[0]) {
					//data for chart
					res[0] = Object.keys(PerformancePropertyName)
						.slice(0, 10)
						.map((key) => {
							this.labels.push('performance.values.' + key);
							return data[0][key];
						});
					// data for labels
					this.allData = Object.keys(PerformancePropertyName).map((key) => data[0][key]);
				}
				return data[0] ? res : [];
			},
			null,
			(data: Array<DoughnutData>) => {
				this.disableOnLoad = data === null;
				const res =
					this.disableOnLoad || data === undefined ? null : data[0]?.some((elem) => elem > 0);
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	getInterpolate() {
		return this.interpolateLabels.bind(this);
	}

	interpolateLabels(data: Array<number>): InterpolatedLabels {
		const res = Object.keys(PerformancePropertyName).reduce(
			(current: any, key: string, index: number) => {
				if (this.timeMode) {
					const timeValue = new SecondsPipe().transform(this.allData[index + 10], true);
					current[key] = timeValue;
				} else {
					current[key] = data[index] + '%';
				}
				return current;
			},
			{}
		);
		return res;
	}

	/**
	 * gets invoked from the binary-toggler
	 * @param val value of the toggler
	 */
	chartSwitchToggled(val: boolean) {
		this.timeMode = val;
	}
}
