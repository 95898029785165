<div
	class="flex gap-2 h-10 items-center select-none overflow-hidden px-4 hover:bg-primary-200 cursor-pointer"
	(click)="handleClick()"
>
	<ui-checkbox
		[icon]="union | vehicleSelectUnionCheckbox: options : selectedVehicles"
		[checked]="true"
		class="mr-4 -mt-[1.625rem]"
		(click)="handleCheckboxClick($event)"
	></ui-checkbox>
	<span class="select-none font-semibold text-primary-900">{{ union }}</span>
</div>
