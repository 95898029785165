/**
 * represents on entry of the performance request
 */
export class Performance {
	/**
	 * represents on entry of the performance request
	 * @param serial serial
	 * @param busy busy
	 * @param charge charge
	 * @param idle idle
	 * @param issueT issueT
	 * @param issueP issueP
	 * @param advObstruction advObstruction
	 * @param advLockwait advLockwait
	 * @param advProtective advProtective
	 * @param manual manual
	 * @param sum sum
	 * @param offline offline
	 * @param from from
	 * @param to to
	 * @param pickups pickups
	 * @param orders orders
	 * @param pickupsPct pickupsPct
	 * @param ordersPct ordersPct
	 * @param busyPct busyPct
	 * @param chargePct chargePct
	 * @param idlePct idlePct
	 * @param offlinePct offlinePct
	 * @param issueTPct issueTPct
	 */
	constructor(
		public serial: string,
		public busy: number,
		public busy_scd: number,
		public charge: number,
		public charge_scd: number,
		public idle: number,
		public idle_scd: number,
		public issueT: number, // issue_t
		public issueT_scd: number,
		public issueP: number, // issue_p
		public issueP_scd: number,
		public advObstruction: number, // adv_obstruction
		public advObstruction_scd: number,
		public advLockwait: number, // adv_lockwait
		public advLockwait_scd: number,
		public advProtective: number, // adv_protective
		public advProtective_scd: number, // adv_protective
		public manual: number,
		public manual_scd: number,
		public sum: number,
		public offline: number,
		public offline_scd: number,
		public from: number,
		public to: number,
		public pickups: number,
		public orders: number,
		public reserve: number,
		public reserve_scd: number
	) {}

	/**
	 * generates a performance object from the performance request
	 * @param serial serial number of the machine
	 * @param data data from the get performance request
	 */
	static generateFromJson(serial: string, data: any): Performance {
		const reserve =
			data.idle +
			data.issue_t +
			data.issue_p +
			data.adv_lockwait +
			data.adv_protective +
			data.offline +
			data.manual +
			data.adv_obstruction;
		const reserve_scd = data.seconds
			? data.seconds.idle +
				data.seconds.issue_t +
				data.seconds.issue_p +
				data.seconds.adv_lockwait +
				data.seconds.adv_protective +
				data.seconds.offline +
				data.seconds.manual +
				data.seconds.adv_obstruction
			: 0;

		return new Performance(
			serial,
			data.busy,
			data.seconds?.busy,
			data.charge,
			data.seconds?.charge,
			data.idle,
			data.seconds?.idle,
			data.issue_t,
			data.seconds?.issue_t,
			data.issue_p,
			data.seconds?.issue_p,
			data.adv_obstruction,
			data.seconds?.adv_obstruction,
			data.adv_lockwait,
			data.seconds?.adv_lockwait,
			data.adv_protective,
			data.seconds?.adv_protective,
			data.manual,
			data.seconds?.manual,
			data.sum,
			data.offline,
			data.seconds?.offline,
			data.from,
			data.to,
			data.pickups,
			data.orders,
			reserve,
			reserve_scd
		);
	}
}
