import { AGILOX_API_ENDPOINT, HttpParamsBuilder, Meta, Response, Vehicle } from '@agilox/common';
import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

@Injectable()
export class VehiclesSelectService {
	private http: HttpClient = inject(HttpClient);
	@Inject(AGILOX_API_ENDPOINT) private apiEndpoint: string = inject(AGILOX_API_ENDPOINT);

	fetchVehicles(search: string, page: Meta, serials?: string[]): Observable<Response<Vehicle>> {
		let params = HttpParamsBuilder.buildParameters(page, undefined, search);
		/**
		 * If the search string is not empty we remove the prioritize parameter
		 */
		if (serials?.length && !search) {
			params = params.append('prioritize[serial]', serials.join(','));
		}

		return this.http
			.get<Response<Vehicle>>(this.apiEndpoint + '/v2/machineMgmt/vehicles', {
				params,
			})
			.pipe(
				catchError(() => {
					return of({
						data: [],
						meta: {
							number: 0,
							size: 0,
							total: 0,
						},
						status: true,
					});
				})
			);
	}
}
