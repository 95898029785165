import { SelectOption } from '@agilox/ui-common';

export class MachineSelect {
	public name: string;
	public serial: string;

	constructor(name: string, serial: string) {
		this.name = name;
		this.serial = serial;
	}

	getSelectOption(): SelectOption<any> {
		return {
			title: this.name + ' (' + this.serial + ')',
			value: {
				name: this.name,
				serial: this.serial,
			},
		};
	}
}
