import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { Summary } from '../../shared/services/chart-data/summary';
import { WeightData } from '../../shared/services/chart-data/weightData';
import { BlockInfoChart } from '../components-for-widget-construction/block-info-chart/block-info-chart.interface';
import { BlockInfoChartColorSchemeEnum } from '../components-for-widget-construction/block-info-chart/block-info-chart-color-scheme.enum';

/**
 * displays the data of the detailed state in some fancy gauges-diagrams
 */
@Component({
	selector: 'agilox-analytics-widget-weight-analysis',
	templateUrl: './widget-weight-analysis.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWeightAnalysisComponent extends BaseWidgetDirective {
	loadingHandler: LoadingHandler<Summary, Summary>;

	blockInfoChartBoxes: Array<BlockInfoChart> = [
		{
			icon: 'weight_max',
			text: 'widget.weight_analysis.max',
		},
		{
			icon: 'weight_min',
			text: 'widget.weight_analysis.min',
		},
		{
			icon: 'weight_avg',
			text: 'widget.weight_analysis.avg',
		},
	];

	/**
	 * constructs the component
	 * @param machineService machineService
	 */
	constructor(private service: ChartDataService) {
		super();
		this.loadingHandler = new LoadingHandler(
			service,
			service.getWeightData,
			null,
			null,
			this.checkWeightData.bind(this)
		);
	}

	/**
	 * checks if the values are zero or undefined
	 * @param data
	 */
	checkWeightData(data: WeightData[]) {
		const machineDataEntries = ['weightAvg', 'weightMin', 'weightMax'];
		const res =
			!data ||
			(data[0] &&
				machineDataEntries?.some(
					(prop) => data[0][prop] !== 0 && data[0][prop] !== undefined && data[0][prop] !== false
				));
		this.disableOnLoad = data === null;
		this.disabledOnLoadNoData = res && !this.disableOnLoad;
		return res;
	}

	protected readonly BlockInfoChartColorSchemeEnum = BlockInfoChartColorSchemeEnum;
}
