<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[(barChart)]="tableChartVisible"
	[switchChart]="switchChart"
	[chartSwitch]="true"
>
</agilox-analytics-widget-header>
<agilox-analytics-table-chart
	*ngIf="tableChartVisible"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[config]="config"
	(headerDisabled)="headerDisabledChanged($event)"
	[fetchFunction]="getFetchFunction()"
	[parseFunction]="parseOrders"
	[hideTableHead]="true"
	[interactive]="true"
	(clickedItem)="clickedInTableChart($event)"
	[othersFieldExisting]="true"
	directiveGoogleAnalytics
	eventAction="click: table-widget-click"
	eventLabel="widget-processes-drops"
>
</agilox-analytics-table-chart>
<agilox-analytics-doughnut-chart
	*ngIf="!tableChartVisible"
	[interpolateLabels]="interpolateLabels"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[loadingHandler]="loadingHandler"
	legendPosition="right"
	[interactive]="true"
	(clickedItem)="clickedInDoughnutChart($event)"
	filterProperty="station"
	data-cy="doughnut-chart-processes-drops"
	directiveGoogleAnalytics
	eventAction="click: doughnut-chart-click"
	eventLabel="widget-processes-drops"
>
</agilox-analytics-doughnut-chart>
