import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Summary } from 'apps/analytics/src/app/general/shared/services/chart-data/summary';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { BlockInfoChart } from '../components-for-widget-construction/block-info-chart/block-info-chart.interface';
import { BlockInfoChartColorSchemeEnum } from '../components-for-widget-construction/block-info-chart/block-info-chart-color-scheme.enum';

@Component({
	selector: 'agilox-analytics-widget-productivity-summary',
	templateUrl: './widget-productivity-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProductivitySummaryComponent extends BaseWidgetDirective {
	loadingHandler: LoadingHandler<Summary, Summary>;

	blockInfoChartBoxes: Array<BlockInfoChart> = [
		{
			icon: 'weight',
			text: 'widget.productivity_summary.weight',
		},
		{
			icon: 'x_arrows_up',
			text: 'widget.productivity_summary.lifting',
		},
		{
			icon: 'x_arrows_right',
			text: 'widget.productivity_summary.distance',
		},
	];

	constructor(private service: ChartDataService) {
		super();
		this.loadingHandler = new LoadingHandler(
			service,
			service.getProductivitySummaryData,
			null,
			null,
			this.parseProductivitySummaryData.bind(this)
		);
	}

	/* checks if the values are zero
	 * @param data
	 */
	parseProductivitySummaryData(data: Summary[]) {
		const machineDataEntries = ['distance', 'weight', 'pickup'];
		const res = !data || (data[0] && machineDataEntries.some((prop) => data[0][prop] !== 0));
		this.disableOnLoad = data === null;
		this.disabledOnLoadNoData = res && !this.disableOnLoad;
		return res;
	}

	protected readonly BlockInfoChartColorSchemeEnum = BlockInfoChartColorSchemeEnum;
}
