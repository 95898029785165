import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './general/guards/authentication.guard';
import { roleGuard } from './general/guards/role.guard';

const routes: Routes = [
	{
		path: 'dashboard',
		loadChildren: () =>
			import('apps/analytics/src/app/menupoints/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
		canActivateChild: [authGuard],
	},
	{
		path: 'productivity',
		loadChildren: () =>
			import('apps/analytics/src/app/menupoints/productivity/productivity.module').then(
				(m) => m.ProductivityModule
			),
		canActivateChild: [roleGuard, authGuard],
	},
	{
		path: 'processes',
		loadChildren: () =>
			import('apps/analytics/src/app/menupoints/processes/processes.module').then(
				(m) => m.ProcessesModule
			),
		canActivateChild: [roleGuard, authGuard],
	},
	{
		path: 'failures',
		loadChildren: () =>
			import('apps/analytics/src/app/menupoints/failures/failures.module').then(
				(m) => m.FailuresModule
			),
		canActivateChild: [roleGuard, authGuard],
	},
	{
		path: 'battery',
		loadChildren: () =>
			import('apps/analytics/src/app/menupoints/battery/battery.module').then(
				(m) => m.BatteryModule
			),
		canActivateChild: [roleGuard, authGuard],
	},
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

/**
 * routing-module
 */
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
