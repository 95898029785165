import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { EntryConfig } from '../timeline-chart/entry-config';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Timestamp } from '../timeline-chart/timestamp';
import { Entry } from '../timeline-chart/entry';
import { BaseWidgetDirective } from '../widget/base-widget';
import { LoadingHandler } from '../load-directive/loading-handler';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { WidgetColors } from '../../../enums/widget-colors.enum';

/**
 * this component can be used for easy creation of line-charts of a desired endpoint given via inputs
 */
@Component({
	selector: 'agilox-analytics-simple-line-chart',
	templateUrl: './simple-line-chart.component.html',
	styleUrls: ['./simple-line-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleLineChartComponent extends BaseWidgetDirective implements OnInit {
	/** attribute of the order you want to display */
	@Input() attribute: string;
	/** true when export icons should be disabled */
	@Input() override disabledOnLoadNoData: boolean;

	@Input() override disableOnLoad: boolean;
	/** if the widget should be disabled on summation-mode */
	@Input() override disableOnSum = false;
	/** if the widget should be disabled on combined-mode */
	@Input() override disableOnComb = false;
	/** unit which gets display in the ticks and tooltips */
	@Input() unit = '';
	/** if false sets the min-value for the y-axis to zero, otherwise it dependes on the data */
	@Input() noLowerBound = false;
	/** if true then the y-axis only represent integer values */
	@Input() integerYAxis = false;
	/** if true the line-chart gets displayed as bar-chart */
	@Input() barChart = false;
	/** if a switcher between chart-types should be displayed */
	@Input() chartSwitch = false;
	/** name as string of the function in the chartservice */
	@Input() fetchFunction: string;
	@Input() hideHeader: boolean;

	/** if set it sets the default line chart options when data changed */
	@Input() resetLineChartOptions = false;
	/** if set it sets the default bar chart options when data changed */
	@Input() resetBarChartOptions = false;
	/** abstraction of the fetch-procedure, which gets passed to load-directive, gets generated in the OnInit */
	loadingHandler: LoadingHandler<Machine, [Array<Timestamp>, Array<EntryConfig>]>;

	@Input() dataAsArray: boolean = false;

	@Input() customValueParser: (value: number) => number | undefined;

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(private chartService: ChartDataService) {
		super();
	}

	/** generates the loading */
	ngOnInit() {
		this.loadingHandler = new LoadingHandler<Machine, [Array<Timestamp>, Array<EntryConfig>]>(
			this.chartService,
			this.chartService[this.fetchFunction],
			this.prepareData.bind(this),
			null,
			(data: Array<[Array<Timestamp>, Array<EntryConfig>]>) => {
				this.disableOnLoad = data === null;
				const res = data?.[0] === null || data?.[0] === undefined ? null : !!data[0].length;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);
	}

	/**
	 * transforms the fetched data, into something the timeline-chart can understand
	 * @param machines list of all machines
	 * @param machineNames name of all machines
	 * @returns a tuple which holds at [0] all timestamps, with the data of all machines at this point, and at [1] color configs for the chart
	 */
	private prepareData(
		machines: Array<Machine>,
		machineNames: Array<[string, string]>
	): [Array<Timestamp>, Array<EntryConfig>] {
		const timestamps = new Array<Timestamp>();
		const configsPerMachine = new Array<EntryConfig>();
		const reduced = this.chartService.reduceData(machines, [this.attribute]);
		/**
		 * we need to build up an array of timestamps and each timestamp holds the data for each machine at this timestamp
		 */
		reduced.forEach((machine: ReducedMachine, index: number) => {
			const nameOfMachine = machineNames.find((tuple) => tuple[0] === machine.serial)?.[1] ?? ' ';
			// iterate through the data of a machine
			for (const datapoint of machine.data) {
				// check if the current timestamp-array already has an entry at this time, otherwise we need to add a new one
				// the order of the timestamps is irrelevant
				const foundTimestamp = timestamps.find((stamp) => datapoint.unix === stamp.unix);
				const newEntry = new Entry(
					nameOfMachine,
					this.customValueParser
						? this.customValueParser(datapoint.data[0].value)
						: datapoint.data[0].value
				);
				if (foundTimestamp) {
					foundTimestamp.data.push(newEntry);
				} else {
					timestamps.push(new Timestamp(datapoint.unix, [newEntry]));
				}
			}

			if (nameOfMachine.length) {
				if (reduced.length === 1) {
					configsPerMachine.push(
						new EntryConfig(nameOfMachine, {
							borderColor: WidgetColors[0],
							backgroundColor: WidgetColors[0],
						})
					);
				} else {
					configsPerMachine.push(
						new EntryConfig(nameOfMachine, {
							borderColor: WidgetColors[index],
							backgroundColor: WidgetColors[index],
						})
					);
				}
			}
		});
		return [timestamps, configsPerMachine];
	}
}
