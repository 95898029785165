import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Replay } from '@sentry/replay';
import { Integrations } from '@sentry/tracing';
import { environment } from '@analytics/env/environment';
import { AppModule } from './app/app.module';
import 'hammerjs';
import { throwError } from 'rxjs';

if (environment.production) {
	enableProdMode();

	Sentry.init({
		dsn: 'https://365f2b8e42474552956ab461c1018946@o919676.ingest.sentry.io/5864005',
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: ['https://analytics.agilox.net'],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			new Replay({
				networkDetailAllowUrls: ['https://api.agilox.net'],
				networkCaptureBodies: true,
			}),
		],
		ignoreErrors: ['Non-Error exception captured'],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 0.5,
		/**
		 * This causes the envelope size to be too big in some cases
		 * e.g. fleet-overview, replay
		 * Should only be uncommented when needed!
		 * https://develop.sentry.dev/sdk/envelopes/#size-limits
		 * /
		beforeBreadcrumb: (breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) => {
			if (breadcrumb.category === 'xhr') {
				breadcrumb.data = (hint['xhr'] as XMLHttpRequest).response;
				const data = {
					requestBody: hint['xhr'].__sentry_xhr_v3__.body,
					response: hint['xhr'].response,
					responseUrl: hint['xhr'].responseURL,
				};
				return { ...breadcrumb, data };
			}
			return breadcrumb;
		},
		**/
	});
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => throwError(err));
