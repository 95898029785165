import { PipeEmptyModule } from '@agilox/pipe/empty';
import { PipeSecondsModule } from '@agilox/pipe/seconds';
import { PipeTrustedModule } from '@agilox/pipe/trusted';

import { PluralTranslatePipe, SafeUrlPipe, TranslationsLoader } from '@agilox/common';
import { GoogleAnalyticsDirective } from '@agilox/directive/google-analytics';
import { CommonModule } from '@angular/common';
import {
	HttpClient,
	HttpClientModule,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { MatTableExporterModule } from '../material/mat-table-exporter/mat-table-exporter.module';
import { MaterialModule } from '../material/material.module';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { InputButtonComponent } from './components/input-button/input-button.component';
import { AnalyticsSpinnerComponent } from './components/spinner/analytics-spinner.component';
import { SubscriptionExpiredNotifyComponent } from './components/subscription-expired-notify/subscription-expired-notify.component';
import { BinaryTogglerComponent } from './components/togglers/binary-toggler/binary-toggler.component';
import { RangeDirective } from './directives/range/range.directive';
import { DistancePipe } from './pipes/distance.pipe';
import { WeightPipe } from './pipes/weight.pipe';

import {
	BoxComponent,
	BoxContentComponent,
	ButtonComponent,
	ButtonToggleComponent,
	CheckboxComponent,
	DropdownModule,
	EmptyStateComponent,
	ErrorContentComponent,
	HeadlineComponent,
	IconModule,
	InputModule,
	MobileFilterToggleComponent,
	NotifyComponent,
	OverlayComponent,
	PillComponent,
	PrintButtonComponent,
	SelectModule,
	SpinnerComponent,
	TabsComponent,
	TagComponent,
	ToggleComponent,
	ToolbarComponent,
	TooltipModule,
} from '@agilox/ui';
import { SubscriptionExpireSoonNotifyComponent } from './components/subscription-expire-soon-notify/subscription-expire-soon-notify.component';
import { SubscriptionExpireSoonService } from './components/subscription-expire-soon-notify/subscription-expire-soon.service';
import { SubscriptionVehicleDisplayPipe } from './components/subscription-expire-soon-notify/subscription-vehicle-display.pipe';

/**
 * create all common-used components, pipes, services and modules here
 */
@NgModule({
	declarations: [
		DistancePipe,
		WeightPipe,
		BinaryTogglerComponent,
		AnalyticsSpinnerComponent,
		RangeDirective,
		ExportButtonComponent,
		SubscriptionExpiredNotifyComponent,
		SubscriptionExpireSoonNotifyComponent,
		InputButtonComponent,
		SubscriptionVehicleDisplayPipe,
	],
	imports: [
		CommonModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationsLoader,
				deps: [HttpClient],
			},
		}),
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		GridsterModule,
		HeadlineComponent,
		IconModule,
		EmptyStateComponent,
		ButtonComponent,
		ToggleComponent,
		NotifyComponent,
		InputModule,
		TooltipModule,
		PipeEmptyModule,
		PipeSecondsModule,
		PipeTrustedModule,
		CheckboxComponent,
		TabsComponent,
		TagComponent,
		MatTableExporterModule,
		MobileFilterToggleComponent,
		ButtonToggleComponent,
		BoxContentComponent,
		ToolbarComponent,
		PillComponent,
		SelectModule,
		PrintButtonComponent,
		InputModule,
		SafeUrlPipe,
		GoogleAnalyticsDirective,
		SpinnerComponent,
		BoxComponent,
		OverlayComponent,
		ErrorContentComponent,
		DropdownModule,
		PluralTranslatePipe,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GridsterModule,
		TranslateModule,
		DistancePipe,
		WeightPipe,
		PipeEmptyModule,
		PipeSecondsModule,
		PipeTrustedModule,
		BinaryTogglerComponent,
		SpinnerComponent,
		RangeDirective,
		ExportButtonComponent,
		HeadlineComponent,
		IconModule,
		ButtonComponent,
		EmptyStateComponent,
		ToggleComponent,
		NotifyComponent,
		InputModule,
		TabsComponent,
		TooltipModule,
		CheckboxComponent,
		TagComponent,
		MobileFilterToggleComponent,
		ButtonToggleComponent,
		ToolbarComponent,
		SubscriptionExpiredNotifyComponent,
		SubscriptionExpireSoonNotifyComponent,
		BoxContentComponent,
		PrintButtonComponent,
		SafeUrlPipe,
		GoogleAnalyticsDirective,
		BoxComponent,
		AnalyticsSpinnerComponent,
		OverlayComponent,
		ErrorContentComponent,
		InputButtonComponent,
	],
	providers: [provideHttpClient(withInterceptorsFromDi()), SubscriptionExpireSoonService],
})
export class SharedModule {}
