import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18n } from '@agilox/common';

/**
 * For all exports/imports and settings of angular/material
 * Also add icons in the constructor
 */
@NgModule({
	declarations: [],
	exports: [
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatTooltipModule,
		MatIconModule,
		DragDropModule,
		ClipboardModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTabsModule,
	],
	providers: [
		{
			provide: MatPaginatorIntl,
			deps: [TranslateService],
			useFactory: (translateService: TranslateService) =>
				new PaginatorI18n(translateService).getPaginatorIntl(),
		},
	],
})
export class MaterialModule {}
