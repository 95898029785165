import { Pipe, PipeTransform } from '@angular/core';

/**
 * transform mm to other units
 */
@Pipe({
	name: 'distance',
})
export class DistancePipe implements PipeTransform {
	/**
	 * transforms mm's to cm, m and km
	 * @param value mm's
	 * @param args nothing should be given
	 */
	transform(value: any, ...args: any[]): any {
		value = Math.abs(value);
		if (value === undefined) {
			return '';
		}

		if (value >= 10 * 100 * 1000) {
			return Math.round(value / (10 * 100 * 1000)) + 'km';
		} else if (value > 10 * 100) {
			return Math.round(value / (10 * 100)) + 'm';
		} else {
			return Math.round(value / 10) + 'cm';
		}
	}
}
