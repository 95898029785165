import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { BatteryUsage } from '../../shared/services/chart-data/battery-usage';

/**
 * displays the data of the detailed state in some fancy gauges-diagrams
 */
@Component({
	selector: 'agilox-analytics-widget-battery-usage',
	templateUrl: './widget-battery-usage.component.html',
	styleUrls: ['./widget-battery-usage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBatteryUsageComponent extends BaseWidgetDirective {
	loadingHandler: LoadingHandler<BatteryUsage | string, BatteryUsage | string>;

	/**
	 * constructs the component
	 * @param appsettings AppsettingsService
	 * @param chartService ChartDataService
	 */
	constructor(
		public appsettings: AppsettingsService,
		chartService: ChartDataService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getBatteryUsage,
			null,
			null,
			(data: any) => {
				const hasData = data ? data.usage : data;

				this.disableOnLoad = data === null;
				const result = this.disableOnLoad || data === undefined ? null : !!hasData;
				this.disabledOnLoadNoData = !!result;
				return result;
			}
		);
	}
}
