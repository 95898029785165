import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Performance } from 'apps/analytics/src/app/general/shared/services/chart-data/performance';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { PerformancePropertyName } from '../../enums/performance-property-name.enum';
import { BlockInfoChart } from '../components-for-widget-construction/block-info-chart/block-info-chart.interface';
import { BlockInfoChartColorSchemeEnum } from '../components-for-widget-construction/block-info-chart/block-info-chart-color-scheme.enum';

/**
 * displays the data of the detailed state in some fancy gauges-diagrams
 */
@Component({
	selector: 'agilox-analytics-widget-performance-summary',
	templateUrl: './widget-performance-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetPerformanceSummaryComponent extends BaseWidgetDirective {
	loadingHandler: LoadingHandler<Performance, Performance>;

	blockInfoChartBoxes: Array<BlockInfoChart> = [
		{
			icon: 'robot_one',
			text: 'widget.performance_summary.busy',
		},
		{
			icon: 'o_pause',
			text: 'widget.performance_summary.reserve',
		},
		{
			icon: 'battery_75',
			text: 'widget.performance_summary.charge',
		},
	];

	/**
	 * constructs the component
	 * @param machineService machineService
	 */
	constructor(
		public appsettings: AppsettingsService,
		chartService: ChartDataService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getPerformanceData,
			null,
			null,
			(data: Array<Performance>) => {
				// has any machine one of these values and it is bigger than 0
				const keys = Object.keys(PerformancePropertyName);
				const values = Object.values(PerformancePropertyName);

				const hasData = data?.some(
					(elem) =>
						elem?.[keys[values.indexOf(PerformancePropertyName.busy)]] ||
						elem?.[keys[values.indexOf(PerformancePropertyName.advLockwait)]] ||
						elem?.[keys[values.indexOf(PerformancePropertyName.charge)]]
				);
				this.disableOnLoad = data === null;
				const result = this.disableOnLoad || data === undefined ? null : hasData;
				this.disabledOnLoadNoData = !!result;
				return result;
			}
		);
	}

	protected readonly BlockInfoChartColorSchemeEnum = BlockInfoChartColorSchemeEnum;
}
