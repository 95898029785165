/**
 * this class is needed to color the different entries of a timestamp
 */
export class EntryConfig {
	/**
	 * constructs the entryconfig
	 * @param name name of the entry (name of the machine)
	 * @param colorConfig color-config
	 */
	constructor(
		public name: string,
		public colorConfig: any
	) {}
}
