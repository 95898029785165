import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataService } from 'apps/analytics/src/app/general/shared/services/chart-data/chart-data.service';
import { Machine } from 'apps/analytics/src/app/general/shared/services/chart-data/machine';
import { ReducedMachine } from 'apps/analytics/src/app/general/shared/services/chart-data/reduced-machine';
import { TooltipItem } from 'chart.js';
import { LoadingHandler } from '../components-for-widget-construction/load-directive/loading-handler';
import { Entry } from '../components-for-widget-construction/timeline-chart/entry';
import { EntryConfig } from '../components-for-widget-construction/timeline-chart/entry-config';
import { Timestamp } from '../components-for-widget-construction/timeline-chart/timestamp';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';
import { RotationColors } from '../widget-rotation-driving-units/rotation-colors.const';

/**
 * This widget displays the 14 different voltages of a battery
 */
@Component({
	selector: 'agilox-analytics-widget-rotation-absolute',
	templateUrl: './widget-rotation-absolute.component.html',
	styleUrls: ['./widget-rotation-absolute.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetRotationAbsoluteComponent extends BaseWidgetDirective {
	config: Array<EntryConfig>;
	unit = 'k°';
	loadingHandler: LoadingHandler<Machine, ReducedMachine>;

	ownOptions: any = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 25,
			},
		},
		maintainAspectRatio: false,
		hover: {
			mode: null,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 8,
				},
				grid: {
					drawOnChartArea: false,
					color: 'rgba(100,100,100,0.5)',
				},
			},
			y: {
				min: 0,
				max: 0.1,
				suggestedMin: 0,
				suggestedMax: 0.1,
				ticks: {
					autoSkip: true,
					maxTicksLimit: 3,
					callback: (data) => data + this.unit,
				},
				grid: {
					color: 'rgba(100,100,100,0.5)',
					drawOnChartArea: false,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 10,
			},
			line: {
				tension: 0,
			},
		},
		plugins: {
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (tooltipItem: TooltipItem<any>) => {
						const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
						const result = value - Math.floor(value) !== 0 ? value.toFixed(2) : value;
						return (
							this.translate.instant(
								'widget.rotation_absolute.' + this.config[tooltipItem.datasetIndex].name
							) +
							': ' +
							result +
							this.unit
						);
					},
					labelColor: (tooltipItem) => {
						return {
							backgroundColor: RotationColors[tooltipItem.datasetIndex],
						};
					},
				},
			},
		},
	};

	/**
	 * constructs the component
	 * @param chartService chartService
	 * @param appsettings appsettings
	 */
	constructor(
		chartService: ChartDataService,
		private translate: TranslateService
	) {
		super();
		this.loadingHandler = new LoadingHandler(
			chartService,
			chartService.getData,
			this.transformData,
			null,
			(data: Array<ReducedMachine>) => {
				this.disableOnLoad = data === null;
				const res = this.disableOnLoad || data === undefined ? null : data.length > 0;
				this.disabledOnLoadNoData = !!res;
				return res;
			}
		);

		this.config = new Array<EntryConfig>();
		this.config.push(
			new EntryConfig('durFLAbs', {
				borderColor: RotationColors[0],
				backgroundColor: RotationColors[0],
			})
		);
		this.config.push(
			new EntryConfig('durFRAbs', {
				borderColor: RotationColors[1],
				backgroundColor: RotationColors[1],
			})
		);
		this.config.push(
			new EntryConfig('durRLAbs', {
				borderColor: RotationColors[2],
				backgroundColor: RotationColors[2],
			})
		);
		this.config.push(
			new EntryConfig('durRRAbs', {
				borderColor: RotationColors[3],
				backgroundColor: RotationColors[3],
			})
		);
	}

	private transformData(data: Array<Machine>): Array<ReducedMachine> {
		if (data[0]?.entries.length) {
			return data.map((machine) => {
				return {
					serial: machine.serial,
					data: machine.entries.map((entry) => {
						const timelineEntries = new Array<Entry>();
						if (entry['durFLAbs']) {
							timelineEntries.push(new Entry('durFLAbs', entry['durFLAbs']));
						}
						if (entry['durFRAbs']) {
							timelineEntries.push(new Entry('durFRAbs', entry['durFRAbs']));
						}
						if (entry['durRLAbs']) {
							timelineEntries.push(new Entry('durRLAbs', entry['durRLAbs']));
						}
						if (entry['durRRAbs']) {
							timelineEntries.push(new Entry('durRRAbs', entry['durRRAbs']));
						}
						return new Timestamp(entry.start, timelineEntries);
					}),
				};
			});
		} else {
			return new Array<ReducedMachine>();
		}
	}
}
