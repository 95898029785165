<div
	class="loading-handler doughnut-chart"
	*agiloxAnalyticsLoad="
		let elem of loadingHandler;
		disableOnComb: disableOnComb;
		disableOnSum: disableOnSum;
		dataAsArray: true
	"
>
	<canvas
		baseChart
		type="doughnut"
		[datasets]="getData(elem)"
		[labels]="getLabels(elem)"
		[options]="options"
		[legend]="true"
		[ngClass]="{ 'doughnut-chart__content--interactive': interactive }"
	>
	</canvas>
</div>
