import {
	AcademyRoutes,
	AnalyticsRoutes,
	DocsRoutes,
	LifecycleRoutes,
	MyRoutes,
	SelftestRoutes,
	ServiceRoutes,
} from '@agilox/common';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RedirectService {
	/**
	 * redirects to app
	 * @param name app name
	 * @param environment environment
	 * @param newTab open in new tab
	 * @param redirectUrl
	 */
	redirectToApp(name: string, environment: string, newTab = false, redirectUrl = '') {
		let url = this.getRedirectAppUrl(name, environment) + (redirectUrl ? `/#${redirectUrl}` : '');
		const target = newTab ? '_blank' : '_self';
		window.open(url, target);
	}

	/**
	 * returns app redirect url
	 * @param app app name
	 * @param environment environment
	 */
	public getRedirectAppUrl(app: string, environment: string) {
		switch (app) {
			case 'analytics':
				return AnalyticsRoutes[environment as keyof typeof AnalyticsRoutes];
			case 'academy':
				return AcademyRoutes[environment as keyof typeof AcademyRoutes];
			case 'service':
				return ServiceRoutes[environment as keyof typeof ServiceRoutes];
			case 'docs':
				return DocsRoutes[environment as keyof typeof DocsRoutes];
			case 'selftest':
				return SelftestRoutes[environment as keyof typeof SelftestRoutes];
			case 'lifecycle':
				return LifecycleRoutes[environment as keyof typeof LifecycleRoutes];
			case 'my':
			default:
				return MyRoutes[environment as keyof typeof MyRoutes];
		}
		return '';
	}
}
