import { SecondsPipe } from 'libs/pipe/seconds/src/lib/pipe-seconds.pipe';

/**
 * represents an Order
 */
export class Order {
	/**
	 * constructs an Order
	 * @param id id
	 * @param serial serial
	 * @param agiloxName agilox_name
	 * @param name name
	 * @param created created
	 * @param pending pending
	 * @param start start
	 * @param end end
	 * @param pickups pickups
	 * @param drops drops
	 * @param distance distance
	 * @param duration duration
	 * @param blob clob
	 * @param processed processed
	 * @param canceled boolean
	 * @param cancelReason diagnose
	 */
	constructor(
		public id: string,
		public serial: string,
		public agiloxName: string,
		public name: string,
		public created: number,
		public pending: number,
		public start: number,
		public end: number,
		public pickups: number,
		public drops: number,
		public distance: number,
		public duration: number,
		public blob: string,
		public processed: boolean,
		public canceled: boolean,
		public cancelReason: string | null
	) {}

	/**
	 * generates order out of the MachineMgmt/order request
	 * @param data entry of the MachineMgmt/order
	 */
	static generateFromJson(data: any): Order {
		return new Order(
			data.id,
			data.serialNumber,
			data.agiloxName,
			data.workflowName,
			data.created,
			new SecondsPipe().transform(data.pending, true),
			data.start,
			data.end,
			data.pickups,
			data.drops,
			Math.round(data.distance / 10) / 100,
			data.duration,
			JSON.stringify(data, null, '\t'),
			data.processed,
			data.canceled,
			data.diagnose
		);
	}
}
