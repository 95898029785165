/**
 * represents a workflow with its name, id and union
 */
export class WorkflowName {
	/**
	 * Constructor of workflowname
	 * @param name name of the workflow
	 * @param id id of the workflow
	 */
	constructor(
		public name: string,
		public id: string,
		public union: string,
		public count?: number
	) {}

	/**
	 * generates a workflowname out of the request to the server
	 * @param data json of the request to the server
	 */
	static generateFromJson(data: any): WorkflowName {
		return new WorkflowName(data.name ? data.name : '', data.id, data.union);
	}

	searchtext(): string {
		return `${this.name} ${this.union} ${this.id}`.toUpperCase();
	}

	/**
	 * represents the name of the workflow
	 */
	headertext(): string {
		return this.name;
	}

	/**
	 * represents the id and the union
	 */
	subtext(): string[] {
		return [this.id];
	}
}
