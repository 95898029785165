<div class="select-bar-chart-values">
	<ui-select
		[options]="valuesSelectOptions"
		[placeholder]="'widget.productivity_history.hide_value' | translate"
		[fullDropdownWidth]="true"
		[multiple]="true"
		[(ngModel)]="selected"
		(saved)="toggleValue($event)"
	>
		<ng-container *uiSelectCustomOption="let value">
			<div class="select-bar-chart-values__entry">
				<div
					class="select-bar-chart-values__colorIndicator"
					[ngStyle]="{ background: getColor(value.title) }"
				></div>
				{{ value.title }}
			</div>
		</ng-container>
	</ui-select>
</div>
