import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Displays itself as an input field
 * In use by e.g. the single machine view in the widget header
 */
@Component({
	selector: 'agilox-analytics-input-button',
	templateUrl: './input-button.component.html',
	styleUrls: ['./input-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputButtonComponent {
	@Input() label: string = '';
	@Input() value: string = '';
	@Input() disabled: boolean = false;

	@Output() inputClicked: EventEmitter<void> = new EventEmitter<void>();
}
