import { CsvExporterService } from './exporters/csv-exporter.service';
import { Injectable, Injector } from '@angular/core';
import { Options } from './options';
import { ExportType } from './export-type';
import { Exporter } from './exporters/exporter';
import { XlsxExporterService } from './exporters/xlsx-exporter.service';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
@Injectable({
	providedIn: 'root',
})
export class ServiceLocatorService {
	constructor(private injector: Injector) {}

	public getService(exportType: ExportType | 'xlsx' | 'csv' | 'other'): Exporter<Options> {
		switch (exportType) {
			case ExportType.XLSX.valueOf():
				return this.injector.get<XlsxExporterService>(XlsxExporterService);
			case ExportType.CSV.valueOf():
				return this.injector.get<CsvExporterService>(CsvExporterService);
			case ExportType.OTHER.valueOf():
				return null;
			default:
				return this.injector.get<XlsxExporterService>(XlsxExporterService);
		}
	}
}
