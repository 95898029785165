import { Entry } from './entry';

/**
 * represents a timestamp in the chart data
 */
export class Timestamp {
	/**
	 * constructs the timestamp
	 * @param unix unixtimestamp in ms
	 * @param data data with different entries/variables
	 */
	constructor(
		public unix: number,
		public data: Array<Entry>
	) {}

	/**
	 * returns the value of the entry, if not existing undefined
	 * @param name name of the entry
	 */
	get(name: string): number {
		const res = this.data.find((item) => item.name === name);
		return res === undefined ? null : res.value;
	}
}
