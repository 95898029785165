import { ModuleWithProviders, NgModule } from '@angular/core';
import { CdkTableExporterModule } from '../cdk-table-exporter/cdk-table-exporter.module';
import { XLSX_LIGHTWEIGHT } from '../cdk-table-exporter/constants';
import { MaterialModule } from '../material.module';
import { MatTableExporterDirective } from './mat-table-exporter.directive';
import { ModuleConfiguration } from './module-configuration.interface';

@NgModule({
	declarations: [MatTableExporterDirective],
	imports: [MaterialModule, CdkTableExporterModule],
	exports: [MatTableExporterDirective],
})
export class MatTableExporterModule {
	static forRoot(configuration: ModuleConfiguration): ModuleWithProviders<MatTableExporterModule> {
		return {
			ngModule: MatTableExporterModule,
			providers: [
				{
					provide: XLSX_LIGHTWEIGHT,
					useValue: configuration.xlsxLightWeight,
				},
			],
		};
	}
}
