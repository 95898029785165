import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalFilter } from '../../../shared/enums/global-filter.enum';
import { OpenGlobalFilterService } from '../../../shared/services/open-global-filter.service';

@Component({
	selector: 'agilox-analytics-not-for-appliance',
	templateUrl: './not-for-appliance.component.html',
	styleUrls: ['./not-for-appliance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotForApplianceComponent {
	constructor(private openFilter: OpenGlobalFilterService) {}

	openMachineSelector(event: MouseEvent) {
		event.stopPropagation();
		event.stopImmediatePropagation();
		event.preventDefault();
		this.openFilter.openSelector(GlobalFilter.VEHICLE);
	}
}
