import { Vehicle } from '@agilox/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'subscriptionVehicleDisplay',
})
export class SubscriptionVehicleDisplayPipe implements PipeTransform {
	transform(vehicles: Vehicle[]): string {
		return vehicles
			.map((vehicle: Vehicle) => vehicle.name + ' (' + vehicle.serial + ')')
			.join(', ');
	}
}
