import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppsettingsService } from '../../general/shared/services/appsettings/appsettings.service';
import { AppSettings } from '../../general/shared/services/appsettings/app-settings';
import { saveCombinedMode, setCombinedMode } from './mode-filter.actions';

@Injectable()
export class ModeFilterEffect {
	constructor(
		private actions$: Actions,
		private appSettingsService: AppsettingsService
	) {}

	saveCombinedMode$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveCombinedMode),
				mergeMap((action: { value: boolean }) =>
					this.appSettingsService.saveCombinedMode(action.value).pipe(
						map((settings: AppSettings) => {
							return setCombinedMode({ value: settings.dateSelector.combined });
						})
					)
				)
			),
		{ dispatch: true }
	);
}
