import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppsettingsService } from '../../services/appsettings/appsettings.service';
import { Mode } from '../../services/appsettings/mode.enum';
import { Machine } from '../../services/machine/machine';
import { MachineService } from '../../services/machine/machine.service';

@Component({
	selector: 'agilox-analytics-subscription-expired-notify',
	templateUrl: './subscription-expired-notify.component.html',
	styleUrls: ['./subscription-expired-notify.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionExpiredNotifyComponent implements OnDestroy {
	private appsettingsChangedSubscription: Array<Subscription> = new Array<Subscription>();
	private observer = {
		next: () => this.showNotify(),
		completed: () => {},
		error: () => {},
	};

	private machineSubscription: Subscription | undefined;

	notifyVisible: boolean = false;
	subscriptionExpiredMachines: Array<Machine> = [];
	expiredMachinesText: string = '';

	constructor(
		private appsettings: AppsettingsService,
		private machineService: MachineService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.appsettingsChangedSubscription.push(this.appsettings.registerOnModeChanged(this.observer));
		this.appsettingsChangedSubscription.push(
			this.appsettings.registerOnMachineSelected(this.observer)
		);
		this.showNotify();
	}

	ngOnDestroy(): void {
		this.machineSubscription?.unsubscribe();
	}

	showNotify(): void {
		if (this.appsettings.webAppSettings) {
			this.machineSubscription?.unsubscribe();
			this.machineSubscription = this.machineService.getMachineDetailsBySerial(
				(machines: Machine[]) => {
					this.subscriptionExpiredMachines = machines.filter(
						(machine) => machine.subscriptionExpired
					);

					this.expiredMachinesText = this.subscriptionExpiredMachines
						.map((machine) => machine.name + ' (' + machine.serial + ')')
						.join(', ');

					const hasExpiredSubscriptions = machines.some((machine) => machine.subscriptionExpired);
					const isModeSumOrComb = [Mode.sum, Mode.comb].includes(
						this.appsettings.dateSelector.mode
					);
					this.notifyVisible =
						hasExpiredSubscriptions && isModeSumOrComb && !this.appsettings.isInternalUser();
					this.changeDetectorRef.markForCheck();
				},
				this.appsettings.webAppSettings.selectedMachines
			);
		}
	}
}
