import { FailureIconType } from '../../../enums/failure-icon-type.enum';
import { FailureCategoryKey } from './failure-category.enum';

/**
 * represents a failure
 */
export class Failure {
	/**
	 * constructs a failure
	 * @param index index (if it is of type problem: subindex; else: findex + '.' + fsubindex)
	 * @param findex findex
	 * @param fsubindex fsubindex
	 * @param serial serial
	 * @param type type
	 * @param process_failure process_failure
	 * @param description description
	 * @param start start
	 * @param end end
	 * @param duration duration
	 * @param position position (x/y m)
	 * @param startUnix start unix timestamp
	 * @param endUnix end unix timestamp
	 * @param x x coordinate
	 * @param y y coordinate
	 * @param angle angle
	 * @param floor floor
	 * @param quit quit
	 * @param icon icon
	 * @param occurrence
	 */
	constructor(
		public index: string,
		public findex: string,
		public fsubindex: string,
		public serial: string,
		public type: string,
		public process_failure: boolean,
		public description: string,
		public start: number,
		public end: number,
		public duration: string,
		public position: string,
		public startUnix: number,
		public endUnix: number,
		public x: number,
		public y: number,
		public angle: number,
		public floor: number,
		public quit: boolean,
		public icon: string,
		public occurrence: number = 0 /** Used when failures are grouped */
	) {}

	/**
	 * transforms one element of the array to a failure
	 * @param data one element of the array of the failure request
	 */
	static generateFromJson(data: any): Failure {
		const index =
			data.type === FailureCategoryKey.PROBLEM
				? data.fsubindex
				: data.findex + '.' + data.fsubindex;
		return new Failure(
			index,
			data.findex,
			data.fsubindex,
			data.serial,
			data.type,
			data.process_failure,
			data.description ?? '-',
			data.timestamp_start * 1000,
			data.timestamp_end * 1000,
			data.duration,
			(data.x / 1000).toFixed(2) + '/' + (data.y / 1000).toFixed(2) + 'm',
			data.timestamp_start * 1000,
			data.timestamp_end * 1000,
			data.x,
			data.y,
			data.angle / 100,
			data.floor,
			data.type === 'error',
			this.getFailureTypeIcon(data.type)
		);
	}

	/**
	 * to string
	 */
	toString() {
		return this.type + this.description + this.index;
	}

	toFailureCountRequest(): { findex: string; fsubindex: string; type: string } {
		return {
			findex: this.findex,
			fsubindex: this.fsubindex,
			type: this.type,
		};
	}

	/**
	 * return the failure icon name
	 * @param failure failure type
	 */
	static getFailureTypeIcon(failureType: string): string {
		return (
			FailureIconType[
				Object.keys(FailureIconType).find(
					(key) => key === failureType
				) as keyof typeof FailureIconType
			] ?? ''
		);
	}

	static copy(failure: Failure): Failure {
		return new Failure(
			failure.index,
			failure.findex,
			failure.fsubindex,
			failure.serial,
			failure.type,
			failure.process_failure,
			failure.description,
			failure.start,
			failure.end,
			failure.duration,
			failure.position,
			failure.startUnix,
			failure.endUnix,
			failure.x,
			failure.y,
			failure.angle,
			failure.floor,
			failure.quit,
			failure.icon,
			failure.occurrence
		);
	}
}
