export enum ErrorMessages {
	SUCCESS = 'success',
	EMAIL_PASSWORD_FAIL = 'email_password_fail',
	CREDENTIALS_INVALID = 'credentials_invalid',
	USER_INACTIVE = 'user_inactive',
	PARAMETER_MISSING = 'parameter_missing',
	BETA_USER = 'beta_user',
	INTERNAL_ERROR = 'internal_error',
	USER_EXISTS = 'user_exists',
	PARTNER_EXISTS = 'partner_exists',
	PASSWORD_INVALID = 'password_invalid',
	TOO_MUCH_DATA = 'too_much_data',
	CONTAO_USER_NOT_CREATED = 'contao_user_not_created',
	NO_MACHINE_SUBSCRIPTION = 'no_machine_subscription',
	WHITELIST_VIOLATION = 'whitelist_violation',
}
