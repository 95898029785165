import { Directive, ViewContainerRef } from '@angular/core';

/**
 * used for dynamically inject components
 */
@Directive({
	selector: '[agiloxAnalyticsAnchor]',
})
export class AnchorDirective {
	/**
	 * ViewContainerRef is need to inject elements and the anchor position
	 * @param viewContainerRef viewContainerRef
	 */
	constructor(public viewContainerRef: ViewContainerRef) {}
}
