<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
>
	<agilox-analytics-binary-toggler
		[disableIcon]="!disabledOnLoadNoData"
		[rightSide]="switchChart.rightChartConfig"
		[leftSide]="switchChart.leftChartConfig"
		[currentMode]="switchChart.currentMode"
		(currentModeChange)="chartSwitchToggled($event)"
	>
	</agilox-analytics-binary-toggler>
</agilox-analytics-widget-header>

<agilox-analytics-doughnut-chart
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[labels]="labels"
	[colors]="colors"
	[loadingHandler]="loadingHandler"
	[interpolateLabels]="getInterpolate()"
>
</agilox-analytics-doughnut-chart>
