<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[chartSwitch]="true"
	[(barChart)]="barChart"
>
</agilox-analytics-widget-header>

<ng-container *ngIf="loadingHandler">
	<div
		class="timeline-chart"
		*agiloxAnalyticsLoad="
			let elem of loadingHandler;
			disableOnSum: disableOnSum;
			disableOnComb: disableOnComb;
			dataAsArray: true
		"
	>
		<agilox-analytics-timeline-chart
			[unit]="'A'"
			[barChart]="barChart"
			[data]="elem[0]"
			[entryConfigs]="elem[1]"
			[stackedBarChart]="false"
		>
		</agilox-analytics-timeline-chart>
	</div>
</ng-container>
