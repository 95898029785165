const notificationsURL = [
	'/v2/Admin/getAllNotifications',
	'/v2/User/getStatus',
	'/v2/Admin/sendNotification',
	'/v2/Admin/deleteNotification',
	'/v2/User/notificationsRead',
];
/**
 * for beta and live
 */
export const environment = {
	production: true,
	environmentName: 'prod',
	server: 'https://api.agilox.net/api',
	cookieDomain: 'domain=.agilox.net;',
	excludedRoutes: [
		'/v2/User/login',
		'/v2/User/logout',
		'/v2/User/setPwd',
		'/v2/User/saveSettings',
		'/v2/Admin/addMachinePermission',
		'/v2/Admin/deletePermission',
		'/v2/Admin/updateUser',
		'/v2/Admin/addUser',
		'/v2/Admin/updateMachine',
		'/v2/User/getUserInfo',
		'/v2/machineMgmt/vehicles',
		...notificationsURL,
	],
	excludedRoutePatterns: ['assets/images/', 'assets/i18n/', 'connect'],
};
