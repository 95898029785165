import { Injectable, Inject, Optional } from '@angular/core';
import { XLSX_LIGHTWEIGHT } from './constants';
import type * as XLSX from 'xlsx';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
@Injectable({
	providedIn: 'root',
})
export class SheetjsHelperService {
	constructor(@Optional() @Inject(XLSX_LIGHTWEIGHT) private xlsxLightweight: boolean) {}

	public async getXlsx(): Promise<typeof XLSX> {
		return await import('xlsx');
	}
}
