import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlockInfoChart } from './block-info-chart.interface';
import { BlockInfoChartColorSchemeEnum } from './block-info-chart-color-scheme.enum';

@Component({
	selector: 'agilox-analytics-block-info-chart',
	templateUrl: './block-info-chart.component.html',
	styleUrls: ['./block-info-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockInfoChartComponent {
	@Input() blocks: Array<BlockInfoChart> = [];
	@Input() colorScheme: BlockInfoChartColorSchemeEnum = BlockInfoChartColorSchemeEnum.GREEN;
	@Input() values: Array<any> = [];
}
