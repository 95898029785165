import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { AuthenticationService } from 'apps/analytics/src/app/general/shared/services/authentication/authentication.service';
import { Observable } from 'rxjs';
import { MenuPointUrl } from '../shared/enums/menupoint-url.enum';

export const roleGuard: CanActivateChildFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const authenticationService = inject(AuthenticationService);
	const router = inject(Router);
	if (authenticationService.canRoleActivateUrl(state.url)) {
		return true;
	} else if (authenticationService.isLoggedIn) {
		return router.parseUrl(MenuPointUrl.dashboard);
	}
	return false;
};
