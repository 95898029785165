import { createAction, props } from '@ngrx/store';

export const setVehicles = createAction(
	'[Global Vehicle Filter] Set Multiple Select',
	props<any>()
);
export const setSingleVehicle = createAction(
	'[Global Vehicle Filter] Set Single Select',
	props<any>()
);

export const saveMultipleVehicles = createAction(
	'[Global Vehicle Filter] Save Multiple Select',
	props<any>()
);
