// Please note the order, as it is important for the productivity analysis chart
export enum PerformancePropertyName {
	busy = 'busy',
	charge = 'charge',
	idle = 'idle',
	advLockwait = 'wait',
	advProtective = 'protective',
	advObstruction = 'obstruction',
	issueT = 't_issue',
	issueP = 'p_issue',
	manual = 'manual',
	offline = 'offline',
	busy_scd = 'busy_scd',
	charge_scd = 'charge_scd',
	idle_scd = 'idle_scd',
	advLockwait_scd = 'wait_scd',
	advProtective_scd = 'protective_scd',
	advObstruction_scd = 'obstruction_scd',
	issueT_scd = 't_issue_scd',
	issueP_scd = 'p_issue_scd',
	manual_scd = 'manual_scd',
	offline_scd = 'offline_scd',
}
