import {
	AfterViewInit,
	Directive,
	Host,
	OnDestroy,
	Optional,
	Renderer2,
	Self,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { CdkTableExporter } from '../cdk-table-exporter/cdk-table-exporter';
import { ExtractorService } from '../cdk-table-exporter/extractor.service';
import { ServiceLocatorService } from '../cdk-table-exporter/service-locator.service';

/**
 * https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/mat-table-exporter
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[matTableExporter]',
	exportAs: 'matTableExporter',
})
export class MatTableExporterDirective
	extends CdkTableExporter
	implements AfterViewInit, OnDestroy
{
	/** subscription for export started */
	private exportStartedSubscription: Subscription;

	/** subscription for export completed */
	private exportCompletedSubscription: Subscription;

	public _initialPageIndex: number = 0;

	constructor(
		renderer: Renderer2,
		serviceLocator: ServiceLocatorService,
		dataExtractor: ExtractorService,
		@Host() @Self() @Optional() table: MatTable<any>
	) {
		super(renderer, serviceLocator, dataExtractor, table);
	}

	/**
	 * Overriding ngAfterViewInit of TableExporter
	 */
	ngAfterViewInit() {
		this.exportStartedSubscription = this.exportStarted.subscribe({
			next: () => this.enablePaginator(false),
		});

		this.exportCompletedSubscription = this.exportCompleted.subscribe({
			next: () => {
				this.enablePaginator(true);
			},
		});
	}

	ngOnDestroy() {
		this.exportStartedSubscription?.unsubscribe();
		this.exportCompletedSubscription?.unsubscribe();
	}

	/**
	 * returns the number of the pages
	 */
	public getPageCount(): number {
		return this.getPaginator().getNumberOfPages();
	}

	/**
	 * returns the size of the pages
	 */
	public getPageSize(): number {
		return this.getPaginator()?.pageSize ?? 0;
	}

	/**
	 * returns the current page index
	 */
	public getCurrentPageIndex(): number {
		return this.getPaginator()?.pageIndex ?? 0;
	}

	/**
	 * returns the count of the total items
	 */
	public getTotalItemsCount(): number {
		return this.getPaginator()?.length ?? this.getDataSource()?.data?.length ?? 0;
	}

	/**
	 * go to the page at the index
	 */
	public goToPage(index: number): void {
		this.getPaginator().pageIndex = index;
		if (this.getPaginator().pageSize != this.getPageSize()) {
			this.getPaginator()._changePageSize(this.getPageSize());
		}
		this.getPaginator().page.emit();
	}

	/**
	 * returns the observable of the page change
	 */
	public getPageChangeObservable(): Observable<any> {
		return this.getPaginator().page;
	}

	/**
	 * returns the datasource
	 */
	private getDataSource(): MatTableDataSource<any> {
		return this._cdkTable.dataSource as MatTableDataSource<any>;
	}

	/**
	 * returns the paginator
	 */
	private getPaginator(): MatPaginator {
		return this.getDataSource().paginator as MatPaginator;
	}

	/**
	 * enables/disables the paginator
	 */
	private enablePaginator(value: boolean) {
		if (this.getPaginator()) {
			this.getPaginator().disabled = !value;
			this.getPaginator().pageIndex = this._initialPageIndex;
		}
	}
}
