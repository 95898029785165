<agilox-analytics-simple-line-chart
	[widgetName]="widgetName"
	[attribute]="'speed'"
	[fetchFunction]="'getData'"
	[unit]="'m/s'"
	[disableOnSum]="disableOnSum"
	[barChart]="false"
	[chartSwitch]="true"
	[noLowerBound]="true"
></agilox-analytics-simple-line-chart>
