<agilox-analytics-simple-line-chart
	[widgetName]="widgetName"
	[attribute]="'z_abs'"
	[fetchFunction]="'getData'"
	[disableOnSum]="disableOnSum"
	[disableOnComb]="disableOnComb"
	[integerYAxis]="true"
	[barChart]="false"
	[chartSwitch]="false"
	[resetBarChartOptions]="true"
	[dataAsArray]="true"
	[unit]="'m'"
	[noLowerBound]="true"
	[customValueParser]="parseValue"
></agilox-analytics-simple-line-chart>
