import { ExportType } from './export-type';
import { Mime } from './mime';
import { InjectionToken } from '@angular/core';
// Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter

export const MAT_TABLE_EXPORTER = 'mat-table-exporter';
export const TYPE_ARRAY = 'array';
export const CHAR_SET_UTF = ';charset=utf-';
export const CHAR_SET_UTF_8 = CHAR_SET_UTF + '8';
export const CONTENT_TYPE_TEXT = ExportType.TXT + '/';
export const CONTENT_TYPE_APPLICATION = 'application/';
export const CONTENT_TYPE_EXCEL = CONTENT_TYPE_APPLICATION + 'octet-stream';
export const DOT = '.';
export const COMMA = ',';
export const EXTENSION_XLSX = DOT + ExportType.XLSX;
export const EXTENSION_CSV = DOT + ExportType.CSV;
export const MIME_EXCEL_XLSX = new Mime(EXTENSION_XLSX, CONTENT_TYPE_EXCEL + CHAR_SET_UTF_8);
export const MIME_CSV = new Mime(
	EXTENSION_CSV,
	CONTENT_TYPE_TEXT + ExportType.CSV + CHAR_SET_UTF_8
);
export const REF = '!ref';
export const RETURN = '\n';
export const TAB = '\t';
export const XLSX_COLS = '!cols';
export const BOM = '\uFEFF';
export const XLSX_LIGHTWEIGHT = new InjectionToken<boolean>('XLSX_LIGHTWEIGHT');
