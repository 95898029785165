import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
@NgModule({
	declarations: [],
	imports: [CdkTableModule],
	exports: [],
})
export class CdkTableExporterModule {}
