import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { environment } from '@analytics/env/environment';

/**
 * This interceptor optimizes the api-requests
 */
@Injectable()
export class CacheOptimizerInterceptor implements HttpInterceptor {
	cachedData = new Map<number, [number, ReplaySubject<HttpEvent<unknown>>]>();

	constructor(private authenticationService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authenticationService.refreshRequests) {
			this.cachedData.clear();
			this.authenticationService.refreshRequests = false;
		}
		// excluded routes
		if (
			environment.excludedRoutes.findIndex(
				(route) => request.url === environment.server + route
			) !== -1
		) {
			if (request.url === environment.server + '/v2/User/logout') {
				// resetting if the user logged out
				this.cachedData.clear();
			}

			return next.handle(request);
		}

		const orderedBody = {};
		// the body can also be empty
		if (request.body) {
			this.orderJson(request.body, orderedBody);
		}
		// generating the key
		const keyStr = this.generateHash(request.url + JSON.stringify(orderedBody));

		// check if the map has an entry for this url + object and if then also checks if it isn't too old
		if (
			this.cachedData.has(keyStr) &&
			this.cachedData.get(keyStr)[0] + 3 * 60 * 1000 > Date.now()
		) {
			return this.cachedData.get(keyStr)[1];
		}

		const subjectData = new ReplaySubject<HttpEvent<unknown>>(1);
		this.cachedData.set(keyStr, [Date.now(), subjectData]);
		next
			.handle(request)
			.pipe(
				// checks if the user is logged in, otherwise the cache gets deleted
				tap((data: any) => {
					// true status
					if (!data?.body?.status) {
						if (data?.msg === 'authentication failed') {
							// delay it, so the observers get executed first and after that clear the cache
							const timeoutId = setTimeout(() => {
								this.cachedData.clear();
								clearTimeout(timeoutId);
							}, 0);
							// status is set and is false, dont cache
							// this needs to be checked this way, because there are for some reason
							// request results which return an object with {type: 0}, but get resolved later
						} else if (data.body && data.body.status === false) {
							this.cachedData.delete(keyStr);
						}
					}
				}),
				// when the requests throws an error, don't cache
				catchError((error: HttpErrorResponse) => {
					if (error) {
						let errorMsg = '';
						this.cachedData.delete(keyStr);

						if (error.error && error.error instanceof ErrorEvent) {
							errorMsg = `Error: ${error.error.message}`;
						} else if (error.status && error.message) {
							errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
						} else {
							return EMPTY;
						}
						return throwError(() => new Error(errorMsg));
					}
					return EMPTY;
				})
			)
			.subscribe((data: any) => {
				// only return something if the request was successfull
				// when data.body.status is false the request should be handled
				if (!(data.body && data.status === false)) {
					subjectData.next(data);
				}
			});
		return subjectData.asObservable();
	}

	/**
	 * sorts the properties of the source object and checks
	 * if the properties are object or arrays itself.
	 * @param source original object
	 * @param target empty object
	 */
	orderJson(source: any, target: any) {
		Object.keys(source)
			.sort()
			.forEach((key) => {
				if (Array.isArray(source[key])) {
					target[key] = [];
					this.orderArray(source[key], target[key]);
					// you need to check for null extra, because typeof(null) === "object"
				} else if (typeof source[key] === 'object' && source[key] !== null) {
					target[key] = {};
					this.orderJson(source[key], target[key]);
				} else {
					target[key] = source[key];
				}
			});
	}

	/**
	 * orders an array and checks if one of its elements is an
	 * object or array itself. Both input-arrays need have the same
	 * length and same elements.
	 * (basically copy source onto target)
	 * @param source original data
	 * @param target where the ordered data should be saved
	 */
	orderArray(source: Array<any>, target: Array<any>) {
		for (let i = 0; i < source.length; i++) {
			if (Array.isArray(source[i])) {
				target[i] = [];
				this.orderArray(source[i], target[i]);
			} else if (typeof source[i] === 'object') {
				target[i] = {};
				this.orderJson(source[i], target[i]);
			} else {
				target[i] = source[i];
			}
		}
	}

	/**
	 * generates a hash for the input string
	 * @param str
	 * @returns hashnumber
	 */
	generateHash(str: string): number {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			const chr = str.charCodeAt(i);
			hash = hash * 31 + chr;
			// eslint-disable-next-line no-bitwise
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}
}
