/* eslint-disable indent */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutPosition, TooltipItem } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { InterpolatedLabels } from '../interpolated-labels';
import { LoadingHandler } from '../load-directive/loading-handler';
import { WidgetColors } from '../../../enums/widget-colors.enum';

@Component({
	selector: 'agilox-analytics-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrls: ['./doughnut-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoughnutChartComponent implements OnInit {
	/** fetches the data and if no parseFunction given, it gets passed directly into the chart */
	@Input() fetchFunction: <T>(
		observer: (data: Array<T>) => void,
		renderInShowAll?: boolean
	) => Subscription;
	/** if given the output of this function gets directly passed into the chart */
	@Input() parseFunction: <T, K>(data: Array<T>) => Array<K>;

	/** if the widget should be disabled on summation-mode */
	@Input() disableOnSum = false;
	/** if the widget should be disabled on combined-mode */
	@Input() disableOnComb = false;
	@Input() options: any = {
		layout: {
			padding: {
				left: 25,
				right: 25,
				bottom: 15,
				top: 15,
			},
		},
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		borderWidth: 0,
		cutout: 75,
		plugins: {
			legend: {
				position: 'right',
				labels: {
					boxWidth: 13,
					font: {
						size: 14,
					},
				},
			},
			tooltip: {
				callbacks: {
					title: (tooltipItem: TooltipItem<any>) => {
						// the title is generated by chart js directly
						// and to show only the label without the title above we set the title to ""
						return '';
					},
					label: (tooltipItem: TooltipItem<any>) => {
						return tooltipItem.label;
					},
				},
			},
		},
		// is used for onclick in the doughnut chart
		onClick: this.itemSelected.bind(this),
	};

	@Input() labels: Array<string>;
	/**
	 * the labels can be interpolated, if wanted, the provided function need to return the following object:
	 * {
	 * 		key: value
	 * }
	 * */
	@Input() interpolateLabels: <T>(data: Array<T>, labels: Array<string>) => InterpolatedLabels;

	/** colors in the doughnut chart */
	@Input() colors: WidgetColors;

	/** loading handler */
	@Input() loadingHandler: LoadingHandler<unknown, unknown>;

	data: Array<any>;

	@Input() legendPosition: LayoutPosition = 'right';

	/** interactive */
	@Input() interactive: boolean;

	/** emits the clicked item */
	@Output() clickedItem = new EventEmitter<any>();

	/** property to filter */
	@Input() filterProperty: string;

	constructor(
		private translate: TranslateService,
		private deviceDetectorService: DeviceDetectorService
	) {}

	ngOnInit() {
		// if no loading handler given, set loading handler with the given fetch an parse function
		if (!this.loadingHandler) {
			this.loadingHandler = new LoadingHandler(null, this.fetchFunction, this.parseFunction);
		}

		// set the custom position of the legend
		if (this.legendPosition) {
			this.options.plugins.legend.position = this.deviceDetectorService.isMobile()
				? 'bottom'
				: this.legendPosition;
		}
	}

	/**
	 * interpolates the data into the placeholder of the translations
	 * @param data fetched data
	 * @returns interpolated labels for the chart
	 */
	getLabels(data: Array<any>): Array<string> {
		// proof if there is only one element in the array
		data = data.length === 1 ? data[0] : data;

		const interpolateObj = this.interpolateLabels(data, this.labels);
		if (!data?.every((element) => typeof element === 'number')) {
			this.labels = data.map((element) => element[this.filterProperty].toString());
			// set the labels with the correct data
			return this.labels?.map(
				(label) => this.translate.instant(label) + ' (' + interpolateObj[label] + ')'
			);
		} else {
			return this.labels?.map((label) => this.translate.instant(label, interpolateObj));
		}
	}

	/**
	 * sets correct data to display
	 * @param data
	 * @returns data for the chart
	 */
	getData(data: Array<any>): Array<any> {
		// proof if there is only one element in the array
		data = data.length === 1 ? data[0] : data;

		// save data for itemSelected to return data when others clicked
		this.data = data;

		// chart colors
		const color = this.colors
			? Object.entries(this.colors)
					.slice(0, data.length)
					.map((val) => val[1])
			: Object.entries(WidgetColors)
					.slice(0, data.length)
					.map((val) => val[1]);

		// if the data array also contains strings, set the numbers for the in doughnut chart
		if (!data.every((element) => typeof element === 'number')) {
			const doughnutData = data.map((element: any) => element?.count);
			return [
				{
					data: doughnutData,
					backgroundColor: color,
				},
			];
		}

		return [
			{
				data: data,
				backgroundColor: color,
				borderColor: '#ffffff',
			},
		];
	}

	/**
	 * gets invoked when clicking in doughnut chart
	 * @param event mouse event
	 * @param activeElement activeElement is an array of points on the canvas
	 */
	itemSelected(event: MouseEvent, activeElement: any) {
		// check if there is an active element
		if (this.interactive && activeElement?.length) {
			const property = this.data[activeElement[0].index][this.filterProperty];

			// proof if clicked item is a translation key
			if (property && this.translate.instant(property?.toString()) !== property) {
				// emit all elements that aren't others and sets a additional param 'others' to true
				this.clickedItem.emit({
					data: this.data
						.map((element) => element[this.filterProperty])
						.filter((element) => element !== property),
					others: true,
				});
			} else if (property) {
				this.clickedItem.emit({ data: property });
			} else {
				this.clickedItem.emit(this.labels[activeElement[0].index]);
			}
		}
	}
}
