<div class="binary-toggler">
	<ui-icon
		[icon]="currentMode ? leftSide?.name : rightSide?.name"
		type="dark"
		[action]="true"
		size="l"
		[disabled]="disableIcon"
		(iconClicked)="toggle(currentMode ? false : true)"
	>
	</ui-icon>
</div>
