<agilox-analytics-simple-line-chart
	[widgetName]="widgetName"
	[attribute]="'chargeCount'"
	[fetchFunction]="'getData'"
	[disableOnSum]="disableOnSum"
	[disableOnComb]="disableOnComb"
	[integerYAxis]="true"
	[barChart]="true"
	[chartSwitch]="true"
	[resetBarChartOptions]="true"
	[dataAsArray]="true"
></agilox-analytics-simple-line-chart>
