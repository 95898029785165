import {
	AnalyticsRoutes,
	MenuPointService,
	MyRoutes,
	NavVariant,
	parseRedirectUrl,
	Role,
} from '@agilox/common';
import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuPointUrl } from '../../enums';
import { MenuPoint } from '@agilox/ui-common';

/**
 * provides the authentication functionality
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	/** environment */
	private _environment = 'live';

	set environment(windowUrl: string) {
		const env = Object.keys(AnalyticsRoutes).find((key) =>
			windowUrl.includes(AnalyticsRoutes[key])
		);
		if (env) {
			this._environment = env;
		}
	}

	get environment(): string {
		return this._environment;
	}

	/** redirect url */
	private redirectUrl: string;

	/** refresh requests in cache optimizer */
	refreshRequests: boolean = false;

	/**
	 * indicates if the user is logged in
	 */
	private _isLoggedIn = false;
	set isLoggedIn(val: boolean) {
		if (this._isLoggedIn !== val && val === true) {
			// initial status to get notifications
			this.status();
		}

		this._isLoggedIn = val;
		if (this._isLoggedIn) {
			this.navigateUserToUrl(this.redirectUrl);
			this.periodicStatus();
		} else {
			this.abortPeriodicStatus();
			window.location.href =
				MyRoutes[this._environment] +
				'/#/login?app=analytics' +
				(this.redirectUrl ? '&redirect=' + this.redirectUrl : '');
		}
	}

	get isLoggedIn(): boolean {
		return this._isLoggedIn;
	}

	/** periodic status */
	periodicStatusId = -1;

	/** subscription of the */
	private statusSubscription: Subscription;

	/** subscription for the getUser */
	private getUserSubscription: Subscription | undefined;

	constructor(
		private appSettings: AppsettingsService,
		private http: HttpClient,
		private router: Router,
		private menupointService: MenuPointService
	) {
		this.redirectUrl = parseRedirectUrl(window.location.href.split('#')[1]);
		this.environment = window.location.href;

		this._isLoggedIn = !!this.appSettings.user;

		// saving the origin-url for redirecting the user if the user reloads
		this.router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				this.redirectUrl = event.url;
			});
	}

	/**
	 * sets the periodic status
	 */
	periodicStatus() {
		clearInterval(this.periodicStatusId);
		this.periodicStatusId = +setInterval(() => {
			this.status();
		}, 30 * 1000);
	}

	/**
	 * aborts the periodic status
	 */
	abortPeriodicStatus(): void {
		clearInterval(this.periodicStatusId);
		this.periodicStatusId = -1;
	}

	/**
	 * logouts the user when logout is true
	 */
	status() {
		this.statusSubscription?.unsubscribe();
		this.statusSubscription = this.http
			.get(environment.server + '/v2/User/getStatus')
			.subscribe((data: any) => {
				if (data.logout) {
					this.logout();
				}
				this.statusSubscription?.unsubscribe();
			});
	}
	/**
	 * get user data and checks if logged in
	 */
	getUser(): Observable<any> {
		const subject = new Subject();
		this.getUserSubscription?.unsubscribe();
		this.getUserSubscription = this.http
			.get(environment.server + '/v2/User/getUserInfo', {})
			.subscribe((data: any) => {
				this.isLoggedIn = data.status;
				if (data.status) {
					this.appSettings.setFromResponse(data);
				}
				subject.complete();
			});
		return subject.asObservable();
	}

	/**
	 * checks if role can activate url
	 * @param url url
	 */
	canRoleActivateUrl(url: string): boolean {
		const user = this.appSettings.user;
		if (!user) {
			return false;
		}
		const role: Role = user.role;
		const menupoints: MenuPoint[] = this.menupointService.getMenuPointsByCategory(
			NavVariant.DESKTOP,
			role
		);

		return menupoints.some(
			(menuPoint: MenuPoint) =>
				menuPoint.url === url || menuPoint.submenus?.some((submenu) => submenu.url === url)
		);
	}

	/**
	 * navigates the user to the given url
	 * @param url
	 */
	navigateUserToUrl(url: string) {
		const isAllowed: boolean | Observable<boolean> = this.isUserPermitted(url);
		// the user isn't loaded at the moment
		if (isAllowed instanceof Observable) {
			return isAllowed.subscribe((allowed) => {
				this.routeUser(allowed, url);
			});
			// the user is already loaded
		} else {
			return this.routeUser(isAllowed, url);
		}
	}

	/**
	 * routes user to the give url
	 * @param allowed is allowed to access url
	 * @param url url
	 */
	routeUser(allowed: boolean, url: string) {
		this.router.navigateByUrl(allowed ? url : MenuPointUrl.dashboard);
	}

	/**
	 * checks if the user is allowed to access this url
	 * @param wholeUrl
	 */
	isUserPermitted(wholeUrl: string): boolean | Observable<boolean> {
		// if the url has more than 2 parts, we need to cut of the last one
		// (main/support/remote for example, the permissions are just given for main/support)
		let suburl = wholeUrl ? wholeUrl.split('/').slice(0, 2).join('/') : MenuPointUrl.dashboard;

		// remove query params
		suburl = suburl.split('?')[0];

		// fetching menupoints, to check if the user is permitted
		const menupoints = this.menupointService.getMenuPointsByCategory(
			NavVariant.DESKTOP,
			this.appSettings.user?.role
		);
		if (menupoints && this.appSettings.user?.role) {
			return suburl.includes('login') || !!menupoints.find((menu) => menu.url === suburl);
		} else {
			const menupointsObservable = new Subject<boolean>();
			this.appSettings.registerOnInitialized({
				next: () => {
					// because the app is initialized I can be sure that this is a boolean
					menupointsObservable.next(this.isUserPermitted(wholeUrl) as boolean);
				},
				error: () => {},
				complete: () => {},
			});
			return menupointsObservable.asObservable();
		}
	}

	logout() {
		const subscription = this.http.get(environment.server + '/v2/User/logout').subscribe(() => {
			this.redirectUrl = '';
			this.isLoggedIn = false;
			subscription?.unsubscribe();
			window.location.href = MyRoutes[this._environment] + '/#/logout';
		});
	}
}
