<div
	uiDropdown
	(openChange)="onDropdownStateChange($event)"
	data-cy="c-vehicle-union-select"
	[fullWidth]="true"
>
	<div
		uiDropdownTrigger
		[uiDropdownDisabled]="formControl.disabled"
		class="flex gap-0.5 w-full"
		data-cy="c-vehicle-union-select-dropdown-trigger"
	>
		<div
			class="flex rounded bg-white cursor-pointer h-10 w-full border border-secondary-300 py-px px-3 items-center justify-between"
			[ngClass]="{
				'!bg-secondary-100 color-secondary-400 !cursor-not-allowed': formControl.disabled,
			}"
		>
			@if (formControl.value?.length) {
				<div class="flex justify-between w-full items-center gap-2">
					<span class="line-clamp-1">
						{{ formControl.value[0].name }} ({{ formControl.value[0].serial }}) |
						{{ formControl.value[0].union }}
					</span>

					<div class="flex items-center">
						@if (formControl.value.length > 1) {
							<ui-pill
								[value]="'+' + (formControl.value.length - 1).toString()"
								class="mr-1"
								type="secondaryOutline"
							></ui-pill>
						}
					</div>
				</div>
			} @else {
				<span class="text-secondary-400 line-clamp-1">
					{{ 'select.placeholder' | translate }}
				</span>
			}
			<ui-icon
				icon="x_arrow_down"
				class="transition block"
				[ngClass]="dropdownOpen ? 'rotate-180' : ''"
				size="m"
			></ui-icon>
		</div>
	</div>

	@let currentSelectedUnion = currentSelectedUnion$ | async;

	@if (vehicleResponse$ | async | vehicleSelectOption: currentSelectedUnion : userRole; as groups) {
		<div *uiDropdownContent class="flex flex-col">
			<ui-input
				type="search"
				class="w-full h-10"
				[formControl]="searchFormControl"
				[placeholder]="'general.search' | translate"
				styleClass="!border-0 -mt-1"
				#searchInput
			/>
			<div
				class="max-h-64 border-t border-secondary-300"
				[ngClass]="loadingVehicles() ? 'overflow-hidden' : 'overflow-y-auto'"
				#optionsList
				(scroll)="onScroll()"
			>
				@if (groups.length === 0 && !loadingVehicles()) {
					<div class="p-4 pb-2">
						<ui-notify [text]="'general.no_data' | translate"></ui-notify>
					</div>
				}
				@if (!loadingVehicles()) {
					@for (group of groups; track group.uuid) {
						@if (currentSelectedUnion === group.uuid) {
							<ui-union-select-option
								[union]="group.name"
								[options]="group.options"
								[selectedVehicles]="selectedVehicles"
								(deselectAll)="onDeselectAll()"
								(selectAll)="onSelectAllInUnion(group)"
								id="selected-union-header"
								data-cy="c-vehicle-union-select-selected-union-header"
							></ui-union-select-option>
						}
						@for (vehicle of group.options; track vehicle.value) {
							<ui-vehicle-select-option
								[displayPills]="displayPills"
								[option]="vehicle"
								[selected]="isVehicleSelected(vehicle.value)"
								(toggleItem)="onSelect($event)"
							></ui-vehicle-select-option>
						}
					}
				}

				@if (loadingVehicles()) {
					<div class="w-full h-10">
						<ui-spinner [inline]="true" [small]="true"></ui-spinner>
					</div>
				}
			</div>

			@if (selectedVehicles && maxSelections && maxSelections < selectedVehicles.length) {
				<div class="pl-3 pr-3 absolute bottom-10 w-full">
					<ui-notify
						[text]="'select.maxSelectionsText' | translate: { max: maxSelections }"
					></ui-notify>
				</div>
			}

			<div class="flex items-center border-t border-secondary-300 h-10 w-full justify-center">
				@if (displayDeselectAll && selectedVehicles.length > 0) {
					<div
						class="flex items-center text-secondary-800 cursor-pointer text-sm p-2 user-select-none justify-center w-full"
						(click)="onDeselectAll()"
						[ngClass]="loadingVehicles() ? '!text-secondary-400 !cursor-not-allowed' : ''"
						data-cy="c-vehicle-union-select-button-deselect-all"
					>
						{{ 'select.deselect_all' | translate }}
					</div>
				}
				<div
					class="flex items-center text-secondary-800 cursor-pointer text-sm p-2 user-select-none justify-center w-full"
					[ngClass]="
						maxSelections < selectedVehicles.length || loadingVehicles()
							? '!text-secondary-400 !cursor-not-allowed'
							: ''
					"
					(click)="onSave()"
					data-cy="c-vehicle-union-select-button-save"
				>
					{{ 'general.save' | translate }}

					<span class="ml-1">({{ selectedVehicles?.length }})</span>
				</div>
			</div>
		</div>
	}
</div>
