/**
 * Represents and entity from the machineSummary request
 */
export class Summary {
	/**
	 * constructs the summary
	 * @param serial serial
	 * @param distance total distance traveled
	 * @param distanceOccupied total distanceOccupied traveled
	 * @param weight total weight lifted
	 * @param pickup total pickups
	 */
	constructor(
		public serial: string,
		public distance: number,
		public distanceOccupied: number,
		public weight: number,
		public pickup: number
	) {}

	/**
	 * generates a summary object from the data
	 * @param serial serial of the machine
	 * @param data entity
	 */
	static generateFromJson(serial: string, data: any): Summary {
		return new Summary(serial, data.distance, data.distance_occupied, data.weight, data.pickup);
	}
}
