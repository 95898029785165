<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
>
</agilox-analytics-widget-header>

<agilox-analytics-doughnut-chart
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[interpolateLabels]="interpolateLabels"
	[labels]="['widget.distance_pie.distance', 'widget.distance_pie.distanceOccupied']"
	[colors]="colors"
	[loadingHandler]="loadingHandler"
>
</agilox-analytics-doughnut-chart>
