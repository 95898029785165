import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Input,
	Output,
} from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { DownloadStatus } from '@agilox/common';
import { ButtonComponent } from '../button/button.component';

@Component({
	selector: 'ui-download-icon',
	standalone: true,
	imports: [IconModule, TooltipModule, TranslateModule, SpinnerComponent, ButtonComponent],
	templateUrl: './download-icon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadIconComponent {
	private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
	@Input() set status(status: DownloadStatus | null) {
		this._status = status;
		this.cd.markForCheck();
		if (status) {
			this.setTimer();
		}
	}

	private _status: DownloadStatus | null = null;

	get status(): DownloadStatus | null {
		return this._status;
	}

	@Input() iconTooltip: string = '';

	/** How long the status should be displayed */
	@Input() timer: number = 3000;
	@Output() iconClicked: EventEmitter<Event> = new EventEmitter<Event>();

	setTimer() {
		if (this.timer) {
			setTimeout(() => {
				this._status = null;
				this.cd.markForCheck();
			}, this.timer);
		}
	}

	protected readonly DownloadStatus = DownloadStatus;
}
