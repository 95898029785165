import { Role } from '@agilox/common';
import { MenuPoint } from '@agilox/ui-common';
import {
	FailuresSubMenuUrl,
	MenuPointUrl,
	ProcessesSubMenuUrl,
	ProductivitySubMenuUrl,
} from '../../enums';

/** holds all menupoints */
export const analyticsMenuPoints = {
	dashboard: {
		name: 'general.menu.dashboard',
		url: MenuPointUrl.dashboard,
		icon: 'dashboard',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
	} as MenuPoint,
	productivity: {
		name: 'general.menu.productivity',
		url: MenuPointUrl.productivity,
		icon: 'chart_line',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
		submenus: [
			{
				name: 'productivity.tabs.analysis',
				url: ProductivitySubMenuUrl.analysis,
				roles: [],
			} as MenuPoint,
			{
				name: 'productivity.tabs.history',
				url: ProductivitySubMenuUrl.history,
				roles: [],
			} as MenuPoint,
		],
	},
	processes: {
		name: 'general.menu.processes',
		url: MenuPointUrl.processes,
		icon: 'process',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
		submenus: [
			{
				name: 'processes.tabs.analysis',
				url: ProcessesSubMenuUrl.analysis,
				roles: [],
			} as MenuPoint,
			{
				name: 'processes.tabs.workflows',
				url: ProcessesSubMenuUrl.workflows,
				roles: [],
			} as MenuPoint,
			{
				name: 'processes.tabs.actions',
				url: ProcessesSubMenuUrl.actions,
				roles: [],
			} as MenuPoint,
		],
	},
	failures: {
		name: 'general.menu.failures',
		url: MenuPointUrl.failures,
		icon: 'failures',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
		submenus: [
			{
				name: 'failures.tabs.analysis',
				url: FailuresSubMenuUrl.analysis,
				roles: [],
			} as MenuPoint,
			{
				name: 'failures.tabs.overview',
				url: FailuresSubMenuUrl.overview,
				roles: [],
			} as MenuPoint,
			{
				name: 'failures.tabs.visualization',
				url: FailuresSubMenuUrl.visualization,
				roles: [],
			} as MenuPoint,
			{
				name: 'failures.tabs.history',
				url: FailuresSubMenuUrl.history,
				roles: [],
			} as MenuPoint,
		],
	},
	battery: {
		name: 'general.menu.battery',
		url: MenuPointUrl.battery,
		icon: 'battery_75',
		roles: [Role.superuser, Role.support, Role.service, Role.partner, Role.customer],
	} as MenuPoint,
	overview: {
		name: 'general.menu.overview',
		url: MenuPointUrl.dashboard,
		icon: 'dashboard',
		roles: [],
	} as MenuPoint,
	help: {
		name: 'menu.help',
		url: '',
		icon: 'o_quesmark',
		roles: [Role.superuser, Role.customer, Role.partner],
		position: 'bottom',
		callback: true,
	} as MenuPoint,
	logout: {
		name: 'general.logout',
		url: '',
		icon: 'logout',
		roles: [],
		position: 'bottom',
		callback: true,
	} as MenuPoint,
};

export const analyticsMobileMenuPoints = {
	...analyticsMenuPoints,
};
