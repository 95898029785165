<div class="block-info-chart block-info-chart--{{ colorScheme }}">
	<div class="block-info-chart__blocks">
		<div
			class="block-info-chart__block block-info-chart__block--{{ i }}"
			*ngFor="let block of blocks; let i = index"
		>
			<div class="block-info-chart__value">{{ values[i] }}</div>
			<div class="block-info-chart__label">
				<ui-icon [icon]="block.icon" class="block-info-chart__icon" size="l"></ui-icon>
				<span class="block-info-chart__text">{{ block.text | translate }}</span>
			</div>
		</div>
	</div>
</div>
