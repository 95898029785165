import { ChartOptions, TooltipItem } from 'chart.js';

export class ChartOptionGenerator {
	static getDefaultLinechartOptions(
		ctx: any,
		noLowerBound: boolean,
		integerYAxis: boolean,
		legend: boolean
	): ChartOptions {
		const chartOptions: any = {
			layout: {
				padding: {
					left: 25,
					right: 25,
					bottom: 25,
				},
			},
			maintainAspectRatio: false,
			hover: {
				mode: null,
			},
			responsive: true,
			scales: {
				x: {
					stacked: true,
					ticks: {
						autoSkip: true,
						maxTicksLimit: 8,
					},
					grid: {
						drawOnChartArea: false,
						color: 'rgba(100,100,100,0.5)',
					},
				},
				y: {
					stacked: false,
					ticks: {
						autoSkip: true,
						maxTicksLimit: 5,
						precision: 0,
						callback: (data) =>
							(isNaN(Number(data))
								? data
								: (Math.round(Number.parseFloat(data) * 100) / 100).toString()) + (ctx.unit ?? ''),
					},
					grid: {
						color: 'rgba(100,100,100,0.5)',
						drawOnChartArea: false,
					},
				},
			},
			elements: {
				point: {
					radius: 0,
					hitRadius: 10,
					hoverRadius: 10,
				},
				line: {
					tension: 0,
				},
			},
			plugins: {
				legend: {
					position: 'bottom',
					labels: {
						boxWidth: 13,
					},
				},
				tooltip: {
					mode: 'index',
					intersect: false,
					displayColors: true,
					position: 'nearest',
					yAlign: 'bottom',
					callbacks: {
						label: (tooltipItem: TooltipItem<any>) => {
							const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
							const result = value - Math.floor(value) !== 0 ? value.toFixed(2) : value;
							const label = ctx.datasets[tooltipItem.datasetIndex].label;
							// the check for the blank character is needed because if you do not have a machine name in the
							// simple line chart (e.g. in the sum mode) you cannot push a empty string in the entry array then the chart is broken
							// TODO: (WAITING FOR https://git.ad.agilox.net/agx/cloud-engineering/fe/agilox-world/-/issues/453)
							//  Chart Refactoring -> other architecture in sum mode with the machine names
							const machinename = label && label !== ' ' ? label + ': ' : '';
							return machinename + result + (ctx.unit ?? '');
						},
						labelColor: (tooltipItem) => {
							return {
								backgroundColor: ctx.datasets[tooltipItem.datasetIndex].borderColor,
							};
						},
					},
					itemSort: (a, b) => {
						return b.datasetIndex - a.datasetIndex;
					},
				},
			},
		};

		if (!noLowerBound) {
			chartOptions.scales.y['suggestedMin'] = 0;
		}

		if (integerYAxis) {
			chartOptions.scales.y.ticks['stepSize'] = 10;
		}

		chartOptions.plugins.legend.display = legend;

		return chartOptions as ChartOptions;
	}

	static getDefaultBarchartOptions(
		ctx: any,
		noLowerBound?: boolean,
		integerYAxis?: boolean,
		legend?: boolean
	): ChartOptions {
		const chartOptions: any = {
			layout: {
				padding: {
					left: 25,
					right: 25,
					bottom: 25,
				},
			},
			maintainAspectRatio: false,
			hover: {
				mode: null,
			},
			responsive: true,
			scales: {
				x: {
					ticks: {
						autoSkip: true,
						maxTicksLimit: 8,
					},
					grid: {
						drawOnChartArea: false,
						color: 'rgba(100,100,100,0.5)',
					},
				},
				y: {
					stacked: true,
					ticks: {
						autoSkip: true,
						maxTicksLimit: 5,
						precision: 0,
						callback: (data) =>
							ctx.unit === undefined
								? (Math.round(Number.parseFloat(data) * 100) / 100).toString()
								: (Math.round(Number.parseFloat(data) * 100) / 100).toString() + (ctx.unit ?? ''),
					},
					grid: {
						color: 'rgba(100,100,100,0.5)',
						drawOnChartArea: false,
					},
				},
			},
			elements: {
				point: {
					radius: 0,
					hitRadius: 10,
					hoverRadius: 10,
				},
				line: {
					tension: 0,
				},
			},
			plugins: {
				legend: {
					position: 'bottom',
					labels: {
						boxWidth: 13,
					},
				},
				tooltip: {
					mode: 'index',
					intersect: false,
					callbacks: {
						label: (tooltipItem: TooltipItem<any>) => {
							const value = (tooltipItem.dataset.data[tooltipItem.dataIndex] * 100) / 100;
							const label = ctx.datasets[tooltipItem.datasetIndex].label;
							// the check for the blank character is needed because if you do not have a machine name in the
							// simple line chart (e.g. in the sum mode) you cannot push a empty string in the entry array then the chart is broken
							// TODO: (WAITING FOR https://git.ad.agilox.net/agx/cloud-engineering/fe/agilox-world/-/issues/453)
							//  Chart Refactoring -> other architecture in sum mode with the machine names
							const machinename = label && label !== ' ' ? label + ': ' : '';
							return machinename + (isNaN(value) ? 0 : value.toString()) + (ctx.unit ?? '');
						},
						labelColor: (tooltipItem) => {
							return {
								backgroundColor: ctx.datasets[tooltipItem.datasetIndex].borderColor,
							};
						},
					},
					itemSort: (a, b) => {
						return b.datasetIndex - a.datasetIndex;
					},
				},
			},
		};

		if (!noLowerBound) {
			chartOptions.scales.y.suggestedMin = 0;
		}

		if (integerYAxis) {
			chartOptions.scales.y.ticks['stepSize'] = 1;
		}

		chartOptions.plugins.legend.display = legend;

		return chartOptions as ChartOptions;
	}
}
