import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/analytics/src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			map((event) => {
				// should only intercept the requests which are from the api
				if (event instanceof HttpResponse && event.url.includes(environment.server) && event.body) {
					if (event.body.status && event.body.data) {
						// Checks whether a message, like "no_machine_subscription," exists.
						// If such a message is in the body, it returns the message; otherwise, it returns the body.
						event = event.clone({
							body: event.body.msg ? event.body.msg : event.body,
						});
						// If status is false, we check if the data is an array,
						// we set the body to an empty array, otherwise we just return the body.
					} else {
						event = event.clone({ body: Array.isArray(event.body.data) ? [] : event.body });
					}
				}
				return event;
			}),
			catchError((error) => {
				throw error;
			})
		);
	}
}
