import { Failure } from '../failure-order-workflow/failure';

export class QuitData {
	/**
	 * constructs the QuitData
	 * @param downtimes
	 * @param quits
	 * @param errors
	 */
	constructor(
		public downtimes: number,
		public quits: number,
		public errors: Array<Failure>
	) {}

	/**
	 * generates a QuitData object from the data
	 * @param data entity
	 */
	static generateFromJson(data: any): QuitData {
		return new QuitData(data.downtimes, data.quits, data.errors);
	}
}
