export enum WidgetColors {
	'#366778',
	'#C5BDF6',
	'#00C5C6',
	'#FFD364',
	'#D03F48',
	'#F785BC',
	'#C5E584',
	'#7469F1',
	'#FF7F5D',
	'#3BAFFF',
	'#C49C94',
	'#940A11',
	'#2D9E78',
	'#FFBEDA',
	'#DCC9A5',
	'#9DE9F7',
	'#215104',
	'#C2BEBE',
	'#FFFF93',
	'#4E4897',
}
