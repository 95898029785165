import { GridsterItem } from 'angular-gridster2';

/**
 * Holds the informations for every widget
 */
export class WidgetConfig {
	/**
	 * represents a gridsteritem (soon to be replaced)
	 */
	gridsterItem: GridsterItem;

	/**
	 * constructs the widgetconfig and constructs the gridsterItem
	 * @param x distance from the left (switches role with cols when it comes from the server)
	 * @param y distance from the top (switches role with rows when it comes from the server)
	 * @param cols cols it consumes (horizontal)
	 * @param name name
	 * @param onExport function which handles the export
	 */
	constructor(
		public x: number,
		public y: number,
		public cols: number,
		public name: string,
		public disableOnComb: boolean,
		public disableOnSum: boolean,
		public usesGranularity: boolean,
		public onExport?: () => void
	) {
		// setting up the gridster item
		// https://github.com/tiberiuzuld/angular-gridster2/blob/master/projects/angular-gridster2/src/lib/gridsterItem.interface.ts
		this.gridsterItem = {
			x: x,
			y: y,
			rows: 1,
			cols: cols,
		};
	}

	/**
	 * checks if the widget is valid
	 * @param name of the widget
	 */
	static isValidWidget(name: string): boolean {
		const validWidgets = [
			'productivity_summary',
			'performance_summary',
			'weight_absolute',
			'current_speed',
			'distance',
			'cell_voltage',
			'battery_level',
			'battery_temperature',
			'battery_current',
			'battery_difference',
			'pickup_count',
			'park_count',
			'weight',
			'productivity_analysis',
			'distance_absolute',
			'productivity_history',
			'performance',
			'widget_weight',
			'failures_quit',
			'failures_process',
			'failures_technical',
			'processes_canceled',
			'processes_workflows',
			'processes_pickups',
			'processes_drops',
			'charge_count',
			'distance_pie',
			'rotation_absolute',
			'rotation_driving_units',
			'weight_analysis',
			'failures_history',
			'lift_height',
		];

		return validWidgets.includes(name);
	}
}
