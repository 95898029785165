import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

@Component({
	selector: 'agilox-analytics-widget-lift-height',
	templateUrl: './widget-lift-height.component.html',
	styleUrls: ['./widget-lift-height.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetLiftHeightComponent extends BaseWidgetDirective {}
