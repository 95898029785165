<agilox-analytics-widget-header
	[widgetName]="widgetName"
	[disabledOnLoadNoData]="disabledOnLoadNoData"
	[disableOnLoad]="disableOnLoad"
	[disableOnComb]="disableOnComb"
	[disableOnSum]="disableOnSum"
	[(barChart)]="barChart"
	[chartSwitch]="true"
></agilox-analytics-widget-header>
<div
	class="loading-handler"
	*agiloxAnalyticsLoad="
		let elem of loadingHandler;
		disableOnComb: disableOnComb;
		disableOnSum: disableOnSum
	"
>
	<agilox-analytics-timeline-chart
		baseChart
		[data]="elem.data"
		[unit]="unit"
		[entryConfigs]="config"
		[options]="ownOptions"
		[barChart]="barChart"
		[integerYAxis]="true"
		[resetBarChartOptions]="true"
		[resetLineChartOptions]="true"
	>
	</agilox-analytics-timeline-chart>
</div>
