import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseWidgetDirective } from '../components-for-widget-construction/widget/base-widget';

/**
 * displays the lifted weight at a timestamp
 */
@Component({
	selector: 'agilox-analytics-widget-weight',
	templateUrl: './widget-weight.component.html',
	styleUrls: ['./widget-weight.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWeightComponent extends BaseWidgetDirective {}
