import { createFeature, createReducer, on } from '@ngrx/store';
import { Granularity } from '../../general/shared/services/appsettings/granularity.enum';
import { setDateFilter } from './date-filter.actions';

export interface DateFilter {
	startDate: number;
	endDate: number;
	granularity: Granularity;
	shiftOn: boolean;
	shiftStart: number;
	shiftEnd: number;
	shiftDays: string[];
}

const initialState: DateFilter = {
	startDate: 0,
	endDate: 0,
	granularity: Granularity.hh,
	shiftOn: false,
	shiftStart: 0,
	shiftEnd: 0,
	shiftDays: [],
};

const reducer = createReducer(
	initialState,
	on(setDateFilter, (state, action) => action.value)
);

export const dateFilterFeature = createFeature({ name: 'globalDateFilter', reducer });
