<div class="widget-performance-summary">
	<agilox-analytics-widget-header
		[widgetName]="widgetName"
		[disabledOnLoadNoData]="disabledOnLoadNoData"
		[disableOnLoad]="disableOnLoad"
		[disableOnComb]="disableOnComb"
		[disableOnSum]="disableOnSum"
		[disableOnComb]="disableOnComb"
	>
	</agilox-analytics-widget-header>
	<div
		*agiloxAnalyticsLoad="
			let elem of loadingHandler;
			disableOnComb: disableOnComb;
			disableOnSum: disableOnSum
		"
	>
		<agilox-analytics-block-info-chart
			[blocks]="blockInfoChartBoxes"
			[colorScheme]="BlockInfoChartColorSchemeEnum.GREEN"
			[values]="[
				elem.busy_scd | seconds: true,
				elem.reserve_scd | seconds: true,
				elem.charge_scd | seconds: true,
			]"
		>
		</agilox-analytics-block-info-chart>
	</div>
</div>
