import { Injectable } from '@angular/core';
import { Mime } from '../mime';
import { MIME_EXCEL_XLSX, TYPE_ARRAY, XLSX_COLS } from '../constants';
import { SheetjsHelperService } from '../sheetjs-helper.service';
import { WorkSheet } from 'xlsx';
import { ExcelOptions } from '../options';
import { WorksheetExporter } from './worksheet-exporter';

/**
 * Code from https://github.com/HalitTalha/ng-material-extensions/tree/master/projects/cdk-table-exporter
 */
@Injectable({
	providedIn: 'root',
})
export class XlsxExporterService extends WorksheetExporter<ExcelOptions> {
	constructor(sheetJsHelper: SheetjsHelperService) {
		super(sheetJsHelper);
	}

	public getMimeType(): Mime {
		return MIME_EXCEL_XLSX;
	}

	public async workSheetToContent(
		worksheet: WorkSheet,
		options: ExcelOptions = {} as ExcelOptions
	): Promise<any> {
		const { utils, write } = await this.sheetJsHelper.getXlsx();
		const workBook = utils.book_new();
		if (options.columnWidths) {
			worksheet[XLSX_COLS] = this.convertToWch(options.columnWidths);
		}
		this.correctTypes(options);
		utils.book_append_sheet(workBook, worksheet, options.sheet as string);
		return write(workBook, options);
	}

	private correctTypes(options: ExcelOptions) {
		if (!options.type) {
			options.type = TYPE_ARRAY;
		}
		(options as any).bookType = this.getMimeType().extension.replace('.', ''); // sheetjs requires bookingType for excel format
	}

	private convertToWch(columnWidths: Array<number>): Array<{ wch: number }> {
		return columnWidths.map((width) => ({ wch: width }));
	}
}
