import { Azure } from './azure';
import { CountriesService, MachineModel, Country } from '@agilox/common';
import { SelectOption } from '@agilox/ui-common';

/**
 * Represents a machine from the server
 * implements DropDownItem to use it in the dropdown-component
 */
export class Machine {
	/**
	 * constructs a machine
	 * @param isSelected isSelected
	 * @param uuid uuid
	 * @param serial serial
	 * @param name name
	 * @param company company
	 * @param union union
	 * @param city city
	 * @param street street
	 * @param zip zip
	 * @param country country
	 * @param countryCode countryCode
	 * @param timezone timezone
	 * @param endDate subscription end date
	 * @param subscriptionExpired if subscription expired
	 * @param machineModel machineModel
	 * @param isAzure  isAzure
	 * @param isDefaultTenant isDefaultTenant
	 * @param timestampCreated timestampCreated
	 * @param reprocessing reprocessing
	 * @param azureData azureData
	 */
	constructor(
		public isSelected: boolean,
		public uuid: string,
		public serial: string, // formally serial_number
		public name: string,
		public company: string, // formally company_name
		public union: string,
		public city: string,
		public street: string,
		public zip: string,
		public country: Country,
		public timezone: string,
		public endDate: number | undefined,
		public subscriptionExpired: boolean,
		public machineModel: string,
		public isAzure: boolean,
		public isDefaultTenant: boolean,
		public timestampCreated: string,
		public reprocessing: boolean,
		public azureData: Azure,
		public machineSvg: any
	) {}

	/**
	 * Generates a machine from the json
	 * TODO: should also consider if this machine is under the selected ones
	 * @param data JSON from the MachineMGMT/list endpoint
	 */
	static generateFromJson(data: any, countriesAndTimezonesService: CountriesService): Machine {
		return new Machine(
			false,
			data.uuid,
			data.serial,
			data.name,
			data.company_name,
			data.union,
			data.city,
			data.street,
			data.zip ?? '',
			countriesAndTimezonesService.getCountryByCountryCode(data.country_code),
			data.timezone,
			data.subscription_end * 1000,
			data.subscription_expired,
			MachineModel[data.machine_model],
			data.is_azure,
			data.is_default_tenant,
			data.timestamp_created,
			data.bbox_reprocess_all_active === 1,
			data.azure_data ? Azure.generateFromJson(data.azure_data) : null,
			''
		);
	}

	/**
	 * transforms a machine to valid json object for the server
	 */
	toJson() {
		return {
			uuid: this.uuid,
			serial: this.serial,
			company_name: this.company,
			union: this.union,
			zip: this.zip,
			city: this.city,
			street: this.street,
			country_code: this.country?.code ? this.country.code : '',
			country: this.country ? this.country.name : null,
			timezone: this.timezone,
			subscription_end: this.endDate / 1000,
			machine_type: MachineModel[this.machineModel],
			is_azure: this.isAzure,
			is_default_tenant: this.isDefaultTenant,
			timestamp_created: this.timestampCreated,
			reprocessing: this.reprocessing,
			azure_data: this.azureData?.toJson(),
		};
	}

	toString(): string {
		return (
			this.serial +
			',' +
			this.name +
			',' +
			this.company +
			',' +
			this.union +
			',' +
			this.city +
			',' +
			this.machineModel
		);
	}

	getSelectOption(): SelectOption<any> {
		return {
			title: this.name + ' (' + this.serial + ')',
			value: {
				name: this.name,
				serial: this.serial,
			},
		};
	}
}
